import React, { useContext, useEffect, useState, useMemo } from "react";
import { Companies, Group } from "../../../../types/BaseTypes";
import { Box, Button, Divider, Typography } from "@mui/material";
import { Form, Formik, useFormikContext } from "formik";
import { InputTextField } from "../../../Inputs/InputTextField";
import { UserContext } from "../../../../context/userContext";
import { LoadingButton } from "../../../LoadingButton";
import OfficeUserSelection from "../OfficeUserSelection";
import { addUserRoleGroup, addUserRoleOffice, removeRoleFromUser } from "../../../../lib/usersBEClient";
import { SnackBarContext } from "../../../../context/snackBarContext";
import { ClientCreationContext } from "../../../../context/officeContexts/clientCreationContext";

interface UserDetailsComponentProps {
   onComplete: Function;
}

const UserAdminGroupComponent = (props: UserDetailsComponentProps) => {
   const [selectedUser, setSelectedUser] = useState(null);
   const [isLoading, setIsLoading] = useState(null);
   const { selectedClient, refetch, setSelectedClient } = useContext(ClientCreationContext);
   const { showSnackBar } = useContext(SnackBarContext);

   const currentGroup = useMemo(() => selectedClient.data as Group, [selectedClient]);
   const adminDetails = useMemo(() => currentGroup?.admin, [currentGroup]);

   const handleSubmit = async () => {
      setIsLoading(true);
      try {
         const data = {
            group: currentGroup._id,
            roles: ["64f6261e2eb21c6d24e252c9"],
         };
         const roleAdded = await addUserRoleGroup(data, selectedUser._id);
         if (!roleAdded) return showSnackBar("Error al agregar rol a usuario.", true);
         const currentAdminId = currentGroup?.admin?._id;
         if (currentAdminId) {
            await removeRoleFromUser(currentAdminId, currentGroup?._id, "64f6261e2eb21c6d24e252c9");
            showSnackBar("El administrador fue reemplazado correctamente", false);
         } else showSnackBar("El usuario fue agregado correctamente", false);
         props.onComplete();
         refetch();
      } catch (error) {
         showSnackBar("Error al agregar usuario.", true);
      } finally {
         setIsLoading(false);
      }
   };

   const handleCancel = () => setSelectedClient(null);

   const FormObserver: React.FC = () => {
      const { setFieldValue } = useFormikContext();

      useEffect(() => {
         if (selectedUser) {
            setFieldValue("firstName", selectedUser.firstName);
            setFieldValue("lastName", selectedUser.lastName);
            setFieldValue("phone", selectedUser.phoneNumber);
            setFieldValue("email", selectedUser.email);
         }
         // eslint-disable-next-line
      }, [selectedUser]);
      return null;
   };

   return (
      <Box flex={1}>
         <Formik
            initialValues={{
               firstName: adminDetails?.firstName ?? "",
               lastName: adminDetails?.lastName ?? "",
               phone: adminDetails?.phoneNumber ?? "",
               email: adminDetails?.email ?? "",
            }}
            onSubmit={handleSubmit}
         >
            <Form>
               <FormObserver />
               <Box
                  sx={{
                     border: 1,
                     borderColor: "#E0E0E0",
                     borderRadius: 2,
                     flex: 1,
                     maxHeight: "420px",
                     overflowY: "auto",
                  }}
               >
                  <Typography sx={{ p: 2 }} fontWeight={600} variant="h6">
                     Registro de administrador de sistema
                  </Typography>
                  <Divider />
                  <Box
                     sx={{
                        display: "flex",
                        rowGap: 2,
                        flexDirection: "column",
                        px: 3,
                        pt: 2,
                        pb: 3,
                     }}
                  >
                     <Box
                        sx={{
                           display: "flex",
                           rowGap: 2,
                           flexDirection: "column",
                        }}
                     >
                        <OfficeUserSelection
                           selectedUsers={selectedUser}
                           setSelectedUsers={setSelectedUser}
                           fullwidth
                        />
                        <Box sx={{ display: "flex", columnGap: 2 }}>
                           <InputTextField
                              sx={{ my: 0.5 }}
                              variant="outlined"
                              size="small"
                              name="firstName"
                              id="firstName"
                              type="text"
                              label="Nombre"
                              fullWidth={true}
                              disabled
                           />
                           <InputTextField
                              sx={{ my: 0.5 }}
                              variant="outlined"
                              size="small"
                              name="lastName"
                              id="lastName"
                              type="text"
                              label="Apellido"
                              fullWidth={true}
                              disabled
                           />
                        </Box>
                        <Box sx={{ display: "flex", columnGap: 2 }}>
                           <InputTextField
                              sx={{ my: 0.5 }}
                              variant="outlined"
                              size="small"
                              name="phone"
                              id="phone"
                              type="text"
                              label="Teléfono"
                              fullWidth={true}
                              disabled
                           />
                           <InputTextField
                              sx={{ my: 0.5 }}
                              variant="outlined"
                              size="small"
                              name="email"
                              id="email"
                              type="text"
                              label="Correo electrónico"
                              fullWidth={true}
                              disabled
                           />
                        </Box>
                     </Box>
                  </Box>
               </Box>
               <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between", alignItems: "baseline" }}>
                  <Button sx={{ height: "100%" }} variant="outlined" onClick={handleCancel}>
                     Cancelar
                  </Button>
                  {selectedUser && currentGroup?.admin && (
                     <Typography sx={{ textAlign: "center", color: "red", fontSize: 11 }}>
                        El administrador actual se reemplazará por el seleccionado
                     </Typography>
                  )}
                  <LoadingButton label={"Continuar"} isLoading={isLoading} />
               </Box>
            </Form>
         </Formik>
      </Box>
   );
};

export default UserAdminGroupComponent;
