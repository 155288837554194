import React from "react";

import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";

interface InputLoadingButtonProps {
   disabled?: boolean;
   label?: string;
   isLoading: boolean;
   icon?: React.ReactNode;
   onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
   fullWidth?: boolean;
   size?: string;
   type?: string;
   href?: string;
   download?: string;
   disableSaveIcon?: boolean;
   datacy?: string;
   sx?: any;
   children?: React.ReactElement[];
   variant?: "text" | "contained" | "outlined";
}

export const LoadingButton = (props: InputLoadingButtonProps) => {
   return (
      <Button
         data-cy={props.datacy}
         type="submit"
         variant={props.variant || "contained"}
         sx={{
            mb: 2,
            bgcolor: "#142c44",
            ...props.sx,
         }}
         fullWidth={props.fullWidth}
         disabled={props.isLoading || props.disabled}
         onClick={props.onClick}
         style={{ textTransform: "none" }}
      >
         {props.isLoading ? (
            props.disableSaveIcon ? (
               <></>
            ) : (
               <SaveIcon />
            )
         ) : (
            props.children || (
               <>
                  {props.icon}
                  {props.label}
               </>
            )
         )}
         {props.isLoading && <CircularProgress size={24} />}
      </Button>
   );
};
