import { useContext, useState } from "react";
import { ClientCreationContext } from "../../../../context/officeContexts/clientCreationContext";
import { Box, Tab, Tabs } from "@mui/material";
import { TabPanel } from "../../../../screens/Mi Lecosy/ClientInfoScreenNew";
import { CompanyDetailsComponent } from "./CompanyDetailsComponent";
import { InvoicesDetailsComponent } from "./InvoicesDetailsComponent";
import { ServiceAssignComponent } from "./ServiceAssignComponent";
import AddCompanyAdminComponent from "./AddCompanyAdminComponent";

const ClientCreationTabsContainer = () => {
   const { selectedClient, setSelectedClient } = useContext(ClientCreationContext);
   const [activeStep, setActiveStep] = useState(0);

   const handleChange = (_event: React.SyntheticEvent, newValue: number) => setActiveStep(newValue);
   const handleNext = () => setActiveStep((a) => a + 1);
   const handleComplete = () => setSelectedClient(null);

   return (
      <Box
         sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            p: 2,
            minWidth: 1000,
            maxWidth: 1000,
         }}
      >
         <Box sx={{ display: "flex" }}>
            <Box sx={{ flex: 1, px: 2 }}>
               <Box
                  sx={{
                     border: 1,
                     borderColor: "#E0E0E0",
                  }}
               >
                  <Tabs orientation="vertical" value={activeStep} onChange={handleChange}>
                     {["Empresa", "Administrador del sistema", "Facturas", "Módulos"].map((step, index) => (
                        <Tab key={index} label={step} disabled={!selectedClient?.data && index !== 0} />
                     ))}
                  </Tabs>
               </Box>
            </Box>
            <Box
               sx={{
                  flex: 3,
                  display: "flex",
                  px: 2,
                  flexDirection: "column",
                  maxHeight: 570,
                  overflowY: "auto",
               }}
            >
               <TabPanel value={activeStep} index={0}>
                  <CompanyDetailsComponent onComplete={handleNext} />
               </TabPanel>
               <TabPanel value={activeStep} index={1}>
                  <AddCompanyAdminComponent onComplete={handleNext} />
               </TabPanel>
               <TabPanel value={activeStep} index={2}>
                  <InvoicesDetailsComponent onComplete={handleNext} />
               </TabPanel>
               <TabPanel value={activeStep} index={3}>
                  <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
                     <ServiceAssignComponent onComplete={handleComplete} />
                  </Box>
               </TabPanel>
            </Box>
         </Box>
      </Box>
   );
};

export default ClientCreationTabsContainer;
