import React, { useEffect, useState } from "react";
import { Box, DialogTitle, IconButton, Modal, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface CustomModalProps {
   open: boolean;
   setOpen?: Function;
   title: string;
   timeStamp?: boolean;
   children: React.ReactNode;
   onClose?: Function;
   maxWidth?: string | number;
}

export const CustomModalComponent = (props: CustomModalProps) => {
   const { open, setOpen, onClose, title, timeStamp, children, maxWidth } = props;

   const handleClose = () => {
      setOpen(false);
      if (onClose) onClose();
   };

   return (
      <Modal open={open} sx={{ display: "flex" }} tabIndex={1}>
         <Box sx={{ m: "auto", maxWidth: maxWidth ?? "60%", minWidtt: 300 }}>
            <Box bgcolor={"#162c44"} px={1} sx={{ borderTopLeftRadius: 8, borderTopRightRadius: 8 }}>
               <DialogTitle
                  sx={{
                     position: "relative",
                     alignItems: "center",
                     display: "flex",
                     justifyContent: "space-between",
                  }}
               >
                  <Stack sx={{ mr: 2 }}>
                     <Typography fontSize={20} color={"white"} fontWeight={600} fontFamily={"Open Sans"}>
                        {title}
                     </Typography>
                     <CurrentDateTime timeStamp={timeStamp} />
                  </Stack>
                  {setOpen && (
                     <IconButton onClick={() => handleClose()} sx={{ position: "absolute", top: 12, right: 0 }}>
                        <CloseIcon sx={{ color: "white" }} />
                     </IconButton>
                  )}
               </DialogTitle>
            </Box>

            <Box
               sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  borderWidth: "thin 0px",
                  bgcolor: "white",
                  borderBottomLeftRadius: 8,
                  borderBottomRightRadius: 8,
                  overflowY: "auto",
                  overflowX: "hidden",
               }}
            >
               {children}
            </Box>
         </Box>
      </Modal>
   );
};

const CurrentDateTime = ({ timeStamp }) => {
   const [currentTime, setCurrentTime] = useState(new Date());
   useEffect(() => {
      const interval = setInterval(() => {
         setCurrentTime(new Date());
      }, 1000);
      return () => clearInterval(interval);
   }, []);

   const formattedHour = currentTime.getHours() % 12 || 12;
   const minute = currentTime.getMinutes();
   const amPM = currentTime.getHours() >= 12 ? "PM" : "AM";

   return (
      timeStamp && (
         <Typography color="white">
            {currentTime.toLocaleDateString("es-MX", {
               day: "numeric",
               month: "long",
               year: "numeric",
            }) + `, ${formattedHour}:${minute < 10 ? "0" + minute : minute} ${amPM}`}
         </Typography>
      )
   );
};
