import {
   Avatar,
   Box,
   CircularProgress,
   Divider,
   List,
   ListItem,
   ListItemButton,
   ListItemIcon,
   ListItemText,
   Modal,
   Typography,
} from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import { Navigate, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { getUrlS3 } from "../../../lib/usersBEClient";
import { AddBusiness, AdminPanelSettings, Home, PermContactCalendar } from "@mui/icons-material";
import { UserContext } from "../../../context/userContext";
import { ServiceStatusComponent } from "../../../components/MiLecosy/ServiceStatusComponent";

export const CompanyScreen = () => {
   const { companyId } = useParams();
   const {
      companySelected,
      getCompanyDetails,
      isLoadingCompany,
      isCompanyAdmin,
      user,
      isImplementationUser,
      companies,
      isOffice,
   } = useContext(UserContext);
   const [logo, setLogo] = useState("");
   const [openLogo, setOpenLogo] = useState(false);
   const navigate = useNavigate();
   const location = useLocation();

   const menu = [
      {
         name: "Inicio",
         icon: <Home sx={{ color: "#2d4357" }} />,
         route: "inicio",
      },
      {
         name: "Usuarios",
         icon: <PermContactCalendar sx={{ color: "#2d4357" }} />,
         route: "usuarios",
      },
      isOffice && {
         name: "Clientes",
         icon: <AddBusiness sx={{ color: "#2d4357" }} />,
         route: "clientes",
      },
      {
         name: "Servicios",
         icon: <AdminPanelSettings sx={{ color: "#2d4357" }} />,
         route: "servicios",
      },
   ].filter(Boolean);

   useEffect(() => {
      if (!companySelected || companySelected?._id !== companyId) getCompanyDetails(companyId);
      // eslint-disable-next-line
   }, [companySelected, companyId]);

   //GET LOGO

   const getCompanyLogo = useCallback(async () => {
      if (!companySelected) return;
      const url = await getUrlS3("images-lecosy", { folder: companyId }, "logo.png");
      setLogo(url);
   }, [companySelected, companyId]);

   useEffect(() => {
      getCompanyLogo();
   }, [getCompanyLogo]);

   // //CHECK IF ADMIN
   useEffect(() => {
      const isAdmin = (user?.group as any)?.admin === user.id;
      const isCompanyAdmin = companies.some(
         (company) => company._id === companyId || company.company_details.admin === user.id
      );
      if (companies.length > 1 && user?.id)
         if (!isAdmin && !isCompanyAdmin && !isImplementationUser()) return navigate("/inicio");
         else if (companySelected && !isCompanyAdmin && !isImplementationUser()) return navigate("/inicio");
      //eslint-disable-next-line
   }, [companies, user, companySelected]);

   if (location.pathname === `/mi-lecosy/${companyId}` || location.pathname === `/mi-lecosy/empresa`) {
      return <Navigate to={"inicio"} />;
   } else if (location.pathname === `/mi-lecosy/empresa` && !isCompanyAdmin && !isImplementationUser()) {
      return <Navigate to={"/inicio"} />;
   }

   //REMOVES SIDE BAR FROM COMPANY
   if (location.pathname.includes("estructura-accionaria")) return <Outlet />;
   if (location.pathname.includes("distribucion-capital-social")) return <Outlet />;

   return (
      <Box minWidth={"100%"} width={"100%"} sx={{ display: "flex", columnGap: 2, mt: 2 }}>
         {!isLoadingCompany && companySelected ? (
            <>
               <Box sx={{ flex: 1 }}>
                  <Box bgcolor={"white"} borderRadius={1} minWidth={250} boxShadow={2}>
                     <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" p={3}>
                        {logo ? (
                           <>
                              <Avatar
                                 sx={{
                                    width: 100,
                                    height: 100,
                                    fontSize: 30,
                                    ":hover": { cursor: "pointer" },
                                    border: 1,
                                    borderColor: "#E3E2E2",
                                 }}
                                 imgProps={{ sx: { objectFit: "contain" } }}
                                 alt={companySelected.person_details.comercialName.substring(0, 1)}
                                 src={logo}
                                 onClick={() => {
                                    setOpenLogo(true);
                                 }}
                              />
                              <Modal
                                 open={openLogo}
                                 onClose={() => {
                                    setOpenLogo(false);
                                 }}
                                 sx={{ display: "flex" }}
                              >
                                 <Box sx={{ m: "auto", maxWidth: "60%" }}>
                                    <Box component={"img"} width={"100%"} maxHeight={600} src={logo} />
                                 </Box>
                              </Modal>
                              <Typography fontWeight={400} mt={0.5}>
                                 {companySelected.person_details.comercialName}
                              </Typography>
                           </>
                        ) : (
                           <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
                              <CircularProgress />
                           </Box>
                        )}
                     </Box>
                     <Divider flexItem sx={{ my: 1 }} />
                     <Box>
                        <Typography mx={2} alignSelf="flex-start" fontWeight={500} variant="h6">
                           Menú
                        </Typography>
                        <List>
                           {menu.map((item, i) => {
                              return (
                                 <ListItem key={item.route} disablePadding sx={{ py: 0.5, px: 1 }}>
                                    <ListItemButton
                                       selected={location.pathname.includes(item.route)}
                                       onClick={() => navigate(item.route)}
                                       sx={{ borderRadius: 3 }}
                                    >
                                       <ListItemIcon>{item.icon}</ListItemIcon>
                                       <ListItemText primary={item.name} />
                                    </ListItemButton>
                                 </ListItem>
                              );
                           })}
                        </List>
                     </Box>
                  </Box>
                  <Box sx={{ boxShadow: 2, borderRadius: 1, mt: 2, bgcolor: "white" }}>
                     <ServiceStatusComponent company={companySelected ? companySelected : null} />
                  </Box>
               </Box>
               <Box
                  sx={{
                     flex: 4,
                     display: "flex",
                     flexDirection: "column",
                  }}
               >
                  <Outlet />
               </Box>
            </>
         ) : (
            <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
               <CircularProgress />
            </Box>
         )}
      </Box>
   );
};
