import {
   Box,
   Button,
   CircularProgress,
   FormHelperText,
   Grid,
   IconButton,
   InputAdornment,
   ListItemText,
   MenuItem,
   Stack,
   Typography,
} from "@mui/material";
import { InputSelectField } from "../../../Inputs/InputSelectField";
import { Form, Formik } from "formik";
import { SessionTitle } from "../SubComponents/SessionTitle";
import { InputTextField } from "../../../Inputs/InputTextField";
import { HoursComponent } from "../SubComponents/HoursComponent";
import { CustomInputStyle } from "../../Inputs/InputStyled";
import {
   AttachFile,
   Description,
   Link,
   LocationOnOutlined,
   LockOutlined,
   PeopleAltOutlined,
} from "@mui/icons-material";
import { AffairsComponent } from "../SubComponents/AffairsComponent";
import { UsersSelector } from "../../Inputs/UsersSelector";
import { useContext, useEffect, useMemo, useState } from "react";
import { GovernanceContext } from "../../../../context/governanceContext/governanceContext";
import {
   useGetUsersByGovernance,
   useGetUsersByGovernance2,
} from "../../../../hooks/gob-corp/SchedulerScreen/index.hooks";
import dayjs from "dayjs";
import { SchedulerHelpers } from "@aldabil/react-scheduler/types";
import { CreateSession, getAffairsByIds, getClassificationByIdAndCategoryName } from "../../../../lib/gobCorpBEClient";
import {
   CreateFileByName,
   SendProclamationFile,
   complaintUploadPdfEvidence,
   getCorporateData,
   getUrlS3,
   getUserDataGB,
   useGetResourceSelected,
} from "../../../../lib/usersBEClient";
import { SnackBarContext } from "../../../../context/snackBarContext";
import { ProclamationTemplate } from "../../../../lib/documentsTemplates/gc/proclamationTemplate";
import { pdf } from "@react-pdf/renderer";
import { UserContext } from "../../../../context/userContext";
import { useParams } from "react-router-dom";
import { ExternGuests } from "../../Inputs/ExternGuests";
import { sessionCreateSchema } from "../../../../lib/validations/GobCop/inputSchemas";
import { SeriesSelect } from "../SubComponents/SeriesSelect";
import { ProclamationAssemblyTemplate } from "../../../../lib/documentsTemplates/gc/proclamationAssemblyTemplate";
import { b64toBlob, blobToBase64, formatFileSize, toISOString } from "../../../../const/globalConst";
import _ from "lodash";
import { uploadFileToS3, UseGetPresignedURLUpload } from "../../../../lib/s3Client";
import { AddFileModal } from "../../../MiLecosy/AddFileModal";
import { GovernanceTheSequelContext } from "../../../../context/governanceContext/governanceTheSequelContext";
import SignService from "../../../../lib/signBEClient";
import { legalario } from "../../../../lib/Legalario/legalario";
import legalarioValidation from "../../../../lib/Legalario/legalarioValidation";

const options = ["Ordinaria", "Extraordinaria"];
const optionsProclamation = ["Primera", "Segunda", "Tercera", "Cuarta", "Quinta"];
const optionsAssembly = ["General", "Ordinaria", "Extraordinaria", "Especiales", "Mixta", "Sesión de acuerdos"];

interface ICreateEventProps {
   scheduler?: SchedulerHelpers;
   setOpen?: Function;
   edit?: boolean;
   initialValues?: any;
   assembly?: boolean;
}

const useGetInitialValues = (event, assembly) => {
   const [isLoading, setIsLoading] = useState(true);
   const [initialValues, setInitialValues] = useState({});

   useEffect(() => {
      const fetchData = async () => {
         if (event) {
            setIsLoading(true);
            let values = [];
            let affairs = [];
            let affairsAdded = [];
            if (assembly) {
               for (const iterator of event.order) {
                  affairs.push(iterator.affair);
               }
               const newValue = await getAffairsByIds(affairs);
               affairsAdded = newValue;
               for (const iterator of event.order) {
                  const data = newValue.find((affa) => affa._id === iterator.affair);
                  values.push({
                     description: iterator.description,
                     affair: data,
                  });
               }
            } else {
               values = event.order;
            }
            const initialValuesC = {
               type: event.type,
               title: event.title,
               date: dayjs(event.date),
               start: dayjs(event.start),
               end: dayjs(event.end),
               location: event.location,
               meetingURL: event.meetingURL,
               provider: event.provider,
               users: event.usersRegistry.map((userR) => {
                  return { _id: userR.user, ...userR };
               }),
               externs: event.externs,
               extern: "",
               specialty: "",
               usersRegistry: event.usersRegistry,
               affairAdded: values,
               affair: affairsAdded,
               proclamation: "Primera",
            };
            setInitialValues(initialValuesC);
            setIsLoading(false);
         } else {
            setIsLoading(false);
         }
      };
      fetchData();
   }, [assembly, event]);

   return { initialValues, isLoading };
};

export const SessionBase = (props: ICreateEventProps) => {
   const { id } = useParams();
   const { initialValues } = useGetInitialValues(props.initialValues, props.assembly);
   const {
      gobernanceBody,
      companySelected,
      setRefetch,
      refetch,
      selectedGovernance: gobernanceSelected,
      consultiveGroup,
      consultiveGroupCompanies,
   } = useContext(GovernanceContext);

   const { loading: loadingUsers, users } = useGetUsersByGovernance(gobernanceBody.find((value) => value._id === id));
   const { users: usersAll } = useGetUsersByGovernance2(gobernanceBody.find((value) => value._id === id));
   const resourceId = useGetResourceSelected();
   const { showSnackBar } = useContext(SnackBarContext);
   const { mutate } = CreateSession(resourceId, companySelected);
   const { mutate: createFile } = CreateFileByName();
   const { mutate: sendProclamation } = SendProclamationFile();
   const { user, companySelected: companySelectedContext } = useContext(UserContext);
   const { governanceCoordinators } = useContext(GovernanceTheSequelContext);
   const [code, setCode] = useState("");
   const [companyLogo, setCompanyLogo] = useState("");
   const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
   const [uploadedFile, setUploadedFile] = useState<FileList>(null);
   const [openFileModal, setOpenFileModal] = useState(false);

   const isConsultiveGroup = useMemo(
      () =>
         consultiveGroup &&
         consultiveGroup?._id === companySelected &&
         gobernanceSelected?.title !== "Asamblea de accionistas" &&
         gobernanceSelected.company === consultiveGroup?._id,
      [consultiveGroup, gobernanceSelected]
   );

   const fetchLogo = async () => {
      const logoUrl = await getUrlS3(
         "images-lecosy",
         { folder: isConsultiveGroup ? `group/${gobernanceSelected.company}` : gobernanceSelected.company },
         "logo.png"
      );
      setCompanyLogo(logoUrl);
   };

   useEffect(() => {
      fetchLogo();
   }, [consultiveGroup]);

   const handleSubmit = async (values, actions) => {
      try {
         setIsLoadingSubmit(true);

         let usersA = values.users;
         if (props.assembly) {
            let series = [];
            values.affairAdded.forEach((affair) => {
               const temporalSeries = affair.affair.series.map((element) => {
                  return element.title;
               });
               series.push(...temporalSeries);
            });
            const filteredSeries = Array.from(new Set(series));
            usersA = gobernanceSelected.users.filter((userF: any) => {
               if (userF.actions.length > 0) {
                  return userF.actions.some((action) => filteredSeries.includes(action.title));
               } else {
                  return false;
               }
            });
         }

         let president;
         let vicepresident;
         let secretary;
         if (props.assembly) {
            const councilGovernance =
               gobernanceBody.find(
                  (g) => g.title === "Consejo de administración" && g.company === gobernanceSelected.company
               ) || gobernanceBody.find((g) => g.title === "Consejo de administración");
            president = councilGovernance.users.find((user: any) => user.charge === "659f11b1fb579847f88877cd");
            secretary = councilGovernance.users.find((user: any) => user.charge === "659f11befb579847f88877ce");
            vicepresident = councilGovernance.users.find((user: any) => user.charge === "66300f211fc117a6154a3f7a");
         } else {
            president = gobernanceSelected.users.find(
               (userF: any) =>
                  userF.charge === "659f11b1fb579847f88877cd" ||
                  userF.charge === "659f135efb579847f88877d5" ||
                  userF.charge === "659f13e2fb579847f88877d9"
            );

            secretary = gobernanceSelected.users.find(
               (userF: any) =>
                  userF.charge === "659f11befb579847f88877ce" ||
                  userF.charge === "659f1383fb579847f88877d7" ||
                  userF.charge === "659f13eefb579847f88877da"
            );

            vicepresident = gobernanceSelected.users.find(
               (userF: any) =>
                  userF.charge === "66300f211fc117a6154a3f7a" ||
                  userF.charge === "66300f7a1fc117a6154a3f7c" ||
                  userF.charge === "66300fa41fc117a6154a3f7e"
            );
         }

         if (!president) {
            showSnackBar("No es posible crear la sesión. Se requiere un presidente.", true);
            setIsLoadingSubmit(false);
            return null;
         }
         if (!secretary) {
            showSnackBar("No es posible crear la sesión. Se requiere un secretario.", true);
            setIsLoadingSubmit(false);
            return null;
         }

         let mails = [];

         const findUserEmailById = (userId) => {
            const user = usersAll.find((userF) => userF._id === userId);
            return user ? { id: user._id, email: user.email } : null;
         };

         usersA.forEach((element) => {
            const userId = props.assembly ? element.user : element._id;
            const mail = findUserEmailById(userId);
            if (mail) mails.push(mail);
         });
         const committeeUsers = await getUserDataGB([president?.user, secretary?.user, vicepresident?.user]);
         const roles = [
            { role: president, user: committeeUsers.find((user) => user._id === president?.user) },
            { role: secretary, user: committeeUsers.find((user) => user._id === secretary?.user) },
            { role: vicepresident, user: committeeUsers.find((user) => user._id === vicepresident?.user) },
         ];
         roles.forEach(({ role, user }) => {
            if (role && user) {
               mails.push({ id: role.user, email: user.email });
            }
         });
         const filteredMails = _.uniqBy(mails, "id");

         let blob = null;

         const fullName = `${user.firstName} ${user.lastName}`;
         const isCoordinator = governanceCoordinators?.some((cord) => cord.user._id === user.id);
         const isPresident = president.user === user.id;

         const corporateData = await getCorporateData(gobernanceSelected.company);
         const responsable = {
            name: fullName,
            type: corporateData?.watchman?.includes(fullName)
               ? "Comisario"
               : isCoordinator
               ? "Coordinador de gobierno corporativo"
               : corporateData?.societyKind === "Sociedad civil" && isPresident
               ? "Administrador único"
               : corporateData?.societyKind === "Sociedad mercantil" && isPresident
               ? "Presidente del consejo de administración"
               : "",
         };

         if (props.assembly) {
            const quorum = await getClassificationByIdAndCategoryName(gobernanceSelected.company, values.type);
            blob = await pdf(
               ProclamationAssemblyTemplate(
                  values.type,
                  values.proclamation,
                  companySelectedContext.person_details.businessName,
                  values.start,
                  values.date,
                  values.provider,
                  values.location,
                  responsable,
                  values.affairAdded,
                  gobernanceSelected.structure.confirmationDays,
                  values.proclamation === "Primera" ? quorum[0] : quorum[1],
                  companyLogo
               )
            ).toBlob();

            const userToSign = [
               {
                  fullname: `${user.firstName} ${user.lastName}`,
                  email: user.email,
                  type: "FIRMA",
               },
            ];
            const base64: any = await blobToBase64(blob);

            //VALIDATION FOR LEGALARIO CREDITS: IF THEY RUN OUT RUN REGULAR SESSION CREATION
            const validationObject = {
               company: gobernanceSelected.company,
               type: values.type,
               assembly: props.assembly,
               group: isConsultiveGroup,
            };
            if (legalarioValidation(validationObject, gobernanceSelected.title)) {
               let documentCreated;
               try {
                  documentCreated = await SignService.createDocument({
                     file: base64,
                     name: `${code}-${values.title}.pdf`,
                     signers: userToSign,
                  });
               } catch (error) {
                  const errorMessage = error?.response?.data?.message;
                  if (errorMessage?.includes('"success":false,"message":"El campo [document_id] es obligatorio"')) {
                     return handleCreateSession(values, actions, filteredMails, mails, blob);
                  }
                  showSnackBar("Error al crear sesion, intente de nuevo", true);
                  setIsLoadingSubmit(false);
                  return;
               }

               const legalarioSDK = legalario();
               legalarioSDK.signature({
                  signerId: documentCreated.document.signers[0].id,
                  callbacks: {
                     onFinish: async () => {
                        const documentId = documentCreated.document.signers[0].user_document_id;
                        blob = (await SignService.downloadDocument(documentId)).document;
                        blob = b64toBlob(blob, "application/pdf");
                        const url = URL.createObjectURL(blob);
                        const a = document.createElement("a");
                        a.href = url;
                        a.download = `${code}-${values.title}.pdf`;
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                        document.body.removeChild(a);
                        await SignService.deleteDocument(documentCreated.document.signers[0].user_document_id);
                        handleCreateSession(values, actions, filteredMails, mails, blob);
                     },
                     onCancel: async () => {
                        await SignService.deleteDocument(documentCreated.document.signers[0].user_document_id);
                        return setIsLoadingSubmit(false);
                     },
                     onError: async (data) => {
                        // await SignService.deleteDocument(documentCreated.document.signers[0].user_document_id);
                        // showSnackBar("Error al firmar, intente de nuevo", true);
                        // return setIsLoadingSubmit(false);
                     },
                  },
               });
            } else {
               handleCreateSession(values, actions, filteredMails, mails, blob);
            }
         } else {
            let newAffairs = values.affairAdded;
            if (isConsultiveGroup) {
               newAffairs = newAffairs.map((affair) => {
                  return {
                     ...affair,
                     companies: affair.companies.map((company) => {
                        const foundCompany = consultiveGroupCompanies.find((c) => c._id === company);
                        return foundCompany ? foundCompany.person_details?.comercialName : company;
                     }),
                  };
               });
            }
            const presidentFound = committeeUsers.find((user) => user._id === president.user);
            blob = await pdf(
               ProclamationTemplate(
                  companyLogo,
                  gobernanceSelected.title,
                  newAffairs,
                  values.date,
                  values.start,
                  values.location,
                  { name: values.provider, id: values.meetingURL },
                  `${presidentFound.firstName} ${presidentFound.lastName}`,
                  code,
                  isConsultiveGroup
               )
            ).toBlob();

            if (!isConsultiveGroup && gobernanceSelected.title === "Consejo de administración") {
               const userToSign = [
                  {
                     fullname: `${user.firstName} ${user.lastName}`,
                     email: user.email,
                     type: "FIRMA",
                  },
               ];
               const base64: any = await blobToBase64(blob);

               //VALIDATION FOR LEGALARIO CREDITS: IF THEY RUN OUT RUN REGULAR SESSION CREATION
               let documentCreated;
               try {
                  documentCreated = await SignService.createDocument({
                     file: base64,
                     name: `${code}-${values.title}.pdf`,
                     signers: userToSign,
                  });
               } catch (error) {
                  const errorMessage = error?.response?.data?.message;
                  if (errorMessage?.includes('"success":false,"message":"El campo [document_id] es obligatorio"')) {
                     return handleCreateSession(values, actions, filteredMails, mails, blob);
                  }
                  showSnackBar("Error al crear sesion, intente de nuevo", true);
                  setIsLoadingSubmit(false);
                  return;
               }

               const legalarioSDK = legalario();
               legalarioSDK.signature({
                  signerId: documentCreated.document.signers[0].id,
                  callbacks: {
                     onFinish: async () => {
                        const documentId = documentCreated.document.signers[0].user_document_id;
                        blob = (await SignService.downloadDocument(documentId)).document;
                        blob = b64toBlob(blob, "application/pdf");
                        const url = URL.createObjectURL(blob);
                        const a = document.createElement("a");
                        a.href = url;
                        a.download = `${code}-${values.title}.pdf`;
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                        document.body.removeChild(a);
                        await SignService.deleteDocument(documentCreated.document.signers[0].user_document_id);
                        handleCreateSession(values, actions, filteredMails, mails, blob);
                     },
                     onCancel: async () => {
                        await SignService.deleteDocument(documentCreated.document.signers[0].user_document_id);
                        return setIsLoadingSubmit(false);
                     },
                     onError: async (data) => {
                        await SignService.deleteDocument(documentCreated.document.signers[0].user_document_id);
                        showSnackBar("Error al firmar, intente de nuevo", true);
                        return setIsLoadingSubmit(false);
                     },
                  },
               });
            } else {
               handleCreateSession(values, actions, filteredMails, mails, blob);
            }
         }
      } catch (error) {
         console.log(error);
      }
   };

   const handleCreateSession = (values, actions, filteredMails, mails, blob) => {
      const startT = toISOString(values.start);
      const endT = toISOString(values.end);

      const coordinatorsMails = governanceCoordinators?.map((cord) => {
         return {
            user: cord.user._id,
            email: cord.user.email,
         };
      });

      mutate(
         {
            sessionData: {
               comments: [],
               date: values.date,
               startTime: toISOString(values.date).split("T")[0] + "T" + startT.split("T")[1],
               finishTime: toISOString(values.date).split("T")[0] + "T" + endT.split("T")[1],
               location: values.location,
               meetingURL: values.meetingURL,
               title: values.title,
               owner: user.id,
               externs: values.externs,
               governance: gobernanceSelected._id,
               companyName: "Company Name",
               coordMail: coordinatorsMails,
               usersRegistry: filteredMails.map((userRe) => {
                  // Se usa filteredMails
                  return { user: userRe.id, email: userRe.email };
               }),
               provider: values.provider,
               order: values.affairAdded,
               type: values.type,
               assembly: props.assembly,
               proclamation: values.proclamation === "Primera" ? "Primera" : "Segunda o ulterior",
               billCode: code,
               group: isConsultiveGroup,
               files:
                  (uploadedFile &&
                     uploadedFile.length > 0 &&
                     (uploadedFile as any)?.map((file, i) => {
                        return { fileName: `${i}_${file?.name}` };
                     })) ??
                  [],
            },
            governanceId: gobernanceSelected._id,
         },
         {
            onSuccess: async (session) => {
               createFile(
                  {
                     file: {
                        name: `${code}-${values.title}.pdf`,
                        owner: user.id,
                        metadata: [],
                        size: blob.size,
                        type: blob.type,
                        fileDirection: `gc/companies/${gobernanceSelected.company}/governing-body/${gobernanceSelected._id}/files/`,
                        governingBody: gobernanceSelected._id,
                     },
                     folder: {
                        name: props.assembly ? values.type : "Convocatoria y minuta de reuniones",
                        governance: gobernanceSelected._id,
                     },
                  },
                  {
                     onError: (error: any) => {
                        showSnackBar("Error al agregar archivo.", true);
                        setIsLoadingSubmit(false);
                     },
                     onSuccess: async (data) => {
                        await complaintUploadPdfEvidence(data.urlToUpload, blob);

                        showSnackBar("Sesión creada correctamente", false);
                        setRefetch(!refetch);
                        setIsLoadingSubmit(false);
                        if (props.scheduler) props.scheduler.close();
                        else props.setOpen(0);
                        actions.resetForm();

                        const externs = session.externs.map((extern) => {
                           return { email: extern.email, id: extern.user };
                        });
                        mails.push(...externs);
                        const filteredMails = _.uniqBy(
                           mails.map((userRe) => {
                              return { user: userRe.id, email: userRe.email };
                           }),
                           "user"
                        );
                        if (uploadedFile && uploadedFile.length > 0) {
                           for (let i = 0; i < uploadedFile.length; i++) {
                              const urlReceipt1 = await UseGetPresignedURLUpload({
                                 bucket: "files-lecosy",
                                 folder: `gc/companies/${gobernanceSelected.company}/governing-body/${session._id}`,
                                 name: `${i}_${uploadedFile[i]?.name}`,
                              });
                              await uploadFileToS3(urlReceipt1.data, uploadedFile[i]);
                           }
                        }
                        sendProclamation(
                           {
                              fileId: data.createFile._id,
                              group: isConsultiveGroup,
                              sessionDetails: {
                                 date: values.date,
                                 start: toISOString(values.date).split("T")[0] + "T" + startT.split("T")[1],
                                 end: toISOString(values.date).split("T")[0] + "T" + endT.split("T")[1],
                                 title: values.title,
                                 location: values.location,
                                 id: session._id,
                                 link: values.meetingURL,
                                 company: gobernanceSelected.company,
                                 governance: gobernanceSelected.title,
                                 assembly: props.assembly,
                                 type: values.type,
                                 provider: values.provider,
                                 attachments:
                                    (uploadedFile &&
                                       uploadedFile.length > 0 &&
                                       (uploadedFile as any)?.map((file, i) => `${i}_${file?.name}`)) ||
                                    [],
                              },
                              userMails: _.uniqBy([...filteredMails, ...coordinatorsMails], "user"),
                           },
                           {
                              onError: (error: any) => {
                                 showSnackBar("Error al enviar archivo.", true);
                                 setIsLoadingSubmit(false);
                              },
                           }
                        );
                     },
                  }
               );
            },
            onError: (error: any) => {
               showSnackBar("Error al crear session", true);
               return null;
            },
         }
      );
   };

   return (
      <Stack>
         <Formik
            initialValues={
               props.edit
                  ? initialValues
                  : {
                       end: null,
                       note: false,
                       externs: [],
                       extern: "",
                       specialty: "",
                       type: "Ordinaria",
                       title: "",
                       date: null,
                       start: null,
                       location: "",
                       meetingURL: "",
                       provider: "",
                       affair: [],
                       affairAdded: [],
                       users: [],
                       proclamation: "Primera",
                       assembly: props.assembly,
                    }
            }
            validationSchema={sessionCreateSchema}
            onSubmit={handleSubmit}
         >
            {(formProps: any) => {
               return (
                  <Form>
                     <Stack sx={{ py: 4, maxHeight: 500, overflowY: "auto", px: 6 }} rowGap={2}>
                        <Stack direction={"row"} sx={{ alignItems: "center", columnGap: 2 }}>
                           <Typography sx={{ flex: 1, fontWeight: 600 }}>
                              {props.assembly ? "Clasificación" : "Tipo de sesión"}
                           </Typography>
                           <Box sx={{ flex: 2 }}>
                              <InputSelectField
                                 name="type"
                                 id="type"
                                 label=""
                                 labelId="type"
                                 fullWidth
                                 size={"small"}
                                 onChange={(event) => {
                                    formProps.setFieldValue("type", event.target.value);
                                    formProps.setFieldValue("affairAdded", []);
                                    formProps.setFieldValue("affair", []);
                                 }}
                              >
                                 {props.assembly
                                    ? optionsAssembly.map((option) => (
                                         <MenuItem key={option} value={option}>
                                            <ListItemText primary={option} />
                                         </MenuItem>
                                      ))
                                    : options.map((option) => (
                                         <MenuItem key={option} value={option}>
                                            <ListItemText primary={option} />
                                         </MenuItem>
                                      ))}
                              </InputSelectField>
                           </Box>
                           {props.assembly ? (
                              <>
                                 <Typography sx={{ flex: 1, fontWeight: 600 }}>Convocatoria</Typography>
                                 <Box sx={{ flex: 2 }}>
                                    <InputSelectField
                                       name="proclamation"
                                       id="proclamation"
                                       label=""
                                       labelId="proclamation"
                                       fullWidth
                                       size={"small"}
                                    >
                                       {optionsProclamation.map((option) => (
                                          <MenuItem key={option} value={option}>
                                             <ListItemText primary={option} />
                                          </MenuItem>
                                       ))}
                                    </InputSelectField>
                                 </Box>
                              </>
                           ) : (
                              <></>
                           )}
                        </Stack>
                        <Stack direction={"row"}>
                           <Box
                              sx={{
                                 p: 1,
                                 border: 1,
                                 borderColor: "#CBD5E0",
                                 bgcolor: "#F6F7FB",
                                 borderRadius: 1,
                                 flex: 1,
                                 display: "flex",
                                 justifyContent: "center",
                                 maxHeight: 40,
                              }}
                           >
                              <SessionTitle assembly={props.assembly} setCode={setCode} />
                           </Box>
                           <Box sx={{ flex: 4 }}>
                              <InputTextField
                                 id="title"
                                 name="title"
                                 type="text"
                                 size="small"
                                 fullWidth
                                 placeholder="Agregar titulo"
                              />
                           </Box>
                        </Stack>
                        <HoursComponent formProps={formProps} />
                        <CustomInputStyle
                           iconDesign={<LocationOnOutlined sx={{ color: "gray" }} />}
                           inputProps={{
                              id: "location",
                              name: "location",
                              type: "text",
                              fullWidth: true,
                              sx: {
                                 height: 40,
                              },
                              size: "small",
                              placeholder: "Sala de reuniones A",
                              InputProps: {
                                 startAdornment: (
                                    <InputAdornment position="start">
                                       <LocationOnOutlined />
                                    </InputAdornment>
                                 ),
                              },
                           }}
                           unstyled
                           label="Lugar"
                        />
                        <Stack direction={"row"} sx={{ flex: 1, columnGap: 1 }}>
                           <CustomInputStyle
                              iconDesign={<Link sx={{ color: "gray" }} />}
                              inputProps={{
                                 id: "provider",
                                 name: "provider",
                                 type: "text",
                                 fullWidth: true,
                                 sx: {
                                    height: 40,
                                 },
                                 size: "small",
                                 placeholder: "Inserte enlace",
                                 InputProps: {
                                    startAdornment: (
                                       <InputAdornment position="start">
                                          <Link />
                                       </InputAdornment>
                                    ),
                                 },
                              }}
                              unstyled
                              label="Enlace de acceso"
                           />
                           <CustomInputStyle
                              iconDesign={<LockOutlined sx={{ color: "gray" }} />}
                              inputProps={{
                                 id: "meetingURL",
                                 name: "meetingURL",
                                 type: "text",
                                 fullWidth: true,
                                 sx: {
                                    height: 40,
                                 },
                                 size: "small",
                                 placeholder: "ID de reunión / Código",
                                 InputProps: {
                                    startAdornment: (
                                       <InputAdornment position="start">
                                          <Link />
                                       </InputAdornment>
                                    ),
                                 },
                              }}
                              unstyled
                              label="ID de reunión / Código de acceso"
                           />
                        </Stack>
                        <AffairsComponent
                           setAffairAdded={(data) => formProps.setFieldValue("affairAdded", data)}
                           affairAdded={formProps.values.affairAdded}
                           inputProps={{
                              id: "affairAdded",
                              name: "affairAdded",
                           }}
                           classification={props.assembly ? formProps.values.type : null}
                           functions={gobernanceSelected.functions}
                        />

                        {formProps.errors.affairAdded && (
                           <FormHelperText
                              error={
                                 formProps.errors.affairAdded.description && formProps.touched.affairAdded.description
                              }
                           >
                              {formProps.errors.affairAdded.description && formProps.touched.affairAdded.description
                                 ? formProps.errors.affairAdded.description
                                 : null}
                           </FormHelperText>
                        )}

                        {props.assembly ? (
                           <SeriesSelect
                              inputProps={{
                                 id: "series",
                                 name: "series",
                              }}
                              affairSelected={formProps.values.affairAdded}
                           />
                        ) : (
                           <UsersSelector
                              icon={<PeopleAltOutlined sx={{ color: "gray" }} />}
                              inputProps={{
                                 id: "users",
                                 name: "users",
                              }}
                              label={"Invitados"}
                              options={gobernanceBody.filter(
                                 (governance) =>
                                    governance.title !== "PANEL DE USUARIO" &&
                                    governance.title.toLocaleLowerCase() !== "asamblea de accionistas"
                              )}
                              gobernanceSelected={gobernanceSelected}
                              setGobernanceSelected={() => {}}
                              users={users}
                              selectedUsers={formProps.values.users}
                              setUsersSelected={(user) => {
                                 let selectedUsers = formProps.values.users;
                                 if (selectedUsers.some((userSelected) => userSelected._id === user._id)) {
                                    const newUsers = selectedUsers.filter(
                                       (userSelected) => userSelected._id !== user._id
                                    );
                                    formProps.setFieldValue("users", newUsers);
                                 } else {
                                    const newUsers = [...selectedUsers, user];
                                    formProps.setFieldValue("users", newUsers);
                                 }
                              }}
                              selectAllUsers={(users) => {
                                 formProps.setFieldValue("users", users);
                              }}
                              deSelectAllUsers={() => formProps.setFieldValue("users", [])}
                              loadingUsers={loadingUsers}
                              defaultP
                           />
                        )}
                        <ExternGuests />

                        <Typography variant="body2" fontWeight={600}>
                           Adjuntar archivos
                        </Typography>

                        <Grid
                           container
                           sx={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "row",
                              border: 1,
                              borderColor: "#E0E0E0",
                              width: "100%",
                              borderRadius: 1,
                              px: 2,
                           }}
                        >
                           {uploadedFile ? (
                              <>
                                 <Grid
                                    xs={9}
                                    sx={{ display: "flex", flexDirection: "row", alignItems: "center", columnGap: 2 }}
                                 >
                                    <Description sx={{ color: "#162c4490" }} />
                                    <Typography fontSize={12}>{uploadedFile.length} archivo(s) adjunto(s)</Typography>
                                 </Grid>
                                 <Grid xs={2} sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <Typography fontSize={12}>
                                       {formatFileSize(
                                          (uploadedFile as any).reduce((acc, value) => acc + value.size, 0)
                                       )}
                                    </Typography>
                                 </Grid>
                                 <Grid xs={1} sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <IconButton onClick={() => setOpenFileModal(true)}>
                                       <AttachFile />
                                    </IconButton>
                                 </Grid>
                              </>
                           ) : (
                              <>
                                 <Grid xs={9}>
                                    <Typography fontSize={13}>Adjuntar archivos</Typography>
                                 </Grid>
                                 <Grid
                                    xs={3}
                                    sx={{ display: "flex", flexDirection: "row", alignItems: "center", columnGap: 3 }}
                                 >
                                    <Typography fontSize={13}>Subir nuevo</Typography>
                                    <IconButton onClick={() => setOpenFileModal(true)}>
                                       <AttachFile />
                                    </IconButton>
                                 </Grid>
                              </>
                           )}
                        </Grid>
                     </Stack>
                     <Box
                        sx={{
                           display: "flex",
                           justifyContent: "flex-end",
                           bgcolor: "#F3F3F3",
                           columnGap: 1,
                           px: 2,
                           py: 1,
                           borderBottomRightRadius: 5,
                           borderBottomLeftRadius: 5,
                        }}
                     >
                        <Button
                           sx={{ color: "grey" }}
                           onClick={() => (props.scheduler ? props.scheduler.close() : props.setOpen(0))}
                        >
                           Cancelar
                        </Button>
                        {!isLoadingSubmit ? (
                           <Button type="submit" sx={{ height: 40 }}>
                              Guardar
                           </Button>
                        ) : (
                           <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: 50 }}>
                              <CircularProgress size={30} />
                           </Box>
                        )}
                     </Box>
                  </Form>
               );
            }}
         </Formik>
         <AddFileModal
            state={openFileModal}
            setState={setOpenFileModal}
            setFile={setUploadedFile}
            file={uploadedFile}
            multiple
         />
      </Stack>
   );
};
