import { useParams } from "react-router-dom";
import { CorporateDataContext } from "../../../../../context/governanceContext/corporateDataContext";
import { useContext, useState } from "react";
import { useGetMembersInfo } from "../../../../../hooks/gob-corp/corporateData/useGetMembersInfo";
import { Box, CircularProgress, ListItemText, MenuItem, Select, Stack, Typography } from "@mui/material";
import { LoadingButton } from "../../../../LoadingButton";
import MembersList from "../../../../Gobierno corporativo/CorporateDataScreen/shareHolderSubComponents/membersList";
import { EditAssociateModal } from "./Assets/EditAssociateModal";

const AssociateRegistry = () => {
   const { selectedCompanyId } = useParams();
   const { corporateData, handleUpdateLegalRepresentative } = useContext(CorporateDataContext);
   const [openModal, setOpenModal] = useState(false);
   const [selectedUser, setSelectedUser] = useState(null);
   const [representative, setRepresentative] = useState<string>(corporateData?.legalRepresentative || null);
   const { formatedMembers, isLoading } = useGetMembersInfo(false);

   return (
      <>
         <Stack direction={"column"} spacing={2} maxWidth={"100%"}>
            {!isLoading ? (
               <MembersList setOpenModal={setOpenModal} setSelectedUser={setSelectedUser} />
            ) : (
               <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
               </Box>
            )}
            {formatedMembers && corporateData && (
               <Stack key={corporateData._id} direction={"row"} justifyContent={"space-between"}>
                  <Stack direction={"row"} spacing={2} alignItems={"center"}>
                     <Typography>Apoderado legal</Typography>
                     <Select
                        size="small"
                        sx={{ minWidth: "150px" }}
                        defaultValue={representative || corporateData?.legalRepresentative}
                        onChange={(e) => setRepresentative(e.target.value)}
                        renderValue={() => (
                           <Typography>{representative || corporateData?.legalRepresentative}</Typography>
                        )}
                     >
                        {formatedMembers.map((user, i) => (
                           <MenuItem key={i} value={user.name}>
                              <ListItemText primary={user.name} />
                           </MenuItem>
                        ))}
                     </Select>
                  </Stack>
                  <LoadingButton
                     label="Guardar"
                     isLoading={false}
                     onClick={() => handleUpdateLegalRepresentative(representative, selectedCompanyId)}
                  />
               </Stack>
            )}
         </Stack>
         {openModal && (
            <EditAssociateModal
               state={openModal}
               setState={setOpenModal}
               user={selectedUser}
               setUser={setSelectedUser}
            />
         )}
      </>
   );
};

export default AssociateRegistry;
