import { Box, Button, CircularProgress, Grid, IconButton, List, ListItem, Stack, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { CorporateDataContext } from "../../../../../../context/governanceContext/corporateDataContext";
import { CustomModalComponent } from "../../../../../CustomModalComponent";
import { Formik, FormikProps } from "formik";
import { Form } from "react-router-dom";
import { InputTextField } from "../../../../../Inputs/InputTextField";
import { Check, Delete } from "@mui/icons-material";
import { partnerTypeSchema } from "../../../../../../lib/validations/inputSchemas";
import { InputCheckBox } from "../../../../../Inputs/InputCheckBox";
import { UserContext } from "../../../../../../context/userContext";
import { IPartnerTypes } from "../../../../../../types/governance.types";

interface PartnerTypeRegistryProps {
   open: boolean;
   setOpen: Function;
}

const PartnerTypeRegistryModal = ({ open, setOpen }: PartnerTypeRegistryProps) => {
   const { updatePartnerData, isLoading, partnerTypes, setPartnerTypes } = useContext(CorporateDataContext);
   const { handleUpdateEditingSection } = useContext(UserContext);

   const createPartnerType = () => {
      const newType: IPartnerTypes = {
         title: `Socio`,
         voice: false,
         vote: false,
         remnant: false,
      };
      setPartnerTypes(partnerTypes.concat(newType));
      handleUpdateEditingSection("partnerTypes");
   };

   return (
      <>
         <CustomModalComponent timeStamp title="Clase de socios" open={open} setOpen={setOpen}>
            <>
               <Box width={"100%"} sx={{ width: 550 }}>
                  <Grid container sx={{ p: 2, borderBottom: 1, borderColor: "#D8D8D8" }}>
                     {["Clase", "Voz", "Voto", "Remanente", "Acciones"].map((text, index) => (
                        <Grid key={index} item xs={12 / 5}>
                           <Typography fontWeight={600} color="#162c44" textAlign="center">
                              {text}
                           </Typography>
                        </Grid>
                     ))}
                  </Grid>

                  <List sx={{ height: "40vh", overflowY: "auto" }}>
                     {partnerTypes.map((t, i) => (
                        <TypeObject type={t} index={i} />
                     ))}
                     <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", my: 1 }}>
                        <Button onClick={() => createPartnerType()} variant="contained">
                           Agregar nueva clase
                        </Button>
                     </Grid>
                  </List>
               </Box>
               <Box
                  sx={{
                     display: "flex",
                     justifyContent: "flex-end",
                     bgcolor: "#F3F3F3",
                     columnGap: 1,
                     px: 2,
                     py: 1,
                     borderBottomRightRadius: 5,
                     borderBottomLeftRadius: 5,
                  }}
               >
                  <Button sx={{ color: "grey" }} onClick={() => setOpen(false)}>
                     Cancelar
                  </Button>

                  {!isLoading ? (
                     <Button
                        type="submit"
                        sx={{ height: 40 }}
                        onClick={() =>
                           updatePartnerData()
                              .then(() => setOpen(false))
                              .catch((error) => console.error("Error updating partner data:", error))
                        }
                     >
                        Guardar
                     </Button>
                  ) : (
                     <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: 50 }}>
                        <CircularProgress size={30} />
                     </Box>
                  )}
               </Box>
            </>
         </CustomModalComponent>
      </>
   );
};

export default PartnerTypeRegistryModal;

const TypeObject = ({ type, index }) => {
   const [editable, setEditable] = useState(false);
   const { setPartnerTypes, partnerTypes } = useContext(CorporateDataContext);
   const { handleUpdateEditingSection } = useContext(UserContext);

   const updatePartnerTypes = (values) => {
      const tempArray = partnerTypes;
      tempArray[index] = { ...values };
      setPartnerTypes(tempArray);
      handleUpdateEditingSection("partnerTypes");
      setEditable(false);
   };

   const deletePartnerType = (index) => {
      const tempArray = partnerTypes;
      tempArray.splice(index, 1);
      setPartnerTypes(tempArray);
      handleUpdateEditingSection("partnerTypes");
   };

   return (
      <Formik
         initialValues={{ title: type.title, voice: type.voice, vote: type.vote, remnant: type.remnant }}
         onSubmit={updatePartnerTypes}
         validationSchema={partnerTypeSchema}
      >
         {(formProps: FormikProps<any>) => (
            <Form>
               <ListItem onDoubleClick={() => setEditable(true)}>
                  <Grid container alignItems="center">
                     <Grid item xs={2.4}>
                        {editable ? (
                           <InputTextField
                              id="title"
                              name="title"
                              type="text"
                              size="small"
                              variant="standard"
                              centeredText
                           />
                        ) : (
                           <Typography fontWeight={600} color="#162c44" textAlign="center">
                              {type.title}
                           </Typography>
                        )}
                     </Grid>
                     {["voice", "vote", "remnant"].map((field) => (
                        <Grid key={field} item xs={2.4} sx={{ display: "flex", justifyContent: "center" }}>
                           <InputCheckBox id={field} name={field} disabled={!editable} disablemargin />
                        </Grid>
                     ))}
                     <Grid item xs={2.4}>
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                           <IconButton onClick={editable ? formProps.submitForm : () => deletePartnerType(index)}>
                              {editable ? <Check sx={{ color: "#162c44" }} /> : <Delete sx={{ color: "#162c44" }} />}
                           </IconButton>
                        </Box>
                     </Grid>
                  </Grid>
               </ListItem>
            </Form>
         )}
      </Formik>
   );
};
