import React, { useContext, useMemo, useState } from "react";
import { CustomModalComponent } from "../../CustomModalComponent";
import {
   Box,
   Button,
   CircularProgress,
   Grid,
   ListItemText,
   MenuItem,
   Select,
   Stack,
   TextField,
   Typography,
} from "@mui/material";
import { BeneficiaryControllerContext } from "../../../context/beneficiaryContext/beneficiaryContollerContext";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import { addEfectiveControllerToBeneficiary } from "../../../lib/usersBEClient";
import { SnackBarContext } from "../../../context/snackBarContext";
import { ConfirmationModal } from "../../ConfirmationModal";

interface EfectiveControllerModalProps {
   state: boolean;
   setState: (open: boolean) => void;
}

const availableOptions = [
   "Accionista",
   "Accionista / Socio / Asociado",
   "Miembro del consejo de administración",
   "Administrador único",
   "Perfil clave",
   "Poderes y facultades",
];

const EfectiveControllerModal = (props: EfectiveControllerModalProps) => {
   const { beneficiaries, refetch } = useContext(BeneficiaryControllerContext);
   const { showSnackBar } = useContext(SnackBarContext);
   const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
   const [openConfirmation, setOpenConfirmation] = useState(false);

   const filteredBeneficiaries = useMemo(
      () => beneficiaries.filter((b) => availableOptions.includes(b.legalPerson) && !b.effectiveControl),
      [beneficiaries]
   );

   const handleSubmit = async ({ selectedUser }) => {
      setIsLoadingSubmit(true);
      try {
         const response = await addEfectiveControllerToBeneficiary(selectedUser._id);
         if (response) {
            props.setState(false);
            showSnackBar("Se ha registrado control efectivo", false);
            refetch();
         }
      } finally {
         setIsLoadingSubmit(false);
      }
   };

   return (
      <CustomModalComponent title="Registro control efectivo" timeStamp open={props.state} setOpen={props.setState}>
         <Formik
            initialValues={{ selectedUser: { _id: "", email: "", rfc: "", phoneNumber: "", user: null } }}
            onSubmit={({ selectedUser }) => {
               if (!selectedUser._id) return;
               setOpenConfirmation(true);
            }}
         >
            {({ values }) => (
               <Form>
                  <Box>
                     <Stack spacing={2} sx={{ p: 2, width: 700 }}>
                        <Grid container>
                           <Grid item xs={6} sx={{ px: 2, py: 1 }}>
                              <Stack spacing={1}>
                                 <Typography sx={{ color: "#585757" }}>Usuario existente</Typography>
                                 <Field
                                    as={Select}
                                    sx={{ width: "100%" }}
                                    size="small"
                                    variant="outlined"
                                    name="selectedUser"
                                    value={values.selectedUser}
                                 >
                                    {filteredBeneficiaries.map((member) => (
                                       <MenuItem key={member._id} value={member as any}>
                                          <ListItemText
                                             primary={member.name ?? `${member.user.firstName} ${member.user.lastName}`}
                                             sx={{ my: -0.1 }}
                                          />
                                       </MenuItem>
                                    ))}
                                 </Field>
                              </Stack>
                           </Grid>
                           <Grid item xs={6} sx={{ px: 2, py: 1 }}>
                              <Stack spacing={1}>
                                 <Typography sx={{ color: "#585757" }}>RFC</Typography>
                                 <Field
                                    as={TextField}
                                    sx={{ width: "100%" }}
                                    size="small"
                                    variant="outlined"
                                    name="selectedUser"
                                    value={values.selectedUser.rfc}
                                    disabled
                                 />
                              </Stack>
                           </Grid>
                           <Grid item xs={6} sx={{ px: 2, py: 1 }}>
                              <Stack spacing={1}>
                                 <Typography sx={{ color: "#585757" }}>Correo electrónico</Typography>
                                 <Field
                                    as={TextField}
                                    sx={{ width: "100%" }}
                                    size="small"
                                    variant="outlined"
                                    name="selectedUser"
                                    value={values.selectedUser.email ?? values.selectedUser.user.email}
                                    disabled
                                 />
                              </Stack>
                           </Grid>
                           <Grid item xs={6} sx={{ px: 2, py: 1 }}>
                              <Stack spacing={1}>
                                 <Typography sx={{ color: "#585757" }}>Teléfono</Typography>
                                 <Field
                                    as={TextField}
                                    sx={{ width: "100%" }}
                                    size="small"
                                    variant="outlined"
                                    name="selectedUser"
                                    value={values.selectedUser.phoneNumber ?? values.selectedUser.user.phoneNumber}
                                    disabled
                                 />
                              </Stack>
                           </Grid>
                        </Grid>
                     </Stack>
                     <Box
                        sx={{
                           display: "flex",
                           justifyContent: "flex-end",
                           bgcolor: "#F3F3F3",
                           width: "100%",
                           p: 1,
                           height: 50,
                           borderBottomRightRadius: 5,
                           borderBottomLeftRadius: 5,
                        }}
                     >
                        <Button
                           onClick={() => props.setState(false)}
                           sx={{ color: "black", height: 35, ":hover": { bgcolor: "#E5E6EB" }, mr: 2 }}
                        >
                           Cancelar
                        </Button>
                        <Button
                           type="submit"
                           disabled={isLoadingSubmit}
                           sx={{ height: 35, ":hover": { bgcolor: "success" } }}
                        >
                           {isLoadingSubmit ? <CircularProgress size={25} /> : "Guardar"}
                        </Button>
                     </Box>
                  </Box>
                  <ConfirmationModal
                     open={openConfirmation}
                     setOpen={setOpenConfirmation}
                     generic
                     onConfirm={() => handleSubmit(values)}
                     cancelButton={false}
                  />
               </Form>
            )}
         </Formik>
      </CustomModalComponent>
   );
};

export default EfectiveControllerModal;
