import React, { useContext, useState } from "react";
import { CustomModalComponent } from "../../CustomModalComponent";
import { Box, Button, Typography } from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import { InputTextField } from "../../Inputs/InputTextField";
import { userCreateSchemaNew } from "../../../lib/validations/inputSchemas";
import { UserContext } from "../../../context/userContext";
import { addUserRoleOffice, getUserByEmail, useCreateUser } from "../../../lib/usersBEClient";
import { SnackBarContext } from "../../../context/snackBarContext";
import { LoadingButton } from "../../LoadingButton";

const initialValues = {
   firstName: "",
   lastName: "",
   phoneNumber: "",
   email: "",
};

interface AddGroupUserProps {
   state: boolean;
   setState: Function;
}

const AddCompanyUser = (props: AddGroupUserProps) => {
   const { state, setState } = props;
   const { showSnackBar } = useContext(SnackBarContext);
   const { companySelected, refetch } = useContext(UserContext);
   const { mutate } = useCreateUser();
   const [foundByEmail, setFoundByEmail] = useState(null);
   const [isLoadingCreate, setIsLoadingCreate] = useState(false);

   const submitInformation = async (values) => {
      setIsLoadingCreate(true);
      if (foundByEmail) {
         const data = {
            company: companySelected._id,
            roles: [],
         };
         await addUserRoleOffice(data, foundByEmail._id);
         showSnackBar("El usuario fue agregado correctamente.", false);
         refetch();
         handleOnCloseModal();
      } else {
         mutate(
            {
               userData: {
                  firstName: values.firstName,
                  lastName: values.lastName,
                  email: values.email,
                  phoneNumber: values.phoneNumber,
                  role: [{ company: companySelected._id, roles: [] }],
               },
               additionalData: {
                  branches: [],
                  admin: false,
                  company: companySelected._id,
               },
            },
            {
               onError: (error: any) => {
                  error.response.data.message === "email already in use"
                     ? showSnackBar("Ya existe un usuario con ese correo", true)
                     : showSnackBar("Error al agregar usuario.", true);

                  console.log(error);
               },
               onSuccess: async () => {
                  showSnackBar("El usuario fue agregado correctamente.", false);
                  refetch();
                  handleOnCloseModal();
               },
            }
         );
      }
      setIsLoadingCreate(false);
   };

   const handleOnCloseModal = () => setState(false);

   const searchUserByEmail = async (email: string, setFieldValue) => {
      if (
         email === companySelected?.company_details?.admin?.email ||
         (companySelected?.company_details.users as any).some((u) => u.email === email)
      ) {
         return setFoundByEmail(null);
      }
      const userFound = await getUserByEmail(email);
      if (!userFound) return setFoundByEmail(null);
      setFoundByEmail(userFound);

      setFieldValue("firstName", userFound.firstName);
      setFieldValue("lastName", userFound.lastName);
      setFieldValue("email", userFound.email);
      setFieldValue("phoneNumber", userFound.phoneNumber);
   };

   return (
      <CustomModalComponent open={state} setOpen={setState} timeStamp title="Agregar nuevo usuario">
         <Box sx={{ p: 4, width: 800 }}>
            <Typography fontWeight={600}>Información del usuario</Typography>
            <Formik initialValues={initialValues} onSubmit={submitInformation} validationSchema={userCreateSchemaNew}>
               {(formProps: FormikProps<any>) => (
                  <Form>
                     <Box sx={{ display: "flex", columnGap: 2, pt: 2 }}>
                        <InputTextField
                           label="Nombre(s)"
                           id="firstName"
                           name="firstName"
                           type="text"
                           fullWidth={true}
                           variant="outlined"
                           size="small"
                           disabled={!!foundByEmail}
                        />
                        <InputTextField
                           label="Apellido(s)"
                           id="lastName"
                           name="lastName"
                           type="text"
                           fullWidth={true}
                           size="small"
                           variant="outlined"
                           disabled={!!foundByEmail}
                        />
                     </Box>
                     <Box sx={{ display: "flex", columnGap: 2, pt: 2 }}>
                        <InputTextField
                           label="Teléfono"
                           id="phoneNumber"
                           name="phoneNumber"
                           type="text"
                           fullWidth={true}
                           size="small"
                           variant="outlined"
                           disabled={!!foundByEmail}
                        />
                        <InputTextField
                           label="Correo electrónico"
                           id="email"
                           name="email"
                           type="text"
                           fullWidth={true}
                           size="small"
                           variant="outlined"
                           onBlur={() => searchUserByEmail(formProps.values.email, formProps.setFieldValue)}
                        />
                     </Box>

                     <Box sx={{ display: "flex", justifyContent: "space-between", pt: 3 }}>
                        <Button variant="contained" sx={{ opacity: 0.5, height: 40 }} onClick={handleOnCloseModal}>
                           Cancelar
                        </Button>
                        <LoadingButton isLoading={isLoadingCreate} label="Guardar" />
                     </Box>
                  </Form>
               )}
            </Formik>
         </Box>
      </CustomModalComponent>
   );
};
export default AddCompanyUser;
