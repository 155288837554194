import { Box, Stack, Tab, Tabs } from "@mui/material";
import { useContext, useState } from "react";
import { CorporateDataContext } from "../../context/governanceContext/corporateDataContext";
import { SnackBarContext } from "../../context/snackBarContext";
import PartnerTypeRegistryModal from "../../components/MiLecosy/Services/Governance/CorporateData/Assets/PartnerTypeRegistryModal";
import AssociateRegistry from "../../components/MiLecosy/Services/Governance/CorporateData/AssociateRegistry";
import { LoadingButton } from "../../components/LoadingButton";
import { AddAssociateModal } from "../../components/MiLecosy/Services/Governance/CorporateData/Assets/AddAssociateModal";

interface TabPanelProps {
   children?: React.ReactNode;
   index: number;
   value: number;
}

function CustomTabPanel(props: Readonly<TabPanelProps & { disabled?: boolean }>) {
   const { children, value, index, disabled, ...other } = props;

   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`simple-tabpanel-${index}`}
         aria-labelledby={`simple-tab-${index}`}
         aria-disabled={disabled}
         {...other}
         style={{ pointerEvents: disabled ? "none" : "auto", opacity: disabled ? 0.5 : 1 }}
      >
         {value === index && <Box>{children}</Box>}
      </div>
   );
}

const CivilCorporateDataScreen = () => {
   const [value, setValue] = useState(0);
   const [openModal, setOpenModal] = useState(false);
   const [openPartnerModal, setOpenPartnerModal] = useState(false);

   const handleChange = (event: React.SyntheticEvent, newValue: number) => setValue(newValue);

   return (
      <Stack sx={{ maxWidth: 1380 }}>
         <Stack direction={"row"}>
            <Tabs value={value} onChange={handleChange} sx={{ flex: 1 }}>
               <Tab label={"Registro de socios"} />
               <Tab label={"Análisis societario"} disabled />
            </Tabs>
            <Stack
               flex={1}
               direction={"row"}
               justifyContent={"flex-end"}
               spacing={2}
               display={value === 0 ? "auto" : "none"}
            >
               <Box alignSelf={"center"} sx={{ display: "flex", columnGap: 2 }}>
                  <LoadingButton
                     isLoading={false}
                     label="Clase de socios"
                     onClick={() => setOpenPartnerModal((s) => !s)}
                     sx={{ mb: 0 }}
                  />
                  <LoadingButton
                     isLoading={false}
                     label="Agregar nuevo"
                     onClick={() => setOpenModal(!openModal)}
                     sx={{ mb: 0 }}
                  />
               </Box>
            </Stack>
         </Stack>
         <CustomTabPanel value={value} index={0}>
            <AssociateRegistry />
         </CustomTabPanel>
         {/* <CustomTabPanel value={value} index={1}>
            <SocietaryAnalysis /> TODO
         </CustomTabPanel> */}

         {<AddAssociateModal state={openModal} setState={setOpenModal} />}
         {openPartnerModal && <PartnerTypeRegistryModal open={openPartnerModal} setOpen={setOpenPartnerModal} />}
      </Stack>
   );
};

export default CivilCorporateDataScreen;
