import { useState, useEffect, useContext, useRef, useMemo } from "react";
import { Box, CircularProgress, Collapse, Stack, Typography } from "@mui/material";
import { ChatTextInputComponent } from "./SubComponent/ChatTextInputComponent";
import { MessageComponent } from "./SubComponent/MessageComponent";
import { HeaderChatComponent } from "./SubComponent/HeaderChatComponent";
import { ChatContext } from "../../../context/governanceContext/ChatContext";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { UserContext } from "../../../context/userContext";
import { authormessage, receivedUser } from "./SubComponent/ConversationComponent";
import Lottie from "lottie-react";
import Typing from "./SubComponent/Typing.json";
import { useParams } from "react-router-dom";
import { UserInformationDrawer } from "./SubComponent/UserInformationDrawer";
import { CopyFileToFolder, CreateFile, VerifyChatFolderByName, getUrlS3 } from "../../../lib/usersBEClient";
import { useGetResourceSelected } from "../../../lib/gobCorpBEClient";
import { GovernanceContext } from "../../../context/governanceContext/governanceContext";
import { SnackBarContext } from "../../../context/snackBarContext";
import { Users } from "../../../types/BaseTypes";

const shouldDisplayDivider = (messages, currentIndex) => {
   if (currentIndex === messages.length - 1) {
      return true;
   }
   const currentDate = new Date(messages[currentIndex].createdAt);
   const previousIndex = currentIndex + 1;

   if (previousIndex >= 0 && messages[previousIndex]?.createdAt) {
      const previousDate = new Date(messages[previousIndex].createdAt);
      return (
         currentDate.getFullYear() !== previousDate.getFullYear() ||
         currentDate.getMonth() !== previousDate.getMonth() ||
         currentDate.getDate() !== previousDate.getDate()
      );
   }
   return false;
};
export const Chat = () => {
   const { user } = useContext(UserContext);
   const {
      socket,
      currentChat,
      isLoadingMessages,
      messages,
      sendTextMessage,
      typing,
      setPage,
      page,
      totalPages,
      setIsLoadingMessages,
      conversations,
      setCurrentChat,
      fileUploaded,
      groups,
      setFileUploaded,
      fileSelected,
      setFileSelected,
      setIsUploadingFile,
   } = useContext(ChatContext);
   const { id } = useParams();
   const msgRef = useRef(null);
   const containerRef = useRef(null);
   const [textMessage, setTextMessage] = useState("");
   const [msgIsVisible, setMsgIsVisible] = useState(false);
   const recipient = currentChat?.participants.find((u) => u._id !== user.id);
   const [openDrawer, setOpenDrawer] = useState(false);
   const { mutate } = VerifyChatFolderByName();
   const { mutate: createFile } = CreateFile();
   const { mutate: copyFileToFolder } = CopyFileToFolder();
   const resourceId = useGetResourceSelected();
   const { companySelected } = useContext(GovernanceContext);
   const [profilePic, setProfilePic] = useState("");
   const { showSnackBar } = useContext(SnackBarContext);

   useEffect(() => {
      const getPicture = async () => {
         const url = await getUrlS3("files-lecosy", { folder: `users/${recipient?._id}` }, "profile.png");
         setProfilePic(url);
      };
      getPicture();
   }, [recipient]);

   useEffect(() => {
      if (!id) {
         setCurrentChat(null);
      } else if (id && !currentChat) {
         const paramChat = conversations.find((conversation) => conversation._id === id);
         setCurrentChat(paramChat);
      }
   }, [id]);

   useEffect(() => {
      if (!containerRef.current) return;
      if (messages.length === 0) return;
      if (messages[messages.length - 1].authorId === user.id) {
         const ultimoMensaje = messages[messages.length - 1];
         const fechaActual: Date = new Date();
         const fechaUltimoMensaje: Date = new Date(ultimoMensaje.createdAt);
         if (fechaActual.getTime() - fechaUltimoMensaje.getTime() > 1000) {
            return;
         }
         containerRef.current.scrollTop = containerRef.current.scrollHeight;
         return;
      }
      Scroll();
   }, [messages]);

   useEffect(() => {
      if (!msgRef.current) return;
      if (page < totalPages && !isLoadingMessages) {
         const observer = new IntersectionObserver((entries) => {
            const entry = entries[0];
            setMsgIsVisible(entry.isIntersecting);
         });
         observer.observe(msgRef.current);
         return () => observer.disconnect();
      }
   }, [messages, page]);

   useEffect(() => {
      if (!msgIsVisible) return;
      setIsLoadingMessages(true);
      setPage((prev) => prev + 1);
   }, [msgIsVisible]);

   const setIndex = useMemo(() => {
      const lastMessageIndex = messages.length - 1;
      return lastMessageIndex;
   }, [messages]);

   const Scroll = () => {
      const { offsetHeight, scrollHeight, scrollTop } = containerRef.current as HTMLDivElement;
      if (scrollHeight <= scrollTop + offsetHeight + 100) {
         containerRef.current.scrollTo(0, scrollHeight);
      }
   };

   useEffect(() => {
      setOpenDrawer(false);
   }, [currentChat]);

   useEffect(() => {
      const fetchChatFolder = async () => {
         setIsUploadingFile(true);
         const receiver = currentChat.participants.find(
            (participant) => participant.firstName + " " + participant.lastName === currentChat.title
         )._id;
         mutate(
            {
               name: currentChat.title,
               userId: user.id,
            },
            {
               onError: async (error) => {
                  showSnackBar("Error al cargar archivo", true);
                  setIsUploadingFile(false);
                  console.error(error);
               },
               onSuccess: async (data) => {
                  setChatFolder(data);
                  mutate(
                     {
                        name: user.firstName + " " + user.lastName,
                        userId: receiver,
                     },
                     {
                        onError: async (error) => {
                           console.error(error);
                           setIsUploadingFile(false);
                        },
                        onSuccess: async (data) => {
                           const chatFolderReceiverData = { receiver: receiver, chatFolder: data };
                           setChatFolderReceiver(chatFolderReceiverData);
                           setIsUploadingFile(false);
                        },
                     }
                  );
               },
            }
         );
      };
      if (fileUploaded || fileSelected) fetchChatFolder();
   }, [fileUploaded, fileSelected]);

   const [chatFolder, setChatFolder] = useState(null);
   const [chatFolderReceiver, setChatFolderReceiver] = useState(null);
   const sendMessage = (e) => {
      if (!textMessage && textMessage.trim() === "" && !(fileUploaded || fileSelected)) return;
      sendTextMessage(
         textMessage,
         user.id,
         currentChat,
         sendTextMessage,
         socket,
         conversations,
         groups,
         fileUploaded,
         createFile,
         chatFolder,
         chatFolderReceiver,
         fileSelected,
         copyFileToFolder,
         companySelected,
         resourceId
      );
      setFileUploaded(null);
      setFileSelected(null);
      setTextMessage("");
      e.preventDefault();
   };

   if (!currentChat)
      return (
         <Stack
            sx={{
               flex: 4,
               bgcolor: "white",
               borderRadius: 2,
               boxShadow: 1,
               justifyContent: "center",
               alignItems: "center",
            }}
         >
            <InfoOutlinedIcon sx={{ height: 150, width: 150, color: "#E0E0E0" }} />
            <Typography fontSize={14} sx={{ color: "#E0E0E0" }}>
               Seleccione un chat para iniciar una conversación
            </Typography>
         </Stack>
      );

   return (
      <>
         <Box
            sx={{
               display: "flex",
               flex: 4,
               bgcolor: "white",
               width: "60vw",
               maxHeight: "72vh",
               borderRadius: 2,
               boxShadow: 1,
               position: "relative",
            }}
         >
            <Stack sx={{ display: "flex", flex: 1 }}>
               <Box sx={{ display: "flex", width: "100%", pt: 2, px: 2 }}>
                  <HeaderChatComponent
                     profilePic={profilePic}
                     user={recipient}
                     userReceived={receivedUser(currentChat?.participants, user)}
                     openDrawer={setOpenDrawer}
                     open={openDrawer}
                     title={currentChat?.title}
                     type={currentChat?.type}
                  />
               </Box>
               <Box
                  ref={containerRef}
                  sx={{
                     flexGrow: 1,
                     overflowY: "auto",
                     p: 2,
                     width: "100%",
                     flexDirection: "column-reverse",
                     display: "flex",
                  }}
               >
                  {messages.length > 0 &&
                     messages.map((msg, index) => (
                        <Box ref={index === setIndex ? msgRef : null} key={msg._id} id={msg._id}>
                           {shouldDisplayDivider(messages, index) ? (
                              <Stack
                                 direction={"row"}
                                 sx={{ flex: 1, direction: "flex", justifyContent: "center", my: 2 }}
                              >
                                 <Box sx={{ px: 2, py: 0.5, borderRadius: 6, border: 1.5, borderColor: "#E6EBF5" }}>
                                    <Typography fontSize={14} fontWeight={600} sx={{ color: "#7D8592" }}>
                                       {new Date(messages[index].createdAt).toLocaleDateString("es-ES", {
                                          weekday: "long",
                                          day: "numeric",
                                          month: "long",
                                       })}
                                    </Typography>
                                 </Box>
                              </Stack>
                           ) : null}

                           <MessageComponent
                              profilePic={profilePic}
                              author={user.id}
                              message={msg}
                              messages={messages}
                              index={index}
                              receivedUser={async () => await authormessage(currentChat.participants, msg.authorId)}
                              key={msg._id}
                           />
                        </Box>
                     ))}
                  {isLoadingMessages ? (
                     <Box sx={{ display: "flex", flex: 1, justifyContent: "center" }}>
                        <CircularProgress />
                     </Box>
                  ) : null}
               </Box>
               <Collapse in={typing} timeout={50} unmountOnExit sx={{ p: 1 }}>
                  <Box sx={{ width: 60, height: 60 }}>
                     <Lottie animationData={Typing} loop={true}></Lottie>
                  </Box>
               </Collapse>
               <Box sx={{ width: "100%" }}>
                  <ChatTextInputComponent
                     newMessage={textMessage}
                     setNewMessage={setTextMessage}
                     submit={sendMessage}
                  />
               </Box>
            </Stack>
            <Collapse
               in={openDrawer}
               unmountOnExit
               orientation="horizontal"
               sx={{
                  display: "flex",
                  position: "absolute",
                  right: "0",
                  backgroundColor: "white",
                  borderLeft: 0.3,
                  borderColor: "#E0E0E0",
               }}
            >
               <UserInformationDrawer
                  profilePic={profilePic}
                  setOpenDrawer={setOpenDrawer}
                  user={receivedUser(currentChat?.participants, user)}
                  conversation={currentChat}
               />
            </Collapse>
         </Box>
      </>
   );
};
