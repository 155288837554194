import { Box, Menu, MenuItem, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { ConfirmationModal } from "../../../ConfirmationModal";
import { DeleteFile } from "../../../../lib/usersBEClient";
import { useParams } from "react-router-dom";
import { SnackBarContext } from "../../../../context/snackBarContext";

interface VerifyFileMenuProps {
   anchorEl: HTMLElement | null;
   open: boolean;
   handleClose: () => void;
   setVerify: (value: boolean) => void;
   setOpenConfirmationModal: (value: boolean) => void;
   selectedFile: any;
   setReload: Function;
}

const VerifyFileMenu = (props: VerifyFileMenuProps) => {
   const { anchorEl, open, handleClose, setVerify, setOpenConfirmationModal, selectedFile, setReload } = props;
   const { companyId } = useParams();
   const { showSnackBar } = useContext(SnackBarContext);
   const [openModalDelete, setOpenModalDelete] = useState(false);
   const { mutate, isLoading: isLoadingDelete } = DeleteFile(selectedFile ? selectedFile._id : "", companyId);

   const handleSuccess = (message: string) => {
      showSnackBar(message, false);
      setReload((r) => !r);
      handleClose();
   };

   const handleDeleteFile = async () => {
      mutate(null, {
         onError: (error: any) => {
            if (error?.response?.data === "unauthorized")
               return showSnackBar("Sin permisos para modificar el archivo.", true);
            showSnackBar("Error al eliminar archivo.", true);
         },
         onSuccess: () => {
            handleSuccess("El archivo se eliminó correctamente.");
            setOpenModalDelete(false);
         },
      });
   };

   return (
      <div>
         <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
               vertical: "top",
               horizontal: "left",
            }}
            transformOrigin={{
               vertical: "top",
               horizontal: "left",
            }}
         >
            <MenuItem
               onClick={() => {
                  setVerify(true);
                  setOpenConfirmationModal(true);
               }}
            >
               Aceptar
            </MenuItem>
            <MenuItem
               onClick={() => {
                  setVerify(false);
                  setOpenConfirmationModal(true);
               }}
            >
               Rechazar
            </MenuItem>
            <MenuItem onClick={() => setOpenModalDelete(true)}>Eliminar</MenuItem>
         </Menu>
         <ConfirmationModal
            open={openModalDelete}
            setOpen={setOpenModalDelete}
            onConfirm={() => handleDeleteFile()}
            body={
               <Box sx={{ pt: 2 }}>
                  <Typography align="center">
                     {selectedFile && selectedFile.type === "carpeta"
                        ? "¿Está seguro que desea eliminar la carpeta?"
                        : "¿Está seguro que desea eliminar el archivo?"}
                  </Typography>
               </Box>
            }
            isLoading={isLoadingDelete}
            title={selectedFile && selectedFile.type === "carpeta" ? "Eliminar carpeta" : "Eliminar archivo"}
         />
      </div>
   );
};

export default VerifyFileMenu;
