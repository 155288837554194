import { Box, Step, StepLabel, Stepper } from "@mui/material";
import { BodyUserFormat, GovernBodyData } from "./GoverningBodiesComponent";
import { NewGoverningBodyModal } from "./NewGoverningBodyModal";
import { NewUserGovernBody } from "./NewUserGovernBody";
import { useState } from "react";
import { ConsultiveGroup } from "../../types/governance.types";

interface StepperGovernBodyUsersProps {
   state: boolean;
   setState: Function;
   setSeed: Function;
   company?: any;
   group?: ConsultiveGroup;
   titlesArray?: string[];
   bodyData?: GovernBodyData;
   bodyUsers?: BodyUserFormat[];
   phase: number;
   setBodyData: Function;
   setPhase: Function;
   bodyEdition: boolean;
   setBodyUsers: Function;
}

const steps = ["Información general", "Miembros"];

export const StepperGovernBodyUsers = (props: StepperGovernBodyUsersProps) => {
   const {
      phase,
      setPhase,
      setSeed,
      setState,
      state,
      bodyData,
      bodyUsers,
      company,
      group,
      titlesArray,
      setBodyData,
      bodyEdition,
      setBodyUsers,
   } = props;
   const [userModalSeed, setUserModalSeed] = useState(0);

   return (
      <Box sx={{ maxWidth: 800 }}>
         <Stepper activeStep={phase} sx={{ px: 6 }}>
            {steps.map((label, index) => {
               const stepProps: { completed?: boolean } = {};
               return (
                  <Step
                     {...stepProps}
                     key={index}
                     sx={{
                        width: 200,
                        height: 80,
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                     }}
                  >
                     <StepLabel>{label}</StepLabel>
                  </Step>
               );
            })}
         </Stepper>
         {phase === 0 ? (
            <NewGoverningBodyModal
               setSeed={setSeed}
               company={company}
               group={group}
               setState={setState}
               titlesArray={titlesArray}
               setPhase={setPhase}
               setBodyData={setBodyData}
               bodyData={bodyData}
               bodyEdition={bodyEdition}
               setUserModalSeed={setUserModalSeed}
               setBodyUsers={setBodyUsers}
            />
         ) : (
            <NewUserGovernBody
               key={userModalSeed}
               bodyData={bodyData}
               company={company?._id}
               group={group?._id}
               bodyUsers={bodyUsers}
               setSeed={setSeed}
               setState={setState}
               state={state}
            />
         )}
      </Box>
   );
};
