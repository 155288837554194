import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import AudioFileIcon from "@mui/icons-material/AudioFile";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import LinkIcon from "@mui/icons-material/Link";
import {
   Avatar,
   Box,
   Button,
   Card,
   CardActions,
   CardContent,
   CircularProgress,
   Collapse,
   IconButton,
   Modal,
   Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { getFileById, getFileByIdChat, getUrlS3GC } from "../../../../lib/usersBEClient";
import Close from "@mui/icons-material/Close";
import { VideoPlayer } from "../../MyFilesScreen/SubComponents/VideoPlayer";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import { Users } from "../../../../types/BaseTypes";
interface IMessage {
   message: any;
   author: string;
   messages;
   index;
   receivedUser: () => Promise<Users>;
   file?;
   profilePic?;
}

export const isSameSender = (messages, i, userId) => {
   return (
      i < messages.length - 1 && messages[i]?.authorId !== messages[i + 1]?.authorId && messages[i]?.authorId !== userId
   );
};

const shouldDisplayDate = (messages, currentIndex) => {
   if (currentIndex === 0) {
      return true;
   } else {
      return messages[currentIndex]?.authorId !== messages[currentIndex - 1]?.authorId;
   }
};

function stringAvatar(name: string) {
   return {
      sx: { color: "#162C44", bgcolor: "#F4F9FD", fontWeight: "bold", fontSize: 16 },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
   };
}

export const isLastMessage = (messages, i, userId) => {
   return i === messages.length + 1 && messages[i].authorId !== userId;
};

const linkify = (text) => {
   const url = text.match(/https?:\/\/[^\s]+/);
   if (url) {
      let pageNameRaw = url[0].match(/https:\/\/([^\/]+)\.com/)[1];
      pageNameRaw = pageNameRaw.replace(/www\./, "");
      const pageName = (pageNameRaw.charAt(0).toUpperCase() + pageNameRaw.slice(1)).replace(".", " ");
      return { url, pageName };
   } else {
      return null;
   }
};

const useGetFile = (messageFileId, setFile, file, setUrlLink, setIsLoading, pdfPage, setPdfPage, urlLink) => {
   useEffect(() => {
      const fileFetch = async () => {
         const fileData = await getFileByIdChat(messageFileId);
         if (fileData) setFile(fileData);
         else setFile({ type: "noAvailable" });
      };
      if (messageFileId) {
         setIsLoading(true);
         fileFetch();
      } else {
         setFile(null);
      }
   }, []);

   useEffect(() => {
      const getFileUrl = async () => {
         const imagesUrlResponse = await getUrlS3GC("files-lecosy", { folder: file.fileDirection }, file.name);
         if (pdfPage) window.open(imagesUrlResponse);
         setUrlLink(imagesUrlResponse);
         setIsLoading(false);
      };
      if (pdfPage) {
         getFileUrl();
         setPdfPage(false);
      } else if (
         file?.type.split("/")[1] === "png" ||
         file?.type.split("/")[1] === "jpg" ||
         file?.type.split("/")[1] === "svg" ||
         file?.type.split("/")[1] === "jpeg" ||
         file?.type.split("/")[1] === "mp4"
      ) {
         getFileUrl();
      }
   }, [file, pdfPage]);
};

export const MessageComponent = (props: IMessage) => {
   const [author, setAuthor] = useState(null);
   const [file, setFile] = useState(null);
   const [urlLink, setUrlLink] = useState("");
   const [openVideoPlayer, setOpenVideoPlayer] = useState(false);
   const [openImage, setOpenImage] = useState(false);
   const [pdfPage, setPdfPage] = useState(false);
   const [isLoading, setIsLoading] = useState(false);
   useGetFile(props.message.file, setFile, file, setUrlLink, setIsLoading, pdfPage, setPdfPage, urlLink);

   const compareDate = useMemo(() => {
      if (!props.message.createdAt) return "";

      const lastCreatedMsg = new Date(props.message.createdAt);
      const hours = lastCreatedMsg.getHours();
      const minutes = lastCreatedMsg.getMinutes();

      const now = new Date();
      const isSameDay =
         lastCreatedMsg.getFullYear() === now.getFullYear() &&
         lastCreatedMsg.getMonth() === now.getMonth() &&
         lastCreatedMsg.getDate() === now.getDate();

      if (isSameDay) {
         return (hours % 12 || 12) + ":" + (minutes < 10 ? "0" : "") + minutes + " " + (hours >= 12 ? "PM" : "AM");
      } else {
         return lastCreatedMsg.toLocaleDateString("es-MX", {
            day: "numeric",
            month: "numeric",
            year: "numeric",
         });
      }
   }, [props.message.createdAt]);

   const link = linkify(props.message.content);

   const handleClick = () => {
      const getFileUrl = async () => {
         if (file?.type.split("/")[1] === "mp4") {
            setOpenVideoPlayer(true);
         } else if (
            file.type.split("/")[1] === "png" ||
            file.type.split("/")[1] === "jpg" ||
            file.type.split("/")[1] === "jpeg" ||
            file.type.split("/")[1] === "svg"
         ) {
            setOpenImage(true);
         } else if (file.type.split("/")[1] === "pdf") {
            setPdfPage(true);
         }
      };
      getFileUrl();
   };

   useEffect(() => {
      const fetchPromise = async () => {
         const promise = await props.receivedUser();
         setAuthor(promise);
      };
      fetchPromise();
   }, []);

   if (!author) return <CircularProgress />;

   return (
      <Box
         sx={{
            flex: 1,
            display: "flex",
            alignItems: "start",
            justifyContent: props.author === props.message.authorId ? "flex-end" : "flex-start",
            mb: 1,
            columnGap: 1,
         }}
         key={props.index}
      >
         {isSameSender(props.messages, props.index, props.author) ||
         isLastMessage(props.messages, props.index, props.author) ? (
            <Avatar
               src={props.profilePic}
               sx={{ size: "small" }}
               {...stringAvatar(`${author.firstName} ${author.lastName}`)}
            />
         ) : null}
         <Box
            sx={{
               maxWidth: "50%",
            }}
         >
            {isSameSender(props.messages, props.index, props.author) ||
            isLastMessage(props.messages, props.index, props.author) ? (
               <>
                  {props.author !== props.message.authorId && (
                     <Typography
                        fontSize="15px"
                        sx={{
                           maxWidth: "100%",
                           wordWrap: "break-word",
                           overflowWrap: "break-word",
                           wordBreak: "break-all",
                           fontWeight: "bold",
                        }}
                     >
                        {author.firstName} {author.lastName}
                     </Typography>
                  )}
                  {author.iscoord !== undefined && author.iscoord === true && (
                     <Typography
                        fontSize="12px"
                        color={"#cecccc"}
                        sx={{
                           maxWidth: "100%",
                           wordWrap: "break-word",
                           overflowWrap: "break-word",
                           wordBreak: "break-all",
                        }}
                     >
                        (Coordinador Gobierno Corporativo)
                     </Typography>
                  )}
               </>
            ) : null}

            <Box
               sx={{
                  p: 1,
                  backgroundColor: props.author === props.message.authorId ? "#E6EDF5" : "#FAFAFA",
                  borderRadius: 2,
                  ml:
                     !(
                        isSameSender(props.messages, props.index, props.author) ||
                        isLastMessage(props.messages, props.index, props.author)
                     ) && props.author !== props.message.authorId
                        ? 6
                        : 0,
                  display: "inline-block",
               }}
            >
               {file &&
                  (file.type.split("/")[1] === "png" ||
                  file.type.split("/")[1] === "jpg" ||
                  file.type.split("/")[1] === "jpeg" ||
                  file.type.split("/")[1] === "svg" ? (
                     <Box
                        sx={{
                           border: 1,
                           borderColor: "silver",
                           overflow: "hidden",
                           display: "flex",
                           alingItems: "center",
                           justifyContent: "center",
                           maxWidth: "400px",
                           maxHeight: "400px",
                        }}
                     >
                        {isLoading ? (
                           <Box
                              sx={{ width: "500px", height: "500px" }}
                              display={"flex"}
                              alignItems={"center"}
                              justifyContent={"center"}
                           >
                              <CircularProgress size={100} />
                           </Box>
                        ) : (
                           <img
                              src={urlLink}
                              alt={file?.name}
                              onClick={handleClick}
                              style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "cover" }}
                           />
                        )}
                     </Box>
                  ) : file.type === "noAvailable" ? (
                     <Box
                        sx={{
                           display: "flex",
                           bgcolor: "#F9F9F9",
                           flexDirection: "row",
                           justifyContent: "space-between",
                           alignItems: "center",
                           gap: 0.5,
                        }}
                     >
                        <NotInterestedIcon sx={{ fontSize: 22, color: "#DADADA" }} />
                        <Typography sx={{ fontSize: 14, flexWrap: "nowrap" }}>Archivo no disponible</Typography>
                     </Box>
                  ) : (
                     <Card
                        sx={{
                           display: "flex",
                           bgcolor: "#F9F9F9",
                           flexDirection: "row",
                           justifyContent: "space-between",
                           mt: 0.5,
                           boxShadow: 0,
                           border: 1,
                           borderColor: "#E2E2E2",
                        }}
                     >
                        <CardContent>
                           <Typography sx={{ fontSize: 14, flexWrap: "nowrap" }}>{file?.name}</Typography>
                        </CardContent>
                        <CardActions>
                           <IconButton onClick={handleClick}>
                              {file.type.split("/")[1] === "pdf" ? (
                                 <PictureAsPdfIcon />
                              ) : file.type.split("/")[1] === "mp3" || file.type.split("/")[1] === "mpeg" ? (
                                 <AudioFileIcon />
                              ) : file.type.split("/")[1] === "mp4" ? (
                                 <PlayCircleOutlineIcon />
                              ) : (
                                 <TextSnippetIcon />
                              )}
                           </IconButton>
                        </CardActions>
                     </Card>
                  ))}

               <Typography
                  fontSize="14px"
                  sx={{
                     display: "block",
                     maxWidth: "100%",
                     wordWrap: "break-word",
                     overflowWrap: "break-word",
                     wordBreak: "break-all",
                  }}
               >
                  {props.message.content}
               </Typography>
               {link && (
                  <Button
                     sx={{
                        mt: 1,
                        bgcolor: props.author === props.message.authorId ? "#FAFAFA" : "#E6EDF5",
                     }}
                     onClick={() => {
                        window.open(link.url);
                     }}
                  >
                     <LinkIcon sx={{ mr: 1 }} />
                     <Typography
                        fontWeight={700}
                        color={"#113460"}
                        fontSize="16px"
                        sx={{
                           pr: 1,
                        }}
                     >
                        {link.pageName}
                     </Typography>
                  </Button>
               )}
            </Box>
            {shouldDisplayDate(props.messages, props.index) ? (
               <Typography
                  fontSize="12px"
                  fontWeight={600}
                  sx={{
                     mt: 0.5,
                     color: "#7D8592",
                     display: "flex",
                     justifyContent: props.author === props.message.authorId ? "flex-end" : "flex-start",
                     ml:
                        !(
                           isSameSender(props.messages, props.index, props.author) ||
                           isLastMessage(props.messages, props.index, props.author)
                        ) && props.author !== props.message.authorId
                           ? 6
                           : 0,
                  }}
               >
                  {compareDate}
               </Typography>
            ) : null}
         </Box>
         <Modal open={openVideoPlayer || openImage}>
            <Box sx={{ overflow: "auto", maxHeight: "100vh" }}>
               <Collapse unmountOnExit in={openVideoPlayer}>
                  <Box sx={{ display: "flex", justifyContent: "flex-end", position: "absolute", right: 20, top: 0 }}>
                     <IconButton onClick={() => setOpenVideoPlayer(false)}>
                        <Close sx={{ color: "white" }} />
                     </IconButton>
                  </Box>
                  <Box
                     sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh",
                     }}
                  >
                     <VideoPlayer video={urlLink} name={file?.name} audio={false} />
                  </Box>
               </Collapse>
               <Collapse unmountOnExit in={openImage}>
                  <Box sx={{ display: "flex", justifyContent: "flex-end", position: "absolute", right: 20, top: 0 }}>
                     <IconButton onClick={() => setOpenImage(false)}>
                        <Close sx={{ color: "white" }} />
                     </IconButton>
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", p: 10 }}>
                     {/* <ExcelViewer excelLink={urlLink} style={{ height: 500 }} /> */}
                     <Box sx={{ bgcolor: "white" }}>
                        <img
                           srcSet={urlLink}
                           src={urlLink}
                           alt={"imagen"}
                           loading="lazy"
                           style={{ width: "100%", height: "auto" }}
                        />
                     </Box>
                  </Box>
               </Collapse>
            </Box>
         </Modal>
      </Box>
   );
};
