import { Document, Font, Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { getDateFromISODate } from "../../../const/globalConst";

export const BeneficiarySheetTemplate = ({ structure, data1, data2, logo, society }) => {
   const handleValues = (fields = [], valuesFromBd = {}, valuesFromBd2 = {}) => {
      return fields.map((element) => {
         const processedFields = (element.fields ?? [])
            .filter((field) => !field.discard)
            .map((field) => {
               let value = valuesFromBd[field.name] ?? valuesFromBd2[field.name];

               if (field.name.includes(".")) {
                  const [key1, key2] = field.name.split(".");
                  value = valuesFromBd?.[key1]?.[key2] ?? valuesFromBd2?.[key1]?.[key2];
               }

               return { ...field, value };
            });

         const processedSubFields =
            (element.subSection?.subFields ?? []).map((field) => {
               let value = valuesFromBd[field.name] ?? valuesFromBd2[field.name];

               if (field.name.includes(".")) {
                  const [key1, key2] = field.name.split(".");
                  value = valuesFromBd?.[key1]?.[key2] ?? valuesFromBd2?.[key1]?.[key2];
               }

               return { ...field, value };
            }) ?? [];

         return {
            section: element.section?.toUpperCase() || "",
            fields: processedFields,
            subSection:
               element.subSection?.if?.includes(valuesFromBd[element.subSection.subSectionDepends]) &&
               element.subSection.title
                  ? { title: element.subSection.title.toUpperCase(), fields: processedSubFields }
                  : null,
         };
      });
   };

   const values = handleValues(structure, data1, data2);

   return (
      <Document>
         <Page style={styles.body}>
            {logo && <Image style={styles.logo} fixed src={logo} />}
            <Text style={styles.title}>FICHA DE BENEFICIARIO CONTROLADOR</Text>
            <Text style={styles.society}>{society ? society.toUpperCase() : ""}</Text>

            {(values ?? []).map((section, index) => (
               <View key={index}>
                  <Text style={styles.subtitle}>{section.section}</Text>

                  {(section.fields ?? [])
                     .filter((field) => field.type !== "file")
                     .map((field, fieldIndex) => (
                        <FieldRow key={fieldIndex} field={field} data={data1} />
                     ))}

                  {section.subSection && (
                     <>
                        <Text style={styles.subtitle}>{section.subSection.title}</Text>
                        {(section.subSection.fields ?? []).map((field, subFieldIndex) => (
                           <FieldRow key={subFieldIndex} field={field} data={data1} />
                        ))}
                     </>
                  )}
               </View>
            ))}
         </Page>
      </Document>
   );
};

const FieldRow = ({ field, data }) => {
   let value2 = "";
   if (field.depends?.includes(".")) {
      const [key1, key2] = field.depends.split(".");
      value2 = data?.[key1]?.[key2] || "";
   }

   return (
      <View>
         <View style={styles.fieldRow}>
            <View style={styles.fieldTitle}>
               <Text style={styles.textLine}>
                  {value2 && !field.if ? field.titles?.[value2] || field.title : field.title}:
               </Text>
            </View>
            <View style={styles.fieldValue}>
               {field.type === "special" ? (
                  <Actions actions={field.value} />
               ) : (
                  <Text style={styles.textValue}>
                     {field.type === "date" ? getDateFromISODate(field.value) : field.value || ""}
                  </Text>
               )}
            </View>
         </View>
         <Text style={styles.separator} />
      </View>
   );
};

const Actions = ({ actions = [] }) => (
   <View>
      {actions.map((action, index) => (
         <View key={index} style={styles.actionRow}>
            <View style={styles.actions}>
               <Text style={styles.textValue}>{action?.title || ""}</Text>
            </View>
            <View style={styles.actionDetails}>
               <View style={styles.actions}>
                  <Text style={styles.textValue}>Votos</Text>
               </View>
               <View style={styles.actions}>
                  <Text style={styles.textValue}>{action?.nominalValue || ""}</Text>
               </View>
               <View style={styles.actions}>
                  <Text style={styles.textValue}>Total de acciones</Text>
               </View>
               <View style={styles.actions}>
                  <Text style={styles.textValue}>{action?.totalActions || ""}</Text>
               </View>
            </View>
         </View>
      ))}
   </View>
);

Font.register({
   family: "open-sans",
   src: "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/fonts/OpenSans-Regular.ttf",
});

Font.register({
   family: "open-sans-bold",
   src: "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/fonts/OpenSans-Bold.ttf",
});

const styles = StyleSheet.create({
   body: {
      paddingTop: 80,
      paddingBottom: 80,
      paddingHorizontal: 65,
      fontFamily: "open-sans",
   },
   logo: {
      height: 40,
      width: "auto",
      position: "absolute",
      top: 25,
      left: 55,
   },
   society: {
      fontSize: 11,
      textAlign: "center",
   },
   title: {
      fontSize: 12,
      fontFamily: "open-sans-bold",
      textAlign: "center",
   },
   subtitle: {
      fontSize: 11,
      fontFamily: "open-sans-bold",
      textAlign: "center",
      color: "#73808c",
      paddingVertical: 15,
   },
   fieldRow: {
      flexDirection: "row",
   },
   fieldTitle: {
      flex: 2,
      justifyContent: "center",
   },
   fieldValue: {
      flex: 4,
      justifyContent: "center",
      borderLeft: "1px solid #000000",
   },
   textLine: {
      fontSize: 11,
      fontFamily: "open-sans-bold",
      padding: 5,
   },
   textValue: {
      fontSize: 11,
      marginHorizontal: 5,
   },
   separator: {
      fontSize: 10,
      textAlign: "center",
      marginTop: 2,
      borderBottom: "0.5px",
      marginBottom: 5,
   },
   actions: {
      flex: 1,
      borderRight: "1px solid #000000",
      alignItems: "center",
      justifyContent: "center",
      padding: 4,
   },
   actionRow: {
      flexDirection: "row",
   },
   actionDetails: {
      flex: 4,
      flexDirection: "row",
   },
});
