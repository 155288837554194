import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import { useContext } from "react";
import { CorporateDataContext } from "../../../context/governanceContext/corporateDataContext";
import { SeriesManagment } from "./SocietarySubComponents/SeriesManagment";
import { ClassificationManagment } from "./SocietarySubComponents/ClassificationManagment";
import { LoadingButton } from "../../LoadingButton";

const SeriesRegistry = () => {
   const { updateClassSeriesData, isLoading } = useContext(CorporateDataContext);
   return (
      <>
         <Stack direction={"row"} spacing={2} sx={{ my: 2 }}>
            <Box
               flex={1.5}
               sx={{ boxShadow: 1, bgcolor: "white", borderBottomLeftRadius: 7, borderBottomRightRadius: 7 }}
            >
               <Box
                  display={"flex"}
                  width={"100%"}
                  bgcolor={"#162c44"}
                  color={"white"}
                  alignItems={"center"}
                  px={2}
                  py={1}
                  sx={{ borderTopRightRadius: 7, borderTopLeftRadius: 7 }}
               >
                  <Typography justifySelf={"center"} fontWeight={600} flex={1} textAlign={"center"}>
                     Clasificación
                  </Typography>
               </Box>
               <Box minHeight={"400px"} display={"flex"}>
                  <ClassificationManagment />
               </Box>
            </Box>
            <Box
               flex={1}
               sx={{ boxShadow: 1, bgcolor: "white", borderBottomLeftRadius: 7, borderBottomRightRadius: 7 }}
            >
               <Box
                  display={"flex"}
                  width={"100%"}
                  bgcolor={"#162c44"}
                  color={"white"}
                  alignItems={"center"}
                  px={2}
                  py={1}
                  sx={{ borderTopRightRadius: 7, borderTopLeftRadius: 7 }}
               >
                  <Typography justifySelf={"center"} fontWeight={600} flex={1} textAlign={"center"}>
                     Series
                  </Typography>
               </Box>
               <Box minHeight={"400px"} display={"flex"}>
                  <SeriesManagment />
               </Box>
            </Box>
         </Stack>
         <Box display={"flex"} justifyContent={"flex-end"}>
            <LoadingButton label="Guardar" isLoading={isLoading} onClick={() => updateClassSeriesData()} />
         </Box>
      </>
   );
};

export default SeriesRegistry;
