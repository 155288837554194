import { createContext, useEffect, useMemo, useState } from "react";
import { IAdditionalVotes, IAffairs, IMembersWithCharges, IResolutions, ISession } from "../../types/governance.types";
import { createExternalSessionToken, getSessionByIdExternal } from "../../lib/gobCorpBEClient";
import { useParams } from "react-router-dom";
import { io } from "socket.io-client";
import { Companies } from "../../types/BaseTypes";
import { getUrlForDocumentsListGCPrefix } from "../../lib/usersBEClient";

interface ISessionContext {
   session: ISession;
   setSession: Function;
   externalSessionToken: string;
   affairsArray: IAffairs[];
   isLoading: boolean;
   setIsLoading: Function;
   isVerified: boolean;
   setIsVerified: Function;
   socket: any;
   userId: string;
   isRejected: boolean;
   setIsRejected: Function;
   isWaitingToVerify: boolean;
   setIsWaitingToVerify: Function;
   colors: { primary: string; secondary: string };
   externalMemberUsers: { user: string; email: string; name?: string; status: string; attended: boolean }[];
   fileArray: any[];
   setFileArray: Function;
   initialValues: any;
   membersWithCharge: IMembersWithCharges[];
   governingBody: any;
   setGoverningBody: Function;
   valuesFromBill: any;
   setValuesFromBill: Function;
   quorum: { attendance: number; vote: number };
   setQuorum: Function;
   usersOnline: any[];
   setUsersOnline: Function;
   groupCompaniesInSession: Companies[];
   additionalVotes: IAdditionalVotes[];
   affairVotations: any[];
   userOnlineSigns: { firstName: string; name?: string; lastName: string; _id: string }[];
   attendedPercentage: number;
   setAttendedPercentage: Function;
   completedSession: boolean;
   canceledSession: boolean;
   openModalToSign: boolean;
   setOpenModalToSign: Function;
   openInputNameModal: boolean;
   setOpenInputNameModal: Function;
   documentUrl: string;
   setDocumentUrl: Function;
   sessionResolutions: IResolutions[];
   signArray: string[];
   setSignArray: Function;
   fetchSignArrayList: Function;
}

export const ExternalGovernanceSessionContext = createContext<ISessionContext>({
   session: null,
   setSession: () => {},
   externalSessionToken: null,
   affairsArray: [],
   isLoading: true,
   setIsLoading: () => {},
   isVerified: false,
   setIsVerified: () => {},
   socket: null,
   userId: null,
   isRejected: false,
   setIsRejected: () => {},
   isWaitingToVerify: false,
   setIsWaitingToVerify: () => {},
   colors: null,
   externalMemberUsers: [],
   fileArray: [],
   setFileArray: () => {},
   initialValues: null,
   membersWithCharge: null,
   governingBody: null,
   setGoverningBody: () => {},
   valuesFromBill: null,
   setValuesFromBill: () => {},
   quorum: null,
   setQuorum: () => {},
   usersOnline: [],
   setUsersOnline: () => {},
   groupCompaniesInSession: [],
   additionalVotes: [],
   affairVotations: [],
   userOnlineSigns: [],
   attendedPercentage: 0,
   setAttendedPercentage: () => {},
   completedSession: false,
   canceledSession: false,
   openModalToSign: false,
   setOpenModalToSign: () => {},
   openInputNameModal: false,
   setOpenInputNameModal: () => {},
   documentUrl: null,
   setDocumentUrl: () => {},
   sessionResolutions: null,
   signArray: [],
   setSignArray: () => {},
   fetchSignArrayList: () => {},
});

export const ExternalGovernanceSessionProvider = ({ children }) => {
   const [session, setSession] = useState<ISession>(null);
   const [externalSessionToken, setExternalSessionToken] = useState(null);
   const [affairsArray, setaffairsArray] = useState([]);
   const { sessionId, userId } = useParams();
   const [socket, setSocket] = useState(null);
   const [isLoading, setIsLoading] = useState(true);
   const [isVerified, setIsVerified] = useState(false);
   const [isRejected, setIsRejected] = useState(false);
   const [isWaitingToVerify, setIsWaitingToVerify] = useState(false);
   const [colors, setColors] = useState(null);
   const [fileArray, setFileArray] = useState([]);
   const [initialValues, setInitialValues] = useState(null);
   const [membersWithCharge, setMembersWithCharge] = useState([]);
   const [governingBody, setGoverningBody] = useState(null);
   const [valuesFromBill, setValuesFromBill] = useState(null);
   const [quorum, setQuorum] = useState(null);
   const [usersOnline, setUsersOnline] = useState([]);
   const [completedSession, setCompletedSession] = useState(false);
   const [canceledSession, setCanceledSession] = useState(false);
   const [attendedPercentage, setAttendedPercentage] = useState(0);
   const [groupCompaniesInSession, setGroupCompaniesInSession] = useState<Companies[]>([]);
   const [openModalToSign, setOpenModalToSign] = useState(false);
   const [openInputNameModal, setOpenInputNameModal] = useState(false);
   const [documentUrl, setDocumentUrl] = useState(null);
   const [signArray, setSignArray] = useState([]);
   const [sessionResolutions, setSessionResolutions] = useState([]);
   const [additionalVotes, setAdditionalVotes] = useState([]);
   const [affairVotations, setAffairVotations] = useState([]);

   const fetchSignArrayList = async () => {
      if (!session) return [];
      const s3SignArray = await getUrlForDocumentsListGCPrefix(
         "files-lecosy",
         `gc/companies/${session?.company}/governing-body/sessions/${session._id}`,
         "sign",
         externalSessionToken
      );
      if (!s3SignArray.Contents) return [];
      const signArray =
         s3SignArray.Contents?.reduce((array, key) => {
            const keySplit = key.Key.split("sign-");
            if (keySplit.length < 2) return array;
            const idSplit = key.Key.split("sign-")[1]; //Separates 'sign-' from key
            return [...array, idSplit.substring(0, idSplit.length - 4)]; //cuts userid from png extension
         }, []) || [];
      return signArray;
   };

   useEffect(() => {
      const fetchInitialSignList = async () => {
         const list = await fetchSignArrayList();
         setSignArray(list);
      };
      if (!session && !externalSessionToken) return;
      fetchInitialSignList();
   }, [session, externalSessionToken]);

   const base_url =
      window.location.hostname === "test.web.lecosy.com.mx" || window.location.hostname === "www.test.web.lecosy.com.mx"
         ? "https://test.server.lecosy.com.mx"
         : process.env.NODE_ENV === "production"
         ? "https://server.lecosy.com.mx"
         : "http://localhost:8003";

   useEffect(() => {
      try {
         const socketServer = io(base_url, {
            path:
               process.env.NODE_ENV === "production" ||
               window.location.hostname === "test.web.lecosy.com.mx" ||
               window.location.hostname === "www.test.web.lecosy.com.mx"
                  ? "/gc/socket.io"
                  : "/socket.io",
            port:
               process.env.NODE_ENV === "production" ||
               window.location.hostname === "test.web.lecosy.com.mx" ||
               window.location.hostname === "www.test.web.lecosy.com.mx"
                  ? 80
                  : 8003,
            withCredentials: true,
         });
         setSocket(socketServer);
         return () => {
            socketServer.disconnect();
         };
      } catch (e) {
         setSocket(null);
      }
   }, [base_url]);

   useEffect(() => {
      if (!session) return;
      socket.emit("join", { sessionId: session._id, userId: userId });

      const handleJoinExternal = async (valuesFromSocket) => {
         if (!valuesFromSocket.access) {
            setIsRejected(true);
            setIsWaitingToVerify(false);
         } else {
            setIsRejected(false);
            setIsVerified(true);
         }
      };

      const handleReceiveValuesExternal = (values) => {
         setaffairsArray(values.affairsArray);
         setColors(values.colors);
         setFileArray(values.fileArray);
         setInitialValues(values.initialValues);
         setMembersWithCharge(values.membersWithCharge);
         setGoverningBody(values.governingBody);
         setValuesFromBill(values.valuesFromBill);
         setGroupCompaniesInSession(values.groupCompaniesInSession);
         setSessionResolutions(values.sessionResolutions);
         setAdditionalVotes(values.additionalVotes);
         setAffairVotations(values.affairVotations);
         setQuorum(values.quorum);
         setIsWaitingToVerify(false);
      };

      const socketModalToSign = (valuesFromSocket) => {
         if (!valuesFromSocket.signArray.includes(userId)) setOpenModalToSign(true);
      };

      const handleEndSession = () => {
         setCompletedSession(true);
      };

      const handleCancelSession = () => {
         setCanceledSession(true);
      };

      const updateExternalNames = (values) => {
         session.externs.find((u) => u.user === values.userId).name = values.firstName;
      };

      const handleReceiveResolutions = (valuesFromSocket) => {
         setSessionResolutions(valuesFromSocket?.resolutions || []);
         setAdditionalVotes(valuesFromSocket?.additionalVotes || []);
         setAffairVotations(valuesFromSocket?.affairVotes || []);
      };

      socket.on("send-response-join-external", handleJoinExternal);
      socket.on("receive-values-external", handleReceiveValuesExternal);
      socket.on("require-sign", socketModalToSign);
      socket.on("complete-killer", handleEndSession);
      socket.on("killer", handleCancelSession);
      socket.on("update-external-users", updateExternalNames);
      socket.on("receive-resolutions", handleReceiveResolutions);

      return () => {
         socket.off("send-response-join-external", handleJoinExternal);
         socket.off("receive-values-external", handleReceiveValuesExternal);
         socket.off("require-sign", socketModalToSign);
         socket.off("complete-killer", handleEndSession);
         socket.off("killer", handleCancelSession);
         socket.off("update-external-users", updateExternalNames);
         socket.off("receive-resolutions", handleReceiveResolutions);
      };
   }, [session, socket]);

   const externalMemberUsers = useMemo(() => {
      if (session === null) return;
      const membersArray = session.externs.map((member: any) => member);
      return membersArray;
   }, [session]);

   const userOnlineSigns = useMemo(() => {
      let userMap = {};
      if (!membersWithCharge || usersOnline.length === 0) return [];
      membersWithCharge.concat(externalMemberUsers)?.forEach((member) => {
         userMap[member._id || member.user] = {
            firstName: member?.firstName || member.name,
            lastName: member?.lastName || "",
            _id: member?._id || member.user,
         };
      });
      const usersOnlineSignsArray = usersOnline.map((userOnline) => userMap[userOnline]);
      return [...new Set(usersOnlineSignsArray)];
   }, [usersOnline, membersWithCharge, externalMemberUsers]);

   useEffect(() => {
      if (isVerified) socket.emit("already-verified-external", { sessionId: sessionId, userId: userId });
   }, [isVerified, socket]);

   useEffect(() => {
      const fetchSession = async () => {
         setIsLoading(true);
         const response = await getSessionByIdExternal(sessionId, userId);
         if (!response) return setIsLoading(false);
         setSession(response.session);
         setExternalSessionToken(response.accessToken);
         // dispatch({ type: "affairsInitialState", affairVotations: response.session.affairVotations });
         setIsLoading(false);
      };
      fetchSession();
   }, []);

   useEffect(() => {
      const verifyToken = async () => {
         if (!completedSession && !canceledSession) {
            const tokenResponse = await createExternalSessionToken(sessionId, userId);
            setExternalSessionToken(tokenResponse?.accessToken);
         }
      };
      verifyToken();
      const intervalId = setInterval(verifyToken, 1000 * 10 * 5);
      return () => clearInterval(intervalId);
   }, [isVerified]);

   return (
      <ExternalGovernanceSessionContext.Provider
         value={{
            session,
            setSession,
            externalSessionToken,
            affairsArray,
            isLoading,
            setIsLoading,
            isVerified,
            setIsVerified,
            socket,
            userId,
            isRejected,
            setIsRejected,
            isWaitingToVerify,
            setIsWaitingToVerify,
            colors,
            externalMemberUsers,
            fileArray,
            setFileArray,
            initialValues,
            membersWithCharge,
            governingBody,
            valuesFromBill,
            setValuesFromBill,
            groupCompaniesInSession,
            quorum,
            setQuorum,
            usersOnline,
            setUsersOnline,
            userOnlineSigns,
            attendedPercentage,
            setAttendedPercentage,
            setGoverningBody,
            completedSession,
            canceledSession,
            openModalToSign,
            setOpenModalToSign,
            openInputNameModal,
            setOpenInputNameModal,
            documentUrl,
            setDocumentUrl,
            sessionResolutions,
            signArray,
            setSignArray,
            fetchSignArrayList,
            additionalVotes,
            affairVotations,
         }}
      >
         {children}
      </ExternalGovernanceSessionContext.Provider>
   );
};
