import { Box, IconButton, Stack, Tab, Tabs, Typography, Button } from "@mui/material";
import React, { useContext, useMemo, useState } from "react";
import { HeadCell, TableComponent } from "../../TableComponent";
import { CustomModalComponent } from "../../CustomModalComponent";
import EditDocumentPeriodModal from "./EditDocumentPeriodModal";
import { formatFileSize } from "../../../const/globalConst";
import { EditNote } from "@mui/icons-material";
import { BeneficiaryControllerContext } from "../../../context/beneficiaryContext/beneficiaryContollerContext";
import { AddCircle } from "@mui/icons-material";
import AddBeneficiaryDocument from "./AddBeneficiaryDocument";

const headers: HeadCell[] = [
   { field: "fileName", headerName: "Nombre del documento", type: "string", align: "left" },
   { field: "fileType", headerName: "Tipo de archivo", type: "string" },
   { field: "formattedSize", headerName: "Tamaño", type: "string" },
   { field: "period", headerName: "Periodo establecido", type: "string" },
   { field: "config", headerName: "Personalizar", type: "string" },
];

const getPeriodName = (period: string) => {
   switch (period) {
      case "none":
         return "Ninguno";
      case "daily":
         return "Diario";
      case "weekly":
         return "Semanal";
      case "monthly":
         return "Mensual";
      case "annually":
         return "Anual";
      default:
         break;
   }
};

export interface BeneficiaryDocument {
   fileName: string;
   fileType: string;
   maxFileSize: Number;
   requestDate: Date;
   periodType: "none" | "daily" | "weekly" | "monthly" | "annually";
   periodFrequency?: Number;
   endOn?: Date;
   userType?: "any" | "shareholder" | "effective" | "beneficiary";
}

const BeneficiaryConfiguration = () => {
   const { configurationData, refetch } = useContext(BeneficiaryControllerContext);
   const [openModal, setOpenModal] = useState(false);
   const [selectedDocument, setSelectedDocument] = useState<BeneficiaryDocument>(null);
   const [openModalAdd, setOpenModalAdd] = useState(false);

   const rows: BeneficiaryDocument[] = useMemo(() => {
      if (!configurationData?.files) return [];
      return configurationData.files.map((doc) => ({
         ...doc,
         maxFileSize: doc.maxFileSize / (1024 * 1024),
         formattedSize: formatFileSize(doc.maxFileSize),
         period: getPeriodName(doc.periodType),
         config: (
            <IconButton onClick={() => setOpenModal(!openModal)} sx={{ p: 0 }}>
               <EditNote />
            </IconButton>
         ),
      }));
   }, [configurationData]);

   return (
      <Box sx={{ bgcolor: "white", borderRadius: 2, my: 2, boxShadow: 1, p: 2 }}>
         <Tabs value={0} sx={{ flex: 1, borderBottom: 1, borderColor: "#CACDD8", display: "flex" }}>
            <Tab label={"Solicitud de documentación"} sx={{ fontWeight: 600, pointerEvents: "none" }} />

            <Box sx={{ flexGrow: 1 }} />

            <Box sx={{ mr: 3 }}>
               <Button
                  variant="contained"
                  onClick={() => {}}
                  sx={{ display: "flex", alignItems: "center", columnGap: 1, fontSize: "14px" }}
                  onClickCapture={() => setOpenModalAdd(true)}
               >
                  <AddCircle />
                  Añadir nuevo
               </Button>
            </Box>
         </Tabs>

         <Stack sx={{ pt: 2 }}>
            <Typography sx={{ fontWeight: 600, px: 3 }}>Lista de documentos</Typography>
            <TableComponent
               headers={headers}
               rows={rows}
               dense
               defaultColumnToOrder=""
               defaultOrder="desc"
               defaultRowsPerPage={10}
               emptyDataText="No se encontro la configuración requerida"
               loader={configurationData === null}
               rowsPerPageOptions={[5, 10, 20, 50]}
               onClick={(_e, row) => setSelectedDocument(row)}
               disableBorders
            />
         </Stack>

         <CustomModalComponent title="Personalizar solicitud de documento" setOpen={setOpenModal} open={openModal}>
            <EditDocumentPeriodModal document={selectedDocument} setState={setOpenModal} refreshTable={refetch} />
         </CustomModalComponent>

         <CustomModalComponent title="Agregar nuevo documento" setOpen={setOpenModalAdd} open={openModalAdd}>
            <AddBeneficiaryDocument document={selectedDocument} setState={setOpenModalAdd} refreshTable={refetch} />
         </CustomModalComponent>
      </Box>
   );
};

export default BeneficiaryConfiguration;
