import { createContext, useCallback, useContext, useEffect, useState } from "react";
import io from "socket.io-client";
import {
   createConversation,
   createMessage,
   getConversationsByUserId,
   getMessagesByConversationId,
   updateUserReadMessageById,
   getNumberOfUnseenMessages,
   getMessagesByContent,
   getMessagesUntilSearchedMessage,
   getConversationsByCompanyId,
} from "../../lib/gobCorpBEClient";
import { VerifyChatFolderByName, complaintUploadPdfEvidence, getFilteredUsers } from "../../lib/usersBEClient";
import { IUserContext } from "../../types/BaseTypes";
import { IConversation, IMessage } from "../../types/governance.types";
import { GovernanceContext } from "./governanceContext";
import { UserContext } from "../userContext";
import { useNavigate } from "react-router-dom";

const base_url =
   window.location.hostname === "test.web.lecosy.com.mx" || window.location.hostname === "www.test.web.lecosy.com.mx"
      ? "https://test.server.lecosy.com.mx"
      : process.env.NODE_ENV === "production"
      ? "https://server.lecosy.com.mx"
      : "http://localhost:8003";

interface IChatContext {
   userChats;
   setUserChats: Function;
   isUserChatsLoading: boolean;
   setIsUserChatsLoading: Function;
   groups;
   setGroups: Function;
   conversations: IConversation[];
   setConversations: Function;
   socket;
   currentChat: IConversation;
   setCurrentChat: Function;
   contacts;
   setContacts: Function;
   createChat: (userChat, userLogged, allContacts, allUsers, userChatId, companySelected) => void;
   messages: IMessage[];
   setMessages: Function;
   isLoadingMessages;
   setIsLoadingMessages: Function;
   sendTextMessage: (
      textMessage,
      authorId,
      currentChat,
      setTextMessage,
      socketMsg,
      conversations,
      groups,
      fileUploaded?,
      createFile?,
      chatFolder?,
      chatFolderReceiver?,
      fileSelected?,
      copyFileToFolder?,
      companySelcted?,
      resourceId?
   ) => void;
   newMessage: IMessage;
   setNewMessage: Function;
   onlineUsers;
   typing;
   setTyping: Function;
   page;
   setPage: Function;
   totalPages;
   setTotalPages: Function;
   notification;
   setNotification: Function;
   allUsers;
   searchInput: string;
   setSearchInput: Function;
   unseenMessages: Map<string, number>;
   setMessagesUnseen: Function;
   selectedMessage: IMessage;
   setSelectedMessage: Function;
   foundMessages: IMessage[];
   setFoundMessages: Function;
   foundConversations: IConversation[];
   setFoundConversations: Function;
   foundGroups: IConversation[];
   setFoundGroups: Function;
   fileUploaded;
   setFileUploaded: Function;
   fileSelected;
   setFileSelected: Function;
   createChatNew: Function;
   members: boolean;
   fetchConversations: Function;
   isUploadingFile: boolean;
   setIsUploadingFile: Function;
}

export const ChatContext = createContext<IChatContext>({
   userChats: null,
   setUserChats: () => {},
   isUserChatsLoading: true,
   setIsUserChatsLoading: () => {},
   groups: [],
   setGroups: () => {},
   conversations: [],
   setConversations: () => {},
   socket: null,
   currentChat: null,
   setCurrentChat: () => {},
   contacts: null,
   setContacts: () => {},
   createChat: () => {},
   messages: [],
   setMessages: () => {},
   isLoadingMessages: true,
   setIsLoadingMessages: () => {},
   sendTextMessage: () => {},
   newMessage: null,
   setNewMessage: () => {},
   onlineUsers: [],
   typing: false,
   setTyping: () => {},
   page: 1,
   setPage: () => {},
   totalPages: null,
   setTotalPages: () => {},
   notification: null,
   setNotification: () => {},
   allUsers: null,
   searchInput: null,
   setSearchInput: () => {},
   unseenMessages: null,
   setMessagesUnseen: () => {},
   selectedMessage: null,
   setSelectedMessage: () => {},
   foundMessages: null,
   setFoundMessages: () => {},
   foundConversations: null,
   setFoundConversations: () => {},
   foundGroups: null,
   setFoundGroups: () => {},
   fileUploaded: null,
   setFileUploaded: () => {},
   fileSelected: null,
   setFileSelected: () => {},
   createChatNew: () => {},
   members: false,
   fetchConversations: () => {},
   isUploadingFile: false,
   setIsUploadingFile: () => {},
});

export const ChatProvider = ({ children }) => {
   const [userChats, setUserChats] = useState(null);
   const [isUserChatsLoading, setIsUserChatsLoading] = useState(true);
   const [groups, setGroups] = useState([]);
   const [conversations, setConversations] = useState<IConversation[]>([]);
   const [socket, setSocket] = useState(null);
   const [currentChat, setCurrentChat] = useState<IConversation>(null);
   const [contacts, setContacts] = useState(null);
   const [messages, setMessages] = useState<IMessage[]>([]);
   const [isLoadingMessages, setIsLoadingMessages] = useState(true);
   const [newMessage, setNewMessage] = useState(null);
   const [onlineUsers, setOnlineUsers] = useState([]);
   const [typing, setTyping] = useState(false);
   const [page, setPage] = useState(1);
   const [totalPages, setTotalPages] = useState(null);
   const [notification, setNotification] = useState(null);
   const [allUsers, setAllUsers] = useState(null);
   const [searchInput, setSearchInput] = useState("");
   const [unseenMessages, setMessagesUnseen] = useState<Map<string, number>>(null);
   const [selectedMessage, setSelectedMessage] = useState<IMessage>(null);
   const [foundMessages, setFoundMessages] = useState<IMessage[]>(null);
   const [foundConversations, setFoundConversations] = useState<IConversation[]>(null);
   const [foundGroups, setFoundGroups] = useState<IConversation[]>(null);
   const [fileUploaded, setFileUploaded] = useState(null);
   const [fileSelected, setFileSelected] = useState(null);
   const [isLoadingSocket, setIsLoadingSocket] = useState(true);
   const { companySelected, gobernanceBody } = useContext(GovernanceContext);
   const { mutate: verifyChatFolder } = VerifyChatFolderByName();
   const { user } = useContext(UserContext);
   const [newConversation, setNewConversation] = useState(null);
   const [members, setMembers] = useState(false);
   const [isUploadingFile, setIsUploadingFile] = useState(false);
   const navigate = useNavigate();

   useEffect(() => {
      const socket = io(base_url, {
         path:
            process.env.NODE_ENV === "production" ||
            window.location.hostname === "test.web.lecosy.com.mx" ||
            window.location.hostname === "www.test.web.lecosy.com.mx"
               ? "/gc/socket.io"
               : "/socket.io",
         port:
            process.env.NODE_ENV === "production" ||
            window.location.hostname === "test.web.lecosy.com.mx" ||
            window.location.hostname === "www.test.web.lecosy.com.mx"
               ? 80
               : 8003,
      });
      setSocket(socket);
      setIsLoadingSocket(false);
   }, []);

   useEffect(() => {
      if (!user || !companySelected || isLoadingSocket) return;
      fetchConversations();
      socket.emit("setup", user);
      socket.on("onlineUsers", (users) => {
         setOnlineUsers(users);
      });
      return () => {
         socket.off("setup", user);
         socket.off("onlineUsers");
      };
   }, [user, companySelected, isLoadingSocket]);

   useEffect(() => {
      if (!socket) return;
      setTyping(false);
      socket.on("typing", (chat) => {
         if (chat !== currentChat._id) return;
         setTyping(true);
      });
      socket.on("stop typing", () => setTyping(false));
      return () => {
         socket.off("typing");
         socket.off("stop typing");
      };
   }, [socket, currentChat]);

   useEffect(() => {
      if (messages?.length > 0 && selectedMessage !== null) {
         if (messages.some((message) => message._id === selectedMessage._id)) {
            const message = document.getElementById(selectedMessage._id);
            message?.scrollIntoView();
         } else {
            fetchMessages(selectedMessage.conversation, true);
         }
      }
   }, [messages, selectedMessage]);

   useEffect(() => {
      if (gobernanceBody.length === 0) return;
      setIsUserChatsLoading(true);

      let allUsers = [];
      for (const iterator of gobernanceBody) {
         if (!iterator.users) continue;
         for (const userGov of iterator.users) {
            const sameUser = allUsers.some((e) => e === userGov);
            if (!sameUser && userGov !== user.id) allUsers.push(userGov);
         }
      }
      const fetchUsers = async () => {
         try {
            const allUsersData = await getFilteredUsers(allUsers.map((user) => user.user));
            setAllUsers(allUsersData);
            if (userChats && userChats.length > 0) {
               const singleUserChats = userChats.filter((chat) => chat.type === "CONVERSATION");
               const participantsIds = singleUserChats.reduce((participants, chat) => {
                  const participantIdsInChat = chat.participants.map((participant) => participant._id);
                  return participants.concat(participantIdsInChat.filter((participant) => participant !== user.id));
               }, []);

               const filteredUsers = allUsersData.filter(
                  (userData) => !participantsIds.includes(userData._id) && userData._id !== user.id
               );
               setContacts(filteredUsers);
               setIsUserChatsLoading(false);
            } else {
               const filteredUsers = allUsersData.filter((userC) => userC._id !== user.id);
               setContacts(filteredUsers);
               setIsUserChatsLoading(false);
            }
         } catch (error) {
            console.log(error);
         }
      };
      fetchUsers();
   }, [userChats, gobernanceBody]);

   useEffect(() => {
      if (!socket) return;

      const handleMessageReceived = (message) => {
         if (currentChat?._id !== message.conversation) return;
         const updatedConversations = conversations.map((chat) => {
            if (chat._id === currentChat?._id) {
               return { ...chat, lastMessage: message };
            }
            return chat;
         });
         const updatedGroups = groups.map((chat) => {
            if (chat._id === currentChat?._id) {
               return { ...chat, lastMessage: message };
            }
            return chat;
         });
         setGroups(updatedGroups);
         setMessages((prev) => [message, ...prev]);
         updateUserReadMessageById(currentChat._id, user.id);
      };

      const handleNotificationReceived = async (notification) => {
         await updateUnseenMessagesNumber();
         if (currentChat?._id === notification.conversation) return;
         const fetchedNotification = await notification;
         setNotification(fetchedNotification);
      };

      socket.on("messageReceived", handleMessageReceived);
      socket.on("getNotification", handleNotificationReceived);

      return () => {
         socket.off("messageReceived", handleMessageReceived);
         socket.off("getNotification", handleNotificationReceived);
      };
   }, [currentChat, conversations, socket]);

   useEffect(() => {
      if (!currentChat) return;
      updateUnseenMessagesNumber();
      setPage(1);
      setMessages([]);
      setIsLoadingMessages(true);
   }, [currentChat]);

   useEffect(() => {
      const searchMessage = async () => {
         if (searchInput.length === 0) {
            setFoundMessages(null);
         } else {
            const conversationIds = conversations.map((conversation) => conversation._id);
            const fetchedMessages = await getMessagesByContent(conversationIds, searchInput);
            setFoundMessages(fetchedMessages);
            const filteredConversations = conversations.filter((conversation) =>
               conversation.title.toLowerCase().includes(searchInput.toLowerCase())
            );
            const filteredGroups = groups.filter((group) =>
               group.title.toLowerCase().includes(searchInput.toLowerCase())
            );
            setFoundConversations(filteredConversations);
            setFoundGroups(filteredGroups);
         }
      };

      searchMessage();
   }, [searchInput]);

   useEffect(() => {
      if (!currentChat) return;
      updateUnseenMessagesNumber();
      fetchMessages(currentChat._id, false);
   }, [page, currentChat]);

   useEffect(() => {
      if (messages.length > 0 && currentChat !== null) {
         updateUserReadMessageById(currentChat._id, user.id);
      }
   }, [currentChat, messages]);

   const createChatNew = async (userChat, userLogged, allContacts, allUsers, userChatId, companySelected) => {
      allUsers = allContacts.filter((user) => user._id !== userLogged._id);
      if (userChatId === undefined || userLogged.id === undefined) return;
      const conversation = {
         title: userChat.firstName + " " + userChat.lastName,
         participants: [userChatId, userLogged.id],
         type: "CONVERSATION",
         company: companySelected,
      };
      const conversationRes = await createConversation(conversation);

      conversation.participants = [userChat, userLogged];
      conversationRes.title = conversation.title;
      const conversationNew = {
         ...conversation,
         _id: conversationRes._id,
         createdAt: conversationRes.createdAt,
         lastMessage: conversationRes.lastMessage,
         updatedAt: conversationRes.updatedAt,
      };
      setNewConversation(conversationNew);

      const updatedContacts = allContacts.filter((user) => user._id !== userChatId);
      setContacts(updatedContacts);
      verifyChatFolder({
         name: userChat.firstName + " " + userChat.lastName,
         userId: userLogged.id,
      });
      verifyChatFolder({
         name: userLogged.userName + " " + userLogged.userLastName,
         userId: userChatId,
      });
      setCurrentChat(conversationNew);
      setMembers(true);
      return conversationRes.id;
   };

   const createChat = useCallback(async (userChat, userLogged, allContacts, allUsers, userChatId, companySelected) => {
      allUsers = allContacts.filter((user) => user._id !== userLogged._id);
      if (userChatId === undefined || userLogged.id === undefined) return;
      const conversation = {
         title: userChat.firstName + " " + userChat.lastName,
         participants: [userChatId, userLogged.id],
         type: "CONVERSATION",
         company: companySelected,
      };
      const conversationRes = await createConversation(conversation);
      conversationRes.participants = conversation.participants;
      setNewConversation(conversationRes);
      setConversations((prev) => [...prev, conversationRes]);
      const updatedContacts = allContacts.filter((user) => user._id !== userChatId);
      setContacts(updatedContacts);
      verifyChatFolder({
         name: userChat.firstName + " " + userChat.lastName,
         userId: userLogged.id,
      });
      verifyChatFolder({
         name: userLogged.userName + " " + userLogged.userLastName,
         userId: userChatId,
      });
      return conversationRes;
   }, []);

   const sendTextMessage = useCallback(
      async (
         textMessage,
         authorId,
         currentChat,
         setTextMessage,
         socketMsg,
         conversations,
         groups,
         fileUploadedTemp,
         createFile,
         chatFolder,
         chatFolderReceiver,
         fileSelectedTemp,
         copyFileToFolder,
         companyId,
         resourceId
      ) => {
         if (!currentChat && !isUploadingFile) return;
         let message = {
            conversation: currentChat._id,
            authorId: authorId,
            content: textMessage,
            usersRead: [authorId],
         };
         const fileUploaded = fileUploadedTemp;
         const fileSelected = fileSelectedTemp;
         let messageFileIdSender = "";
         try {
            if (fileUploaded) {
               const fileData: { createFile: { _id: string } } = await new Promise((resolve, reject) => {
                  createFile(
                     {
                        name: fileUploaded.name,
                        owner: authorId,
                        metadata: [],
                        shared: [],
                        size: fileUploaded.size,
                        type: fileUploaded.type,
                        folder: chatFolder._id,
                        fileDirection: `gc/companies/${companyId}/users/${authorId}/${chatFolder._id}`,
                     },
                     {
                        onError: (error: any) => {
                           console.error(error);
                           reject(error);
                        },
                        onSuccess: async (data) => {
                           await complaintUploadPdfEvidence(data.urlToUpload, fileUploaded);
                           resolve(data);
                           messageFileIdSender = data.createFile._id;
                        },
                     }
                  );
               });
               createFile(
                  {
                     name: fileUploaded.name,
                     owner: chatFolderReceiver.receiver,
                     metadata: [],
                     shared: [],
                     size: fileUploaded.size,
                     type: fileUploaded.type,
                     folder: chatFolderReceiver.chatFolder._id,
                     fileDirection: `gc/companies/${companyId}/users/${chatFolderReceiver.receiver}/${chatFolderReceiver.chatFolder._id}`,
                     idFileFromMessageReceive: fileData.createFile._id,
                  },
                  {
                     onError: (error: any) => {
                        console.error(error);
                     },
                     onSuccess: async (data) => {
                        await complaintUploadPdfEvidence(data.urlToUpload, fileUploaded);
                     },
                  }
               );

               const messageWithFile = {
                  ...message,
                  file: fileData.createFile._id,
               };
               message = messageWithFile;
            }
            if (fileSelected) {
               const fileData: { _id: string } = await new Promise((resolve, reject) => {
                  copyFileToFolder(
                     {
                        folderId: chatFolder._id,
                        fileId: fileSelected._id,
                        resourceId: resourceId,
                        companyId: companyId,
                        userId: chatFolder.owner,
                     },
                     {
                        onError: (error: any) => {
                           reject(error);
                        },
                        onSuccess: async (data) => {
                           resolve(data);
                           // messageFileIdSender = data.copyFileToFolder._id;
                        },
                     }
                  );
               });
               copyFileToFolder(
                  {
                     folderId: chatFolderReceiver.chatFolder._id,
                     fileId: fileSelected._id,
                     userId: chatFolderReceiver.receiver,
                     resourceId: resourceId,
                     companyId: companyId,
                  },
                  {
                     onError: (error: any) => {
                        console.error(error);
                     },
                     onSuccess: async (data) => {
                        // await complaintUploadPdfEvidence(data.urlToUpload, fileSelected);
                     },
                  }
               );
               const messageWithFile = {
                  ...message,
                  file: fileData._id,
               };
               message = messageWithFile;
            }

            const res = await createMessage(message);
            res.chat = currentChat;
            const updatedConversations = conversations.map((chat) => {
               if (chat._id === currentChat._id) {
                  return { ...chat, lastMessage: res };
               }
               return chat;
            });
            const updatedGroup = groups.map((chat) => {
               if (chat._id === currentChat._id) {
                  return { ...chat, lastMessage: res };
               }
               return chat;
            });

            setFileUploaded(null);
            setFileSelected(null);
            setConversations(updatedConversations);
            setGroups(updatedGroup);
            socketMsg.emit("newMessage", res);
            socketMsg.emit("stop typing", currentChat);
            setTextMessage("");
            setMessages((prev) => [res, ...prev]);
         } catch (error) {
            console.log(error);
         }
      },
      []
   );

   useEffect(() => {
      if (members) {
         fetchConversations();
      }
   }, [members]);

   useEffect(() => {
      if (gobernanceBody) {
         fetchConversations();
      }
   }, [gobernanceBody]);

   const fetchConversations = async () => {
      if (!user.id) return;
      setIsUserChatsLoading(true);

      let conversations = await getConversationsByUserId(user.id, companySelected);
      setUserChats(conversations);

      if (user.role.some((role) => role.toLowerCase().includes("coordinador"))) {
         const groupConv = await getConversationsByCompanyId(companySelected);
         conversations = conversations.concat(groupConv);
      }

      if (gobernanceBody != null) {
         const filteredGovernments = gobernanceBody.filter((government) => government.company === companySelected);
         for (const government of filteredGovernments) {
            const governmentCompanyId = government.company;
            const governmentTitle = government.title.toLowerCase();
            const governmentname = government.title.toUpperCase();
            if (government.users && Array.isArray(government.users)) {
               const governmentParticipants = government.users.map((user) => user.user);

               const existingConversation = conversations.find(
                  (conversation) =>
                     conversation.company === governmentCompanyId &&
                     conversation.title.toLowerCase() === governmentTitle
               );
               if (!existingConversation) {
                  const conversation = {
                     title: governmentname,
                     participants: governmentParticipants,
                     type: "GROUP",
                     company: governmentCompanyId,
                  };

                  const conversationRes = await createConversation(conversation);

                  conversations.push(conversationRes);
               }
            }
         }
      }

      await formattedConversations(conversations);
      const conversationsId = conversations.map((conversation) => conversation._id);
      const unseenMessagesData = await getNumberOfUnseenMessages(user.id, conversationsId);
      setMessagesUnseen(unseenMessagesData);
   };

   const formattedConversations = async (conversations: IConversation[]) => {
      let userArray = [];
      let formattedMessages = [];
      let formattedGroups = [];
      if (conversations.length > 0) {
         for (const iterator of conversations) {
            userArray.push(...iterator.participants);
         }
      }
      const allUsersData = await getFilteredUsers(userArray);
      for (let i = 0; i < conversations.length; i++) {
         for (let j = 0; j < conversations[i].participants.length; j++) {
            const participantId = conversations[i].participants[j];
            const user = allUsersData.find((user) => user._id === participantId);

            if (user) {
               conversations[i].participants[j] = user;
            } else {
               continue;
            }
         }
         if (conversations[i].lastMessage) {
            const lastMessageUser = conversations[i].lastMessage.authorId;
            const user = allUsersData.find((user) => user._id === lastMessageUser);
            if (user) {
               conversations[i].lastMessage.authorId = user;
            } else {
               continue;
            }
         }
         if (conversations[i].type === "GROUP") {
            formattedGroups.push(conversations[i]);
         } else {
            formattedMessages.push(conversations[i]);
         }
      }
      setGroups(formattedGroups);
      setConversations(formattedMessages);
      setIsUserChatsLoading(false);
   };

   const fetchMessages = async (currentChatId: string, isFromSearch) => {
      try {
         let fetchedMessages;
         if (isFromSearch) {
            fetchedMessages = await getMessagesUntilSearchedMessage(selectedMessage.conversation, selectedMessage._id);
         } else {
            fetchedMessages = await getMessagesByConversationId(currentChatId, page);
         }

         if (fetchedMessages.messages.length === 0) {
            setIsLoadingMessages(false);
            socket.emit("join chat", currentChatId);
            return;
         }

         setTimeout(() => {
            if (isFromSearch) {
               setMessages(fetchedMessages.messages);
            } else {
               if (messages.length > 20) {
                  setMessages((prevMessages) => [...prevMessages, ...fetchedMessages.messages.slice(1)]);
               } else {
                  setMessages((prevMessages) => [...prevMessages, ...fetchedMessages.messages]);
               }
            }
            setIsLoadingMessages(false);
            setTotalPages(fetchedMessages.totalPages);
         }, 500);

         socket.emit("join chat", currentChat?._id);
      } catch (error) {
         setIsLoadingMessages(false);
         console.log(error);
      }
   };

   useEffect(() => {
      if (!newConversation) return;
      setCurrentChat(newConversation);
      navigate(`/gobierno-corporativo/${companySelected}/mi-cuenta/chat/${newConversation._id}`);
   }, [newConversation]);

   async function updateUnseenMessagesNumber() {
      const chatsId = conversations.map((conversation) => conversation._id);
      groups.forEach((group) => {
         chatsId.push(group._id);
      });
      const unseenMessagesData = await getNumberOfUnseenMessages(user?.id, chatsId);
      setMessagesUnseen(unseenMessagesData);
   }

   return (
      <ChatContext.Provider
         value={{
            userChats,
            setUserChats,
            isUserChatsLoading,
            setIsUserChatsLoading,
            groups,
            setGroups,
            conversations,
            setConversations,
            socket,
            currentChat,
            setCurrentChat,
            contacts,
            setContacts,
            createChat,
            messages,
            setMessages,
            isLoadingMessages,
            setIsLoadingMessages,
            sendTextMessage,
            newMessage,
            setNewMessage,
            onlineUsers,
            typing,
            setTyping,
            page,
            setPage,
            totalPages,
            setTotalPages,
            notification,
            setNotification,
            allUsers,
            searchInput,
            setSearchInput,
            unseenMessages,
            setMessagesUnseen,
            selectedMessage,
            setSelectedMessage,
            foundMessages,
            setFoundMessages,
            foundConversations,
            setFoundConversations,
            foundGroups,
            setFoundGroups,
            fileUploaded,
            setFileUploaded,
            fileSelected,
            setFileSelected,
            createChatNew,
            members,
            fetchConversations,
            isUploadingFile,
            setIsUploadingFile,
         }}
      >
         {children}
      </ChatContext.Provider>
   );
};
