import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { Box, Grid, Stack } from "@mui/material";
import { LastMessage } from "./LastMessage";
import { ChatContext } from "../../../../context/governanceContext/ChatContext";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SearchMessage } from "./SearchMessage";
import { GovernanceContext } from "../../../../context/governanceContext/governanceContext";
import { UserContext } from "../../../../context/userContext";
import { getUserName, getUsersByIds } from "../../../../lib/usersBEClient";
const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
   ({ theme }) => ({
      border: "0px",
      "&:not(:last-child)": {
         borderBottom: 0,
      },
      "&:before": {
         display: "none",
      },
   })
);

const AccordionSummary = styled((props: AccordionSummaryProps) => (
   <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: 12, color: "#024F6C" }} />} {...props} />
))(({ theme }) => ({
   backgroundColor: "transparent",
   border: "0px solid 0px",
   flexDirection: "row-reverse",
   "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
   },
   "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
   },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
   padding: theme.spacing(2),
   borderTop: "0px solid rgba(0, 0, 0, .125)",
}));

export const receivedUser = (users, isMainUser) => {
   const user = users.find((user) => user._id !== isMainUser.id);
   return user;
};

export const authormessage = async (users, authorId) => {
   let user;
   user = users.find((user) => user._id === authorId);
   if (!user) {
      user = await getUsersByIds(authorId);
      user.iscoord = true;
      const user1 = user[0];
      user1.iscoord = true;
      return user1;
   }
   return user;
};

interface IConversationComponent {
   searchedConversation: boolean;
}

export const ConversationComponent = (props: IConversationComponent) => {
   const [expanded, setExpanded] = useState<string | false>("panel1");
   const [expanded2, setExpanded2] = useState<string | false>("panel2");
   const {
      groups,
      conversations,
      setCurrentChat,
      currentChat,
      isLoadingMessages,
      unseenMessages,
      foundConversations,
      foundGroups,
      foundMessages,
   } = useContext(ChatContext);
   const { companySelected } = useContext(GovernanceContext);
   const { user } = useContext(UserContext);
   const navigate = useNavigate();
   const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
   };
   const handleChange2 = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded2(newExpanded ? panel : false);
   };
   return (
      <>
         {/* ---GROUPS CHATS--- */}
         {groups.length > 0 && !props.searchedConversation ? (
            <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
               <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                  <Typography color={"#024F6C"} fontSize={14} fontWeight={600}>
                     Grupos
                  </Typography>
               </AccordionSummary>
               <AccordionDetails sx={{ p: "0 !important" }}>
                  <Stack>
                     {groups.map((g, index) => {
                        return (
                           <Box
                              key={index}
                              sx={{
                                 "&:hover": { cursor: "pointer" },
                              }}
                              onClick={() => {
                                 if (isLoadingMessages && currentChat) return;
                                 setCurrentChat(g);
                                 navigate(`/gobierno-corporativo/${companySelected}/mi-cuenta/chat/${g._id}`);
                              }}
                           >
                              <LastMessage
                                 content={g.lastMessage ? g.lastMessage.content : ""}
                                 createdAt={g.lastMessage ? g.lastMessage.createdAt : null}
                                 receivedUser={g.title}
                                 unseenMessages={
                                    currentChat &&
                                    currentChat?._id !== g._id &&
                                    unseenMessages &&
                                    (unseenMessages[g._id] !== null ? unseenMessages[g._id] : 0)
                                 }
                                 type={g.type}
                              />
                           </Box>
                        );
                     })}
                  </Stack>
               </AccordionDetails>
            </Accordion>
         ) : (
            foundGroups?.length > 0 && (
               <Accordion expanded={expanded2 === "panel2"} onChange={handleChange2("panel2")}>
                  <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                     <Typography color={"#024F6C"} fontSize={14} fontWeight={600}>
                        Grupos
                     </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ p: "0 !important" }}>
                     <Stack>
                        {foundGroups.map((g, index) => (
                           <Box
                              key={index}
                              sx={{
                                 "&:hover": { cursor: "pointer" },
                              }}
                              onClick={() => {
                                 if (isLoadingMessages && currentChat) return;
                                 setCurrentChat(g);
                                 navigate(`/gobierno-corporativo/${companySelected}/mi-cuenta/chat/${g._id}`);
                              }}
                           >
                              <LastMessage
                                 content={g.lastMessage ? g.lastMessage.content : ""}
                                 createdAt={g.lastMessage ? g.lastMessage.createdAt : null}
                                 receivedUser={g.title}
                                 unseenMessages={currentChat && currentChat?._id !== g._id ? unseenMessages[g._id] : 0}
                                 type={g.type}
                              />
                           </Box>
                        ))}
                     </Stack>
                  </AccordionDetails>
               </Accordion>
            )
         )}
         {/* ---SINGLE CHATS--- */}
         {conversations.length > 0 && !props.searchedConversation ? (
            <Accordion expanded={expanded2 === "panel2"} onChange={handleChange2("panel2")}>
               <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                  <Typography color={"#024F6C"} fontSize={14} fontWeight={600}>
                     Mensajes directos
                  </Typography>
               </AccordionSummary>
               <AccordionDetails sx={{ p: "0 !important" }}>
                  <Stack>
                     {conversations.map((c, index) => {
                        const otherParticipant = c.participants.find((participant) => participant._id !== user.id);
                        c.title = otherParticipant.firstName + " " + otherParticipant.lastName;
                        return (
                           <Grid
                              key={index}
                              sx={{
                                 "&:hover": { cursor: "pointer" },
                              }}
                              onClick={() => {
                                 if (isLoadingMessages && currentChat) return;
                                 setCurrentChat(c);
                                 navigate(`/gobierno-corporativo/${companySelected}/mi-cuenta/chat/${c._id}`);
                              }}
                           >
                              <LastMessage
                                 content={c.lastMessage ? c.lastMessage.content : ""}
                                 createdAt={c.lastMessage ? c.lastMessage.createdAt : null}
                                 receivedUser={c.title}
                                 unseenMessages={
                                    currentChat &&
                                    currentChat?._id !== c._id &&
                                    unseenMessages &&
                                    (unseenMessages[c._id] ? unseenMessages[c._id] : 0)
                                 }
                                 type={c.type}
                                 receivedUserId={otherParticipant._id}
                              />
                           </Grid>
                        );
                     })}
                  </Stack>
               </AccordionDetails>
            </Accordion>
         ) : (
            <Accordion expanded={expanded2 === "panel2"} onChange={handleChange2("panel2")}>
               <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                  {
                     <Typography color={"#024F6C"} fontSize={14} fontWeight={600}>
                        Búsqueda
                     </Typography>
                  }
               </AccordionSummary>
               <AccordionDetails sx={{ p: "0 !important" }}>
                  <Stack>
                     {foundConversations?.length > 0 &&
                        foundConversations.map((c, index) => {
                           const otherParticipant = c.participants.find((participant) => participant._id !== user.id);
                           c.title = otherParticipant.firstName + " " + otherParticipant.lastName;
                           return (
                              <Grid
                                 key={index}
                                 sx={{
                                    "&:hover": { cursor: "pointer" },
                                 }}
                                 onClick={() => {
                                    if (isLoadingMessages && currentChat) return;
                                    setCurrentChat(c);
                                    navigate(`/gobierno-corporativo/${companySelected}/mi-cuenta/chat/${c._id}`);
                                 }}
                              >
                                 <LastMessage
                                    content={c.lastMessage ? c.lastMessage.content : ""}
                                    createdAt={c.lastMessage ? c.lastMessage.createdAt : null}
                                    receivedUser={c.title}
                                    unseenMessages={
                                       currentChat && currentChat?._id !== c._id ? unseenMessages[c._id] : 0
                                    }
                                    type={c.type}
                                    receivedUserId={otherParticipant._id}
                                 />
                              </Grid>
                           );
                        })}
                  </Stack>
                  <SearchMessage />
               </AccordionDetails>
            </Accordion>
         )}
      </>
   );
};
