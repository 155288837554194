import { AttachFile } from "@mui/icons-material";
import { Backdrop, Box, Button, CircularProgress, Grid, IconButton, MenuItem, Modal, Typography } from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import { SnackBarContext } from "../../../../../context/snackBarContext";
import { Form, Formik, FormikProps } from "formik";
import { corporateDataSchema } from "../../../../../lib/validations/inputSchemas";
import { InputTextField } from "../../../../Inputs/InputTextField";
import { SocietyKindsComponent } from "./Assets/SocietyKindsComponent";
import { UseGetPresignedURLUpload, uploadFileToS3 } from "../../../../../lib/s3Client";
import { AddFileModal } from "../../../AddFileModal";
import { WatchmanOptionsComponent } from "../../../ModalsGovernanceModule/WatchmanOptionsComponent";
import { CountrySelectComponent } from "../../../ModalsGovernanceModule/CountrySelectComponent";
import TextEditor from "../../../../Inputs/TextEditor/TextEditor";
import { getUrlS3, postCorporateData, updateCorporateData } from "../../../../../lib/usersBEClient";
import { InputSelectField } from "../../../../Inputs/InputSelectField";
import dayjs from "dayjs";
import { ListOfTexFields } from "../../../../Gobierno corporativo/Inputs/ListOfTexFields";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
   createConsultiveGroup,
   getConsultiveGroupById,
   updateConsultiveGroup,
} from "../../../../../lib/gobCorpBEClient";
import { UserContext } from "../../../../../context/userContext";
import { CorporateDataContext } from "../../../../../context/governanceContext/corporateDataContext";
import { formatFileSize } from "../../../../../const/globalConst";

const formatArrayField = (field) => (typeof field === "string" ? [] : field);
const defaultValues = {
   societyKind: "",
   legalConcept: "",
   legalConceptOther: "",
   physicalAddress: "",
   incorporationYear: "",
   taxRegime: "",
   operatingCountries: [],
   watchman: [],
   socialObject: "",
   administration: "",
   legalRepresentative: "",
   councilType: "",
   administratorFaculties: "",
   businessFolio: "",
   notaryName: "",
   notaryNumber: "",
   recordType: "",
   actNumber: "",
};

const councilTypeOptions = ["Legal", "Consultivo"];
const recordTypes = ["Constitutiva", "Extraordinaria"];

interface CorporateDataModalProps {
   setState: Function;
   edition: boolean;
   setBodiesSeed: Function;
}

const CorporateDataModal = (props: CorporateDataModalProps) => {
   const { showSnackBar } = useContext(SnackBarContext);
   const { companySelected } = useContext(UserContext);
   const { corporateData, setCorporateData } = useContext(CorporateDataContext);

   const [incorporationDate, setIncorporationDate] = useState<dayjs.Dayjs>(() =>
      typeof corporateData?.incorporationYear === "string" && corporateData?.incorporationYear.length < 6
         ? null
         : dayjs(corporateData?.incorporationYear)
   );

   const [text, setText] = useState(() => (props.edition ? corporateData?.administratorFaculties || "" : ""));
   const [grantDate, setGrantDate] = useState<dayjs.Dayjs>(() =>
      corporateData?.grantDate ? dayjs(corporateData.grantDate) : null
   );
   const [filePact, setFilePact] = useState<null | File>(null);
   const [beneficiaryFile, setBeneficiaryFile] = useState<null | File>(null);
   const [openFilePactModal, setOpenFilePactModal] = useState(false);
   const [openBeneficiaryModal, setOpenBeneficiaryModal] = useState(false);
   const [currentPactsFileName, setCurrentPactsFileName] = useState("");
   const [currentBeneficiaryFile, setCurrentBeneficiaryFile] = useState("");
   const [isLoading, setIsLoading] = useState(false);

   const hasBeneficiary = useMemo(
      () =>
         companySelected?.company_details?.servicesDetails?.some(
            ({ serviceId, disable }) => serviceId?._id === "66d75b0724169eb3399c631b" && !disable
         ) ?? false,
      [companySelected]
   );

   const initialValues = props.edition
      ? {
           ...defaultValues,
           ...corporateData,
           watchman: formatArrayField(corporateData.watchman),
           socialObject: formatArrayField(corporateData.socialObject),
        }
      : defaultValues;

   const fetchFile = async (fileName: string, setFile: Function) => {
      try {
         const fileUrl = await getUrlS3(
            "files-lecosy",
            { folder: `gc/companies/${companySelected._id}/informacion-societaria` },
            fileName
         );
         const response = await fetch(fileUrl);
         const blob = await response.blob();
         const file = new File([blob], fileName, { type: "application/pdf" });
         setFile(file);
      } catch (error) {
         console.error("Error fetching file:", error);
      }
   };

   useEffect(() => {
      if (!corporateData) return;
      const { filePact, beneficiaryManual } = corporateData;
      const fetchFiles = [
         { fileData: filePact, setFile: setFilePact, setCurrentName: setCurrentPactsFileName },
         { fileData: beneficiaryManual, setFile: setBeneficiaryFile, setCurrentName: setCurrentBeneficiaryFile },
      ];
      fetchFiles.forEach(({ fileData, setFile, setCurrentName }) => {
         if (fileData) {
            setCurrentName(fileData.name);
            fetchFile(fileData.name, setFile);
         }
      });
   }, [corporateData]);

   const handleUploadFile = async (fileName: string, file: File) => {
      const urlReceipt2 = await UseGetPresignedURLUpload({
         bucket: "files-lecosy",
         folder: `gc/companies/${companySelected._id}/informacion-societaria`,
         name: fileName,
      });

      await uploadFileToS3(urlReceipt2.data, file);
   };

   const handleSubmit = async (values) => {
      try {
         setIsLoading(true);
         if (incorporationDate === null) {
            showSnackBar("Fecha de constitución/modificación sin seleccionar", true);
            setIsLoading(false);
            return;
         }

         if (filePact === null) {
            showSnackBar("Falta pacto de socios (PDF)", true);
            setIsLoading(false);
            return;
         }

         const data = {
            companyId: companySelected._id,
            ...values,
            incorporationYear: incorporationDate,
            grantDate: grantDate,
            filePact: {
               name: filePact.name,
               size: Math.trunc(filePact.size / (1024 * 1024)),
            },
            beneficiaryManual: hasBeneficiary &&
               currentBeneficiaryFile !== beneficiaryFile?.name && {
                  name: beneficiaryFile?.name,
                  size: Math.trunc(beneficiaryFile?.size / (1024 * 1024)),
               },
         };
         let updatedCorporateData;
         if (props.edition) updatedCorporateData = await updateCorporateData(corporateData._id, data);
         else updatedCorporateData = await postCorporateData(data);

         if (currentPactsFileName !== filePact.name) await handleUploadFile(filePact.name, filePact);
         if (hasBeneficiary && currentBeneficiaryFile !== beneficiaryFile?.name)
            await handleUploadFile(beneficiaryFile.name, beneficiaryFile);

         //GROUPS
         const groupId = (companySelected?.group as any)?._id;
         const groupFound = await getConsultiveGroupById(groupId);

         if (values.councilType === "Consultivo") {
            if (groupFound) {
               if (!groupFound.companies.includes(companySelected?._id)) {
                  groupFound.companies.push(companySelected?._id);
                  await updateConsultiveGroup(groupId, groupFound.companies);
               }
            } else await createConsultiveGroup({ _id: groupId, companies: [companySelected?._id] });
         } else if (groupFound && groupFound.companies.includes(companySelected?._id)) {
            groupFound.companies = groupFound.companies.filter((c) => c !== companySelected?._id);
            await updateConsultiveGroup(groupId, groupFound.companies);
         }

         showSnackBar(
            props.edition ? "Información actualizada correctamente" : "Información creada correctamente",
            false
         );
         setCorporateData(updatedCorporateData);
         props.setBodiesSeed(Math.random());
         props.setState(false);
      } catch (error) {
         console.log(error);
         showSnackBar(props.edition ? "Error al modificar la información" : "Error al agregar la información", true);
      }
      setIsLoading(false);
   };

   return (
      <Box sx={{ maxWidth: 700 }}>
         <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={corporateDataSchema}>
            {(formProps: FormikProps<any>) => (
               <Form>
                  <Box
                     sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        maxHeight: 500,
                        px: 4,
                        py: 2,
                        rowGap: 2,
                        bgcolor: "white",
                        overflow: "auto",
                     }}
                  >
                     <Grid container sx={{ justifyContent: "space-between", rowGap: 2, columnGap: 1 }}>
                        <Grid item xs={12}>
                           <Typography>Denominación/Razón social</Typography>
                           <InputTextField
                              disabled
                              type="string"
                              id="businessName"
                              name="businessName"
                              placeholder={companySelected?.person_details?.businessName || ""}
                              size="small"
                              fullWidth
                           />
                        </Grid>

                        <Grid item xs={12}>
                           <Typography>Nombre comercial</Typography>
                           <InputTextField
                              disabled
                              type="string"
                              id="comercialName"
                              name="comercialName"
                              placeholder={companySelected.person_details.comercialName || ""}
                              size={"small"}
                              fullWidth
                           />
                        </Grid>
                        <Grid item xs={12}>
                           <SocietyKindsComponent
                              disabled={props.edition}
                              currentSociety={formProps.values.societyKind}
                              currentLegalConcept={formProps.values.legalConcept}
                           />
                        </Grid>
                        <Grid item xs={5.5}>
                           <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es-mx">
                              <Typography>Fecha de constitución/modificación</Typography>
                              <DatePicker
                                 maxDate={dayjs(new Date())}
                                 format="DD/MM/YYYY"
                                 value={incorporationDate || null}
                                 onChange={(event: any) => {
                                    if (event) setIncorporationDate(dayjs(event.$d));
                                    else setIncorporationDate(null);
                                 }}
                                 slotProps={{ textField: { variant: "outlined", size: "small", fullWidth: true } }}
                              />
                           </LocalizationProvider>
                        </Grid>
                        <Grid item xs={5.5}>
                           <WatchmanOptionsComponent
                              companyId={companySelected._id}
                              id="watchman"
                              name="watchman"
                              currentWatchman={formProps.values.watchman}
                              setSelected={(option) => {
                                 formProps.setFieldValue(
                                    "watchman",
                                    typeof option === "string" ? option.split(",") : option
                                 );
                              }}
                           />
                        </Grid>
                        <Grid item xs={5.5}>
                           <Typography>Tipo de acta</Typography>
                           <InputSelectField
                              id="recordType"
                              name="recordType"
                              label=""
                              labelId=""
                              variant="outlined"
                              size={"small"}
                              fullWidth
                           >
                              {recordTypes.map((e, index) => (
                                 <MenuItem key={index} value={e}>
                                    {e}
                                 </MenuItem>
                              ))}
                           </InputSelectField>
                        </Grid>
                        <Grid item xs={5.5}>
                           <Typography>
                              {formProps.values.societyKind === "Sociedad mercantil"
                                 ? "Folio mercantil"
                                 : "Número de inscripción"}
                           </Typography>
                           <InputTextField
                              type="text"
                              id="businessFolio"
                              name="businessFolio"
                              placeholder={
                                 formProps.values.societyKind === "Sociedad mercantil"
                                    ? "Folio mercantil"
                                    : "Número de inscripción"
                              }
                              size={"small"}
                              fullWidth
                           />
                        </Grid>
                        <Grid item xs={5.5}>
                           <Typography>Nombre del notario</Typography>
                           <InputTextField
                              type="text"
                              id="notaryName"
                              name="notaryName"
                              placeholder="Nombre del notario"
                              size={"small"}
                              fullWidth
                           />
                        </Grid>
                        <Grid item xs={5.5}>
                           <Typography>Número de notaria</Typography>
                           <InputTextField
                              type="text"
                              id="notaryNumber"
                              name="notaryNumber"
                              placeholder="Número de notaria"
                              size={"small"}
                              fullWidth
                           />
                        </Grid>
                        <Grid item xs={12}>
                           <Typography>Objeto social</Typography>
                           <ListOfTexFields
                              listOfData={formProps.values.socialObject || []}
                              setListOfData={formProps.setFieldValue}
                              inputProps={{
                                 id: "socialObject",
                                 name: "socialObject",
                              }}
                           />
                        </Grid>
                        <Grid item xs={5.5}>
                           <Typography>Pactos de socios</Typography>
                           <Box
                              sx={{
                                 display: "flex",
                                 border: 1,
                                 borderColor: "#E0E0E0",
                                 alignItems: "center",
                                 px: 1,
                                 justifyContent: "space-between",
                                 borderRadius: 1.5,
                              }}
                           >
                              {filePact === null ? (
                                 <>
                                    <Typography fontSize={15}>Adjuntar archivo</Typography>
                                    <IconButton onClick={() => setOpenFilePactModal(true)}>
                                       <AttachFile />
                                    </IconButton>
                                 </>
                              ) : (
                                 <>
                                    <Typography fontSize={15}>{filePact.name.substring(0, 17)}</Typography>
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                       <Typography fontSize={12}>{formatFileSize(filePact.size)}</Typography>
                                       <IconButton onClick={() => setOpenFilePactModal(true)}>
                                          <AttachFile />
                                       </IconButton>
                                    </Box>
                                 </>
                              )}
                           </Box>
                        </Grid>
                        <Grid item xs={5.5}>
                           <Typography>Régimen fiscal</Typography>
                           <InputTextField
                              type="text"
                              id="taxRegime"
                              name="taxRegime"
                              placeholder="Régimen fiscal"
                              size={"small"}
                              fullWidth
                           />
                        </Grid>
                        <Grid item xs={5.5}>
                           <CountrySelectComponent
                              label="Países en los que opera"
                              id="operatingCountries"
                              name="operatingCountries"
                              countries={formProps.values.operatingCountries}
                              setCountries={(c) => {
                                 formProps.setFieldValue(
                                    "operatingCountries",
                                    typeof c === "string" ? c.split(",") : c
                                 );
                              }}
                           />
                        </Grid>
                        <Grid item xs={5.5}>
                           <Typography>Órgano de Administración</Typography>
                           <InputSelectField
                              id="administration"
                              name="administration"
                              label=""
                              labelId=""
                              variant="outlined"
                              size={"small"}
                              fullWidth
                           >
                              {(formProps.values.societyKind === "Sociedad mercantil"
                                 ? ["Consejo de administración", "Administrador único"]
                                 : ["Consejo de gerentes", "Director general"]
                              ).map((option) => (
                                 <MenuItem key={option} value={option}>
                                    {option}
                                 </MenuItem>
                              ))}
                           </InputSelectField>
                        </Grid>
                        <Grid item xs={5.5}>
                           <Typography>Tipo de consejo</Typography>
                           <InputSelectField
                              id="councilType"
                              name="councilType"
                              label=""
                              labelId=""
                              variant="outlined"
                              size={"small"}
                              fullWidth
                           >
                              {councilTypeOptions.map((e, index) => (
                                 <MenuItem key={index} value={e}>
                                    {e}
                                 </MenuItem>
                              ))}
                           </InputSelectField>
                        </Grid>

                        <Grid item xs={5.5}>
                           <Typography>Dirección física</Typography>
                           <InputTextField
                              type="string"
                              id="physicalAddress"
                              name="physicalAddress"
                              placeholder="Dirección física"
                              size={"small"}
                              fullWidth
                           />
                        </Grid>
                        <Grid item xs={4}>
                           <Typography>Apoderado legal</Typography>
                           <InputTextField
                              type="text"
                              id="legalRepresentative"
                              name="legalRepresentative"
                              placeholder="Apoderado legal"
                              size={"small"}
                              fullWidth
                           />
                        </Grid>
                        <Grid item xs={2.5}>
                           <Typography>Número de acta</Typography>
                           <InputTextField
                              type="text"
                              id="actNumber"
                              name="actNumber"
                              placeholder="000"
                              size={"small"}
                              fullWidth
                           />
                        </Grid>
                        <Grid item xs={3.5}>
                           <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es-mx">
                              <Typography>Fecha de otorgamiento</Typography>
                              <DatePicker
                                 maxDate={dayjs(new Date())}
                                 format="DD/MM/YYYY"
                                 value={grantDate || null}
                                 onChange={(event: any) => {
                                    if (event) setGrantDate(dayjs(event.$d));
                                    else setGrantDate(null);
                                 }}
                                 slotProps={{ textField: { variant: "outlined", size: "small", fullWidth: true } }}
                              />
                           </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12}>
                           <Typography>Poderes y facultades de la administración</Typography>
                           <TextEditor
                              text={text}
                              setText={(e) => {
                                 setText(e);
                                 formProps.setFieldValue("administratorFaculties", e);
                              }}
                           />
                        </Grid>
                        {hasBeneficiary && (
                           <Grid item xs={12}>
                              <Typography>Manual de identificación de beneficiario controlador</Typography>
                              <Box
                                 sx={{
                                    display: "flex",
                                    border: 1,
                                    borderColor: "#E0E0E0",
                                    alignItems: "center",
                                    px: 1,
                                    justifyContent: "space-between",
                                    borderRadius: 1.5,
                                 }}
                              >
                                 {!beneficiaryFile ? (
                                    <>
                                       <Typography fontSize={15}>Adjuntar archivo</Typography>
                                       <IconButton onClick={() => setOpenBeneficiaryModal(true)}>
                                          <AttachFile />
                                       </IconButton>
                                    </>
                                 ) : (
                                    <>
                                       <Typography fontSize={15}>{beneficiaryFile?.name?.substring(0, 50)}</Typography>
                                       <Box sx={{ display: "flex", alignItems: "center" }}>
                                          <Typography fontSize={12}>{formatFileSize(beneficiaryFile.size)}</Typography>
                                          <IconButton onClick={() => setOpenBeneficiaryModal(true)}>
                                             <AttachFile />
                                          </IconButton>
                                       </Box>
                                    </>
                                 )}
                              </Box>
                           </Grid>
                        )}
                     </Grid>
                  </Box>
                  <Box
                     sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        bgcolor: "#F3F3F3",
                        width: "100%",
                        p: 1,
                        height: 50,
                        borderBottomRightRadius: 5,
                        borderBottomLeftRadius: 5,
                     }}
                  >
                     <Button
                        onClick={() => props.setState(false)}
                        sx={{ color: "black", height: 35, ":hover": { bgcolor: "#E5E6EB" }, mr: 2 }}
                     >
                        Cancelar
                     </Button>
                     <Button type="submit" disabled={isLoading} sx={{ height: 35, ":hover": { bgcolor: "success" } }}>
                        {!isLoading ? "Guardar" : <CircularProgress size={24} />}
                     </Button>
                  </Box>
               </Form>
            )}
         </Formik>
         <AddFileModal
            state={openFilePactModal}
            setState={setOpenFilePactModal}
            setFile={setFilePact}
            file={filePact}
         />
         <AddFileModal
            state={openBeneficiaryModal}
            setState={setOpenBeneficiaryModal}
            setFile={setBeneficiaryFile}
            file={beneficiaryFile}
         />
      </Box>
   );
};

export default CorporateDataModal;
