import { AttachFile, Description } from "@mui/icons-material";
import { Box, Button, CircularProgress, Grid, IconButton, ListItemText, MenuItem, Typography } from "@mui/material";
import { Form, Formik, FormikProps, useFormikContext } from "formik";
import { InputTextField } from "../Inputs/InputTextField";
import { useContext, useEffect, useMemo, useState } from "react";
import { SnackBarContext } from "../../context/snackBarContext";
import { governingBodySchema, shareholdersBodySchema } from "../../lib/validations/inputSchemas";
import { createGovernBody, updateGovernBody } from "../../lib/gobCorpBEClient";
import { AddFileModal } from "./AddFileModal";
import { InputSelectField } from "../Inputs/InputSelectField";
import { CreateFileByName, createFolderGoverning, deleteRegulationFile } from "../../lib/usersBEClient";
import { InputCheckBox } from "../Inputs/InputCheckBox";
import { GovernBodyData } from "./GoverningBodiesComponent";
import { ListOfTexFields } from "../Gobierno corporativo/Inputs/ListOfTexFields";
import { CorporateDataContext } from "../../context/governanceContext/corporateDataContext";
import { FunctionsList } from "./FunctionsList";
import { UserContext } from "../../context/userContext";
import { formatFileSize } from "../../const/globalConst";
import { uploadFileToS3 } from "../../lib/s3Client";
import { ConsultiveGroup } from "../../types/governance.types";

interface NewGoverningBodyModalProps {
   setState: Function;
   company: any;
   group: ConsultiveGroup;
   setSeed: Function;
   titlesArray: string[];
   setPhase: Function;
   setBodyData: Function;
   bodyData: GovernBodyData;
   bodyEdition: boolean;
   setUserModalSeed: Function;
   setBodyUsers: Function;
}

const sessionsPeriods = ["1 año", "2 años", "3 años"];
const nameOptionsCivil = [
   "Asamblea de socios",
   "Consejo de gerentes",
   "Comité directivo",
   "Comité de Planeación y Finanzas",
   "Comité de Evaluación y Compensación",
   "Comité de Riesgo y Cumplimiento",
   "Comité de Auditoria",
   "Comité de Operaciones",
   "Otro comité",
];
const nameOptionsMercantil = [
   "Asamblea de accionistas",
   "Consejo de administración",
   "Comité directivo",
   "Comité de Planeación y Finanzas",
   "Comité de Evaluación y Compensación",
   "Comité de Riesgo y Cumplimiento",
   "Comité de Auditoria",
   "Comité de Operaciones",
   "Otro comité",
];

export const NewGoverningBodyModal = (props: NewGoverningBodyModalProps) => {
   const {
      setState,
      setBodyData,
      setPhase,
      setSeed,
      bodyData,
      bodyEdition,
      company,
      group,
      titlesArray,
      setUserModalSeed,
      setBodyUsers,
   } = props;
   const { user } = useContext(UserContext);
   const [file, setFile] = useState<null | File>(bodyEdition ? bodyData.file : null);
   const [open, setOpen] = useState(false);
   const [submitLoading, setSubmitLoading] = useState(false);
   const { showSnackBar } = useContext(SnackBarContext);
   const [currentFileName, setCurrentFileName] = useState("");
   const { corporateData } = useContext(CorporateDataContext);
   const { companySelected } = useContext(UserContext);

   const { mutate: createFile } = CreateFileByName();
   const [repeatedTitle, setRepeatedTitle] = useState(false);
   const [shareholderCheck, setShareholderCheck] = useState(false);
   const checkWatchman = false;

   const onlyHasBeneficiary = useMemo(() => {
      const services = companySelected?.company_details?.servicesDetails || [];
      return (
         services.some(({ serviceId, disable }) => serviceId?._id === "66d75b0724169eb3399c631b" && !disable) &&
         !services.some(({ serviceId, disable }) => serviceId?._id === "64e7851d978b71bd4f011ee4" && !disable)
      );
   }, [companySelected]);

   useEffect(() => {
      if (bodyData.file) setCurrentFileName(bodyData.file.name);
   }, [bodyData]);

   const filteredTitles = useMemo(() => {
      const nameOptions = corporateData?.societyKind === "Sociedad mercantil" ? nameOptionsMercantil : nameOptionsCivil;
      let availableOptions = nameOptions.filter((item) => !titlesArray.includes(item));
      if (onlyHasBeneficiary) {
         return availableOptions.filter((item) =>
            [
               "Asamblea de accionistas",
               "Consejo de administración",
               "Asamblea de socios",
               "Consejo de gerentes",
            ].includes(item)
         );
      }
      return group
         ? availableOptions.filter((item) => !["Asamblea de accionistas", "Asamblea de socios"].includes(item))
         : availableOptions;
   }, [titlesArray, corporateData, onlyHasBeneficiary, group]);

   const handleSubmit = async (values) => {
      try {
         setSubmitLoading(true);
         let userPermission = false;
         if (
            user.role.includes("Usuario de implementación") ||
            user.role.includes("Administrador del Sistema") ||
            user.role.includes("N2") ||
            user.role.includes("Coordinador de gobierno corporativo") ||
            user.role.includes("Director General") ||
            user.role.includes("Administrador Único / Presidente del Consejo")
         ) {
            userPermission = true;
         } else {
            userPermission = false;
            showSnackBar("Usuario sin permisos", true);
            return;
         }

         if (repeatedTitle) {
            showSnackBar("Nombre de órgano esta en uso", true);
            return;
         }

         if (!shareholderCheck && !onlyHasBeneficiary) {
            const qualitativePercentage =
               Number(values.compositionP) + Number(values.compositionR) + Number(values.compositionI);
            if (qualitativePercentage !== 100) {
               showSnackBar("Composición cualitativa insuficiente", true);
               setSubmitLoading(false);
               return;
            }
         }

         if (file === null && !onlyHasBeneficiary) {
            showSnackBar("Falta reglamento (PDF)", true);
            setSubmitLoading(false);
            return;
         }
         if (!bodyEdition) {
            const data = !onlyHasBeneficiary
               ? {
                    title: values.title,
                    functions: values.functions,
                    name: values.name,
                    watchman: values.watchman,
                    regulationFile: {
                       name: file.name,
                       size: file?.size,
                    },
                    users: [],
                    company: group?._id ?? company?._id,
                    disabled: false,
                    structure: {
                       integrationMin: Number(values.integrationMin),
                       integrationMax: Number(values.integrationMax),
                       chargesDuration: values.chargesDuration,
                       quorum: Number(values.quorum),
                       sendAnnouncementDays: Number(values.sendAnnouncementDays),
                       confirmationDays: Number(values.confirmationDays),
                       resolutionVotes: Number(values.resolutionVotes),
                       compositionP: Number(values.compositionP),
                       compositionR: Number(values.compositionR),
                       compositionI: Number(values.compositionI),
                    },
                 }
               : {
                    title: values.title,
                    company: group?._id ?? company?._id,
                    disabled: false,
                    users: [],
                    functions: values.functions,
                 };
            const governBodyData = await createGovernBody(data);
            if (!governBodyData) {
               setSubmitLoading(false);
               return showSnackBar("Error al crear órgano de gobierno", true);
            }

            const createdFolder = await createFolderGoverning({
               folderData: { name: governBodyData.title, fileNumber: 0, shared: [], children: [] },
               governanceId: governBodyData?._id,
            });

            if (!onlyHasBeneficiary) {
               createFile(
                  {
                     file: {
                        name: file.name,
                        metadata: [],
                        size: file.size,
                        type: file.type,
                        fileDirection: `gc/${company ? "companies" : "groups"}/${
                           company ? company?._id : group?._id
                        }/governing-body/${governBodyData?._id}/files/`,
                        governingBody: governBodyData?._id,
                     },
                     folder: {
                        name: createdFolder.name,
                        governance: governBodyData?._id,
                     },
                  },
                  {
                     onError: (error: any) => {
                        console.log(error);
                        showSnackBar("Error al agregar archivo.", true);
                     },
                     onSuccess: async (fileData) => {
                        await uploadFileToS3(fileData.urlToUpload, file);
                        setUserModalSeed(Math.random());
                        setPhase(1);
                        setSubmitLoading(false);
                     },
                  }
               );
            } else {
               setUserModalSeed(Math.random());
               setPhase(1);
            }
            setBodyData({ title: governBodyData.title, bodyId: governBodyData?._id });
            setBodyUsers(governBodyData.users);
            showSnackBar("Éxito al crear comité", false);
            setSeed(Math.random());
         } else {
            const updatedData = {
               title: values.title,
               functions: values.functions,
               watchman: values.watchman,
               regulationFile: {
                  name: file.name,
                  size: file?.size,
               },
               structure: {
                  integrationMin: Number(values.integrationMin),
                  integrationMax: Number(values.integrationMax),
                  chargesDuration: values.chargesDuration,
                  quorum: Number(values.quorum),
                  sendAnnouncementDays: Number(values.sendAnnouncementDays),
                  confirmationDays: Number(values.confirmationDays),
                  resolutionVotes: Number(values.resolutionVotes),
                  compositionP: Number(values.compositionP),
                  compositionR: Number(values.compositionR),
                  compositionI: Number(values.compositionI),
               },
            };
            await updateGovernBody(bodyData.bodyId, updatedData);
            if (currentFileName !== file.name) {
               const folderMap = {
                  "Asamblea de accionistas": "Acta constitutiva",
                  "Asamblea de socios": "Acta constitutiva",
                  "Consejo de administración": "Reglamento de consejo",
                  "Consejo de gerentes": "Reglamento de consejo",
               };
               const folderName = folderMap[bodyData.title] || "Reglamento de comité";
               const folder = await deleteRegulationFile(bodyData.bodyId, folderName, userPermission);
               if (folder) {
                  createFile(
                     {
                        file: {
                           name: file.name,
                           metadata: [],
                           size: file.size,
                           type: file.type,
                           fileDirection: `gc/${company ? "companies" : "groups"}/${
                              company ? company?._id : group?._id
                           }/governing-body/${bodyData.bodyId}/files/`,
                           governingBody: bodyData.bodyId,
                        },
                        folder: {
                           name: folder.name,
                           governance: bodyData.bodyId,
                        },
                     },
                     {
                        onError: (error: any) => {
                           console.log(error);
                           showSnackBar("Error al agregar archivo.", true);
                        },
                        onSuccess: async (fileData) => {
                           await uploadFileToS3(fileData.urlToUpload, file);
                           showSnackBar("Comité actualizado correctamente", false);
                           setUserModalSeed(Math.random());
                           setPhase(1);
                           setSubmitLoading(false);
                        },
                     }
                  );
               }
            }
         }
      } catch (error) {
         console.log(error);
         showSnackBar(bodyEdition ? "Error al modificar el comité" : "Error al crear comité", true);
      }
   };

   const FormObserver: React.FC = () => {
      const { values, setFieldValue } = useFormikContext<any>();

      useEffect(() => {
         setShareholderCheck(["Asamblea de accionistas", "Asamblea de socios"].includes(values.title));
         if (!bodyEdition) {
            const functionsMap = {
               "Consejo de administración": FunctionsList.adminCouncilList,
               "Consejo de gerentes": FunctionsList.adminCouncilList,
               "Comité de Auditoria": FunctionsList.auditCouncilList,
               "Comité de Evaluación y Compensación": FunctionsList.evaluationCompensationCouncilList,
               "Comité de Planeación y Finanzas": FunctionsList.planningFinanceCouncilList,
               "Comité de Riesgo y Cumplimiento": FunctionsList.riskComplianceCouncilList,
            };
            setFieldValue("functions", functionsMap[values.title] || []);
         }
      }, [values.title, bodyEdition, setFieldValue]);

      useEffect(() => {
         if (values.title === "Otro comité") {
            for (const title of titlesArray) {
               const titleTrim = title
                  .trim()
                  .replace(/ +/g, "")
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "");
               const newName = values.name
                  .trim()
                  .replace(/ +/g, "")
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "");
               const result = titleTrim.localeCompare(newName);
               if (result === 0) {
                  setRepeatedTitle(true);
                  return;
               }
            }
         }
      }, [values.name]);

      return null;
   };

   return (
      <>
         <Formik
            initialValues={{
               title: bodyData?.title || "",
               functions: bodyData?.functions || [],
               name: "",
               integrationMin: bodyData.structureData?.integrationMin || "",
               integrationMax: bodyData.structureData?.integrationMax || "",
               chargesDuration: bodyData.structureData?.chargesDuration || "",
               quorum: bodyData.structureData?.quorum || "",
               sendAnnouncementDays: bodyData.structureData?.sendAnnouncementDays || "",
               confirmationDays: bodyData.structureData?.confirmationDays || "",
               resolutionVotes: bodyData.structureData?.resolutionVotes || "",
               compositionP: bodyData.structureData?.compositionP || 0,
               compositionR: bodyData.structureData?.compositionR || 0,
               compositionI: bodyData.structureData?.compositionI || 0,
               watchman: checkWatchman,
            }}
            onSubmit={handleSubmit}
            validationSchema={
               onlyHasBeneficiary ? null : shareholderCheck ? shareholdersBodySchema : governingBodySchema
            }
         >
            {(formProps: FormikProps<any>) => (
               <Form>
                  <FormObserver />
                  <Box
                     sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        minWidth: 700,
                        maxHeight: 500,
                        px: 4,
                        py: 1,
                        rowGap: 2,
                        bgcolor: "white",
                        borderBottomLeftRadius: 8,
                        borderBottomRightRadius: 8,
                     }}
                  >
                     <Grid container rowGap={2} overflow={"auto"} pb={3}>
                        {!bodyEdition && (
                           <>
                              <Grid xs={12} sx={{ px: 1 }}>
                                 <Typography variant="body2" fontWeight={600}>
                                    Nombre del órgano de gobierno
                                 </Typography>
                              </Grid>
                              <Grid xs={formProps.values.title === "Otro comité" ? 3 : 12} sx={{ p: 1 }}>
                                 <InputSelectField
                                    fullWidth={true}
                                    labelId="modules-select-label"
                                    id="title"
                                    name="title"
                                    label={""}
                                    variant="outlined"
                                    size="small"
                                    placeholder={bodyData.title}
                                    disabled={bodyEdition}
                                    onChange={(e) => {
                                       formProps.setFieldValue("title", e.target.value);
                                    }}
                                 >
                                    {filteredTitles.map((e) => (
                                       <MenuItem key={e} value={e}>
                                          <ListItemText primary={e} sx={{ my: -0.1 }} />
                                       </MenuItem>
                                    ))}
                                 </InputSelectField>
                              </Grid>
                              {formProps.values.title === "Otro comité" ? (
                                 <>
                                    <Grid xs={9} sx={{ p: 1 }}>
                                       <InputTextField
                                          fullWidth
                                          label="Nombre"
                                          id="name"
                                          name="name"
                                          type="text"
                                          size="small"
                                          disabled={bodyEdition}
                                       />
                                    </Grid>
                                    <Grid xs={12} sx={{ display: "flex", alignItems: "center", px: 1 }}>
                                       <InputCheckBox
                                          id="watchman"
                                          name="watchman"
                                          label=""
                                          disabled={bodyEdition}
                                          size="medium"
                                       />
                                       <Typography>Comité encargado de vigilancia</Typography>
                                    </Grid>
                                 </>
                              ) : null}
                           </>
                        )}

                        {!onlyHasBeneficiary && (
                           <>
                              {!shareholderCheck && (
                                 <>
                                    <Grid xs={12} sx={{ px: 1 }}>
                                       <Typography variant="body2" fontWeight={600}>
                                          Funciones del órgano de gobierno
                                       </Typography>
                                    </Grid>
                                    <Grid xs={12} sx={{ px: 1 }}>
                                       <ListOfTexFields
                                          listOfData={formProps.values.functions}
                                          setListOfData={formProps.setFieldValue}
                                          inputProps={{
                                             id: "functions",
                                             name: "functions",
                                          }}
                                       />
                                    </Grid>
                                 </>
                              )}
                              <Grid xs={4} sx={{ px: 2 }}>
                                 <Typography variant="subtitle2">Días para mandar convocatoria:</Typography>
                                 <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
                                    <InputTextField
                                       size="small"
                                       id="sendAnnouncementDays"
                                       name="sendAnnouncementDays"
                                       type="text"
                                    />
                                    <Typography variant="caption">días</Typography>
                                 </Box>
                              </Grid>
                              <Grid xs={4} sx={{ px: 2 }}>
                                 <Typography variant="subtitle2">Días para confirmar asistencia:</Typography>
                                 <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
                                    <InputTextField
                                       size="small"
                                       id="confirmationDays"
                                       name="confirmationDays"
                                       type="text"
                                    />
                                    <Typography variant="caption">días</Typography>
                                 </Box>
                              </Grid>
                              {!shareholderCheck && (
                                 <>
                                    <Grid xs={4} sx={{ px: 2 }}>
                                       <Typography variant="subtitle2">Parámetro de integración:</Typography>
                                       <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
                                          <Typography variant="caption">Mínimo</Typography>
                                          <InputTextField
                                             size="small"
                                             id="integrationMin"
                                             name="integrationMin"
                                             type="text"
                                          />
                                          <Typography variant="caption">Máximo</Typography>
                                          <InputTextField
                                             size="small"
                                             id="integrationMax"
                                             name="integrationMax"
                                             type="text"
                                          />
                                       </Box>
                                    </Grid>
                                    <Grid xs={4} sx={{ px: 2 }}>
                                       <Typography variant="subtitle2">Duración de los cargos:</Typography>
                                       <InputSelectField
                                          size="small"
                                          id="chargesDuration"
                                          name="chargesDuration"
                                          label=""
                                          labelId=""
                                          fullWidth
                                       >
                                          {sessionsPeriods.map((e, index) => (
                                             <MenuItem key={index} value={e}>
                                                {e}
                                             </MenuItem>
                                          ))}
                                       </InputSelectField>
                                    </Grid>
                                    <Grid xs={4} sx={{ px: 2 }}>
                                       <Typography variant="subtitle2">Quórum requerido:</Typography>
                                       <InputTextField size="small" id="quorum" name="quorum" type="text" />
                                    </Grid>
                                    <Grid xs={4} sx={{ px: 2 }}>
                                       <Typography variant="subtitle2">Porcentaje para resoluciones:</Typography>
                                       <InputTextField
                                          size="small"
                                          id="resolutionVotes"
                                          name="resolutionVotes"
                                          type="text"
                                       />
                                    </Grid>
                                    <Grid xs={12} sx={{ px: 2 }}>
                                       <Typography variant="subtitle2">Composición cualitativa:</Typography>
                                       <Box sx={{ columnGap: 1, display: "flex", alignItems: "center" }}>
                                          <Typography variant="caption">Patrimonial</Typography>
                                          <InputTextField
                                             sx={{ mr: 1 }}
                                             size="small"
                                             id="compositionP"
                                             name="compositionP"
                                             type="text"
                                             fullWidth
                                          />
                                          <Typography variant="caption">Relacionado</Typography>
                                          <InputTextField
                                             sx={{ mr: 1 }}
                                             size="small"
                                             id="compositionR"
                                             name="compositionR"
                                             type="text"
                                             fullWidth
                                          />
                                          <Typography variant="caption">Independiente</Typography>
                                          <InputTextField
                                             size="small"
                                             id="compositionI"
                                             name="compositionI"
                                             type="text"
                                             fullWidth
                                          />
                                       </Box>
                                    </Grid>
                                 </>
                              )}
                              <Grid xs={12} sx={{ px: 1 }}>
                                 <Typography variant="body2" fontWeight={600}>
                                    {!shareholderCheck ? "Reglamento del órgano de gobierno" : "Acta constitutiva"}
                                 </Typography>
                              </Grid>
                              <Grid
                                 container
                                 sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: "row",
                                    border: 1,
                                    borderColor: "#E0E0E0",
                                    width: "100%",
                                    mt: 1,
                                    px: 1,
                                    mx: 1,
                                 }}
                              >
                                 {file ? (
                                    <>
                                       <Grid
                                          xs={9}
                                          sx={{
                                             display: "flex",
                                             flexDirection: "row",
                                             alignItems: "center",
                                             columnGap: 2,
                                          }}
                                       >
                                          <IconButton onClick={() => {}}>
                                             <Description />
                                          </IconButton>
                                          <Typography fontSize={12}>{file.name.substring(0, 20)}</Typography>
                                       </Grid>
                                       <Grid
                                          xs={2}
                                          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
                                       >
                                          <Typography fontSize={12}>{formatFileSize(file.size)}</Typography>
                                       </Grid>
                                       <Grid
                                          xs={1}
                                          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
                                       >
                                          <IconButton onClick={() => setOpen(true)}>
                                             <AttachFile />
                                          </IconButton>
                                       </Grid>
                                    </>
                                 ) : (
                                    <>
                                       <Grid xs={9}>
                                          <Typography fontSize={13}>Adjuntar archivo</Typography>
                                       </Grid>
                                       <Grid
                                          xs={3}
                                          sx={{
                                             display: "flex",
                                             flexDirection: "row",
                                             alignItems: "center",
                                             columnGap: 3,
                                          }}
                                       >
                                          <Typography fontSize={13}>Subir nuevo</Typography>
                                          <IconButton onClick={() => setOpen(true)}>
                                             <AttachFile />
                                          </IconButton>
                                       </Grid>
                                    </>
                                 )}
                              </Grid>
                           </>
                        )}
                     </Grid>

                     <Box
                        sx={{
                           display: "flex",
                           justifyContent: "space-between",
                           px: 1,
                           my: 3,
                           height: 50,
                        }}
                     >
                        <Button
                           variant="contained"
                           onClick={() => {
                              setState(false);
                              props.setBodyData({
                                 title: "",
                                 bodyId: "",
                                 structureData: null,
                                 file: null,
                                 functions: "",
                              });
                           }}
                           sx={{
                              color: "black",
                              height: 35,
                              bgcolor: "#E5E6EB",
                              boxShadow: 0,
                              ":hover": { bgcolor: "#E5E6EB", boxShadow: 0 },
                           }}
                        >
                           Cancelar
                        </Button>
                        <Button type="submit" variant="contained" disabled={submitLoading} sx={{ height: 35 }}>
                           {formProps.initialValues === formProps.values ? (
                              "Continuar"
                           ) : !submitLoading ? (
                              "Guardar"
                           ) : (
                              <CircularProgress size={24} />
                           )}
                        </Button>
                     </Box>
                  </Box>
               </Form>
            )}
         </Formik>
         <AddFileModal state={open} setState={setOpen} setFile={setFile} file={file} />
      </>
   );
};
