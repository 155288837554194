import {
   Box,
   Button,
   CircularProgress,
   Stack,
   Typography,
   Checkbox,
   MenuItem,
   ListItemText,
   TextField,
   Select,
   Divider,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { SnackBarContext } from "../../../context/snackBarContext";
import { CustomModalComponent } from "../../CustomModalComponent";
import { deleteGovernRol, getAllResources, updateActiveStatus, updateCorporateData } from "../../../lib/usersBEClient";
import { deleteUserGovernBody, getAllCharges, modifyGovernBodyUser } from "../../../lib/gobCorpBEClient";
import { CorporateDataContext } from "../../../context/governanceContext/corporateDataContext";
import { DeleteForever } from "@mui/icons-material";
import { ConfirmationModal } from "../../ConfirmationModal";
import { UserContext } from "../../../context/userContext";
import ControllingBeneficiaryModal from "./ControllingBeneficiaryModal";
import { BeneficiaryControllerContext } from "../../../context/beneficiaryContext/beneficiaryContollerContext";
import EconomyModal from "./EconomyModal";

interface EditShareholderModalProps {
   state: boolean;
   setState: Function;
   user: any;
   setUser: Function;
}

export const EditShareholderModal = (props: EditShareholderModalProps) => {
   const { showSnackBar } = useContext(SnackBarContext);
   const { companySelected } = useContext(UserContext);
   const { series, governanceBody, updateMembers, corporateData } = useContext(CorporateDataContext);
   const [submitLoading, setSubmitLoading] = useState(false);
   const [isLoading, setIsLoading] = useState(true);
   const [legalR, setLegalR] = useState(false);
   const [controllerB, setControllerB] = useState(false);
   const [effectiveC, setEffectiveC] = useState(false);
   const [rfc, setRfc] = useState("");
   const [businessName, setBusinessName] = useState("");
   const [founder, setFounder] = useState(false);
   const [confirmationModal, setConfirmationModal] = useState(false);
   const [seed, setSeed] = useState(0);
   const [selectedSeries, setSelectedSeries] = useState([]);
   const [isControllingBeneficiaryOpen, setControllingBeneficiaryOpen] = useState(false);
   const [selectedUserId, setSelectedUserId] = useState("");
   const [shareHolderType, setShareHolderType] = useState("");
   const [razonSocial, setRazonSocial] = useState("");
   const { beneficiaries, refetch } = useContext(BeneficiaryControllerContext);
   const [isEconomyModalOpen, setEconomyModalOpen] = useState(false);

   const handleClose = () => {
      setSelectedSeries([]);
      setBusinessName(undefined);
      setLegalR(false);
      setControllerB(false);
      setEffectiveC(false);
      setRfc(undefined);
      setFounder(false);
      setSubmitLoading(false);
      props.setUser(null);
      setIsLoading(true);
   };

   const handleControllingBeneficiaryConfirm = () => {
      refetch();
      setControllingBeneficiaryOpen(false);
      handleClose();
      props.setState(false);
   };

   const handleEconomyModalConfirm = (open) => {
      if (open) {
         window.open("https://psm.economia.gob.mx/PSM/avisoInscripcion.jsf", "gob");
         window.open("https://www.sat.gob.mx/tramites/26216/actualiza-la-informacion-de-socios-o-accionistas.", "sat");
      }
      setEconomyModalOpen(false);
      props.setState(false);
      setControllingBeneficiaryOpen(false);
      handleClose();
   };

   const deleteShareholder = async () => {
      try {
         const resourceResponse = await getAllResources();
         const resourceId = resourceResponse.find((r) => r.name === "Mi Lecosy")._id;
         if (!resourceId) return showSnackBar("Error al eliminado usuario", true);
         const deleteUser = await deleteUserGovernBody(
            governanceBody._id,
            props.user.user,
            companySelected._id,
            resourceId
         );
         await deleteGovernRol(deleteUser.user, deleteUser.charge, companySelected._id, governanceBody._id, true);
         if (props.user.controllerB) await updateActiveStatus(props.user.user, false);
         setSubmitLoading(false);
         props.setState(false);
         updateMembers();
         showSnackBar("Usuario eliminado correctamente", false);
         setEconomyModalOpen(true);
      } catch (error) {
         console.log(error);
         showSnackBar("Error al eliminar usuario", true);
      }
   };

   useEffect(() => {
      if (props.user) {
         setSelectedSeries(props.user.actions);
         setFounder(props.user.founder);
         setLegalR(corporateData.legalRepresentative === `${props.user?.firstName} ${props.user?.lastName}`);
         setControllerB(props.user.controllerB || false);
         setEffectiveC(props.user.effectiveC || false);
         setRfc(props.user.rfc);
         setBusinessName(props.user.businessName);
         setIsLoading(false);
      }
   }, [props.user]);

   //#region Handlers

   const handleChangeRfc = (e) => {
      setRfc(e.target.value);
   };

   const handleChange = () => {
      setFounder(!founder);
   };

   const handleChangeLegal = () => {
      setLegalR(!legalR);
   };

   const handleChangeController = () => {
      setControllerB(!controllerB);
   };

   const handleChangeEffective = () => {
      setEffectiveC(!effectiveC);
   };

   const handleSubmit = async () => {
      if (!props.user) return showSnackBar("Seleccionar usuario existente", true);
      for (const serie of selectedSeries) {
         if (serie.sharesAmount <= 0 || serie.sharesAmount >= 999999999 || !serie.sharesAmount)
            return showSnackBar(`Ingresar cantidad de acciones valida en ${serie.title}`, true);
         if (serie.votes <= 0 || serie.votes >= 999999999 || !serie.votes)
            return showSnackBar(`Ingresar cantidad de votos valida en ${serie.title}`, true);
      }
      try {
         setSubmitLoading(true);
         const response = await getAllCharges();
         const shareholderChargeId = response.find((charge) => charge.chargeName === "Accionista")._id;
         const resourceResponse = await getAllResources();
         const resourceId = resourceResponse.find((r) => r.name === "Mi Lecosy")._id;
         if (!resourceId) return showSnackBar("Error al actualizar accionista", true);

         const userData = {
            user: props.user.user,
            charge: shareholderChargeId,
            founder,
            actions: selectedSeries,
            controllerB,
            effectiveC,
            rfc: rfc,
            businessName: businessName,
         };
         await modifyGovernBodyUser(governanceBody._id, userData, companySelected._id, resourceId);
         updateMembers();

         if (legalR && corporateData) {
            const data = {
               companyId: companySelected._id,
               legalRepresentative: `${props.user.firstName} ${props.user.lastName}`,
            };
            await updateCorporateData(corporateData._id, data);
         }

         setSubmitLoading(false);

         if ((controllerB || effectiveC) && !beneficiaries?.some((u) => u?.user?._id === props.user.user)) {
            setSelectedUserId(props.user.user);
            setShareHolderType(businessName ? "Persona moral" : "Persona física");
            setRfc(rfc);
            setBusinessName(businessName);
            setControllingBeneficiaryOpen(true);
         } else {
            setEconomyModalOpen(true);
         }
      } catch (error) {
         console.log(error);
         showSnackBar("Error al actualizar accionista", true);
      }
   };

   const handleAddSerie = (serie) => {
      setSelectedSeries(
         [...selectedSeries, serie].sort((a, b) => {
            let titleA = a.title.toLowerCase();
            let titleB = b.title.toLowerCase();
            if (titleA < titleB) {
               return -1;
            }
            if (titleA > titleB) {
               return 1;
            }
            return 0;
         })
      );
      setSeed(seed + 1);
   };

   const handleRemoveSerie = (serie) => {
      const tempSeries = selectedSeries;
      const deleteIndex = tempSeries.map((s) => s.title).indexOf(serie.title);
      const newArr = tempSeries.slice(0, deleteIndex).concat(tempSeries.slice(deleteIndex + 1));
      setSelectedSeries(newArr);
      setSeed(seed + 1);
   };

   const handleUpdateSerie = (serie) => {
      const tempSeries = selectedSeries;
      const updateIndex = tempSeries.map((s) => s.title).indexOf(serie.title);
      tempSeries[updateIndex] = serie;
      setSelectedSeries(tempSeries);
      setSeed(seed + 1);
   };

   //#endregion

   return (
      <CustomModalComponent
         open={props.state}
         setOpen={props.setState}
         onClose={handleClose}
         title="Actualización de accionista/socio"
         timeStamp
      >
         {isLoading ? (
            <Box sx={{ display: "flex", bgcolor: "white", justifyContent: "center", p: 5 }}>
               <CircularProgress />
            </Box>
         ) : (
            <Box
               sx={{
                  display: "flex",
                  flexDirection: "column",
                  maxHeight: 500,
                  width: "100%",
                  px: 4,
                  py: 2,
                  rowGap: 2,
                  bgcolor: "white",
                  overflow: "auto",
               }}
            >
               <Box sx={{ display: "flex", columnGap: 8, justifyContent: "space-between" }}>
                  <Stack sx={{ width: "100%", rowGap: 1 }}>
                     <Typography>Nombre accionista/socio:</Typography>
                     <Select
                        value={`${props.user?.firstName} ${props.user?.lastName}`}
                        renderValue={() => `${props.user?.firstName} ${props.user?.lastName}`}
                        size="small"
                        disabled
                     >
                        <MenuItem value={`${props.user?.firstName} ${props.user?.lastName}`}>
                           <ListItemText primary={`${props.user?.firstName} ${props.user?.lastName}`} />
                        </MenuItem>
                     </Select>
                  </Stack>
                  <Box
                     sx={{
                        display: "flex",
                        flexDirection: "column",
                        rowGap: 1,
                        width: 200,
                     }}
                  >
                     <Typography>Fundador:</Typography>
                     <Stack sx={{ flexDirection: "row", justifyContent: "space-evenly", alignItems: "center" }}>
                        <Typography>Sí</Typography>
                        <Checkbox checked={founder} onChange={handleChange} />
                        <Typography>No</Typography>
                        <Checkbox checked={!founder} onChange={handleChange} />
                     </Stack>
                  </Box>
               </Box>
               <Typography>RFC:</Typography>
               <TextField type="text" size="small" placeholder="XXXXXXXXXXXXX" value={rfc} onChange={handleChangeRfc} />
               <Stack spacing={1} sx={{ width: "40%" }}>
                  <Typography>Participación:</Typography>

                  <Select
                     variant="outlined"
                     size={"small"}
                     fullWidth
                     value={selectedSeries ? selectedSeries.map((s) => s?.title) : []}
                     multiple
                     renderValue={() => <Typography sx={{ color: "#969696" }}>Seleccionar serie</Typography>}
                  >
                     {series?.map((serie, i) => (
                        <MenuItem
                           key={i}
                           value={serie.title}
                           onClick={() => {
                              if (!selectedSeries.some((s) => s.title === serie.title)) handleAddSerie(serie);
                              else if (selectedSeries.length > 1) handleRemoveSerie(serie);
                           }}
                        >
                           <Checkbox
                              checked={selectedSeries.some((s) => s.title === serie.title)}
                              sx={{ py: 0, my: 0 }}
                           />
                           <ListItemText primary={serie.title} />
                        </MenuItem>
                     ))}
                  </Select>
               </Stack>
               <Stack spacing={0.5} key={seed}>
                  {selectedSeries.map((serie, i) => (
                     <Box
                        key={i}
                        sx={{
                           display: "flex",
                           columnGap: 2,
                           p: 1,
                           px: 2,
                           borderRadius: 2,
                           bgcolor: "whitesmoke",
                           alignItems: "center",
                           justifyContent: "space-between",
                        }}
                     >
                        <Typography fontWeight={600}>{serie?.title}</Typography>
                        <Divider orientation="vertical" sx={{ height: 30 }} />
                        <Stack
                           sx={{
                              columnGap: 2,
                              flexDirection: "row",
                              alignItems: "center",
                           }}
                        >
                           <Typography fontWeight={600}>Acciones</Typography>

                           <TextField
                              type="number"
                              size={"small"}
                              placeholder="#"
                              defaultValue={selectedSeries[i].sharesAmount}
                              onBlur={(value) => {
                                 handleUpdateSerie({
                                    title: serie.title,
                                    sharesAmount: parseInt(value.target.value),
                                    votes: serie.votes,
                                 });
                              }}
                              sx={{ width: 90 }}
                           />
                        </Stack>
                        <Divider orientation="vertical" sx={{ height: 30 }} />

                        <Stack
                           sx={{
                              columnGap: 2,
                              flexDirection: "row",
                              alignItems: "center",
                           }}
                        >
                           <Typography fontWeight={600}>Votos</Typography>
                           <TextField
                              type="number"
                              size={"small"}
                              placeholder="#"
                              defaultValue={selectedSeries[i].votes}
                              onBlur={(value) => {
                                 handleUpdateSerie({
                                    title: serie.title,
                                    sharesAmount: serie.sharesAmount,
                                    votes: parseInt(value.target.value),
                                 });
                              }}
                              sx={{ width: 90 }}
                           />
                        </Stack>
                     </Box>
                  ))}
               </Stack>
               <Box>
                  <Typography>Asignar como:</Typography>
                  <Box sx={{ display: "flex", columnGap: 3, alignItems: "center" }}>
                     {!businessName && (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                           <Checkbox
                              checked={legalR}
                              onChange={handleChangeLegal}
                              id="legalRepresentative"
                              name="legalRepresentative"
                           />
                           <Typography
                              sx={{
                                 color: "#969696",
                              }}
                           >
                              Apoderado legal
                           </Typography>
                        </Box>
                     )}
                     <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Checkbox
                           checked={controllerB}
                           onChange={handleChangeController}
                           id="beneficiaryController"
                           name="beneficiaryController"
                        />
                        <Typography
                           sx={{
                              color: "#969696",
                           }}
                        >
                           Beneficiario controlador
                        </Typography>
                     </Box>
                     <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Checkbox
                           checked={effectiveC}
                           onChange={handleChangeEffective}
                           id="effectiveControl"
                           name="effectiveControl"
                        />
                        <Typography
                           sx={{
                              color: "#969696",
                           }}
                        >
                           Control efectivo
                        </Typography>
                     </Box>
                  </Box>
               </Box>
            </Box>
         )}

         <Box
            sx={{
               display: "flex",
               justifyContent: "space-between",
               bgcolor: "#F3F3F3",
               width: "100%",
               p: 1,
               height: 50,
               borderBottomRightRadius: 5,
               borderBottomLeftRadius: 5,
            }}
         >
            <Button
               onClick={() => {
                  setConfirmationModal(!confirmationModal);
               }}
               disabled={isLoading || submitLoading}
               sx={{ color: "black", height: 35, ":hover": { bgcolor: "#E5E6EB" }, mr: 2, columnGap: 0.5 }}
            >
               <DeleteForever sx={{ color: "#788190", fontSize: 20 }} />
               <Typography sx={{ color: "#788190", fontSize: 14, fontWeight: "bold" }}>Eliminar</Typography>
            </Button>
            <Box>
               <Button
                  onClick={() => {
                     props.setState(false);
                     handleClose();
                  }}
                  disabled={isLoading || submitLoading}
                  sx={{ color: "black", height: 35, ":hover": { bgcolor: "#E5E6EB" }, mr: 2 }}
               >
                  Cancelar
               </Button>
               <Button
                  onClick={handleSubmit}
                  disabled={isLoading || submitLoading}
                  sx={{ height: 35, ":hover": { bgcolor: "success" } }}
               >
                  {!submitLoading ? "Guardar" : <CircularProgress size={24} />}
               </Button>
            </Box>
         </Box>
         <ConfirmationModal
            open={confirmationModal}
            setOpen={setConfirmationModal}
            onConfirm={deleteShareholder}
            title={"Eliminar accionista/socio"}
            body={
               <>
                  <Typography sx={{ textAlign: "center" }}>
                     {`¿Está seguro que desea eliminar a ${props.user?.firstName} ${props.user?.lastName} de la Asamblea de
                  Accionistas?`}
                  </Typography>
                  <Typography sx={{ textAlign: "center", fontStyle: "italic", fontSize: 12, color: "red" }}>
                     Una vez confirmado, el usuario quedará inhabilitado.
                  </Typography>
               </>
            }
         />

         {isControllingBeneficiaryOpen && (
            <ControllingBeneficiaryModal
               state={isControllingBeneficiaryOpen}
               setState={setControllingBeneficiaryOpen}
               companyId={companySelected._id}
               userId={selectedUserId}
               shareHolderType={shareHolderType}
               rfc={rfc}
               businessName={razonSocial}
               onConfirm={handleControllingBeneficiaryConfirm}
               timeStamp={true}
               beneficiaries={[]}
               control={effectiveC}
            />
         )}

         {isEconomyModalOpen && (
            <EconomyModal
               state={isEconomyModalOpen}
               setState={setEconomyModalOpen}
               onConfirm={handleEconomyModalConfirm}
            />
         )}
      </CustomModalComponent>
   );
};
