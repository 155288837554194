import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { filterCharges } from "../../lib/gobCorpBEClient";
import { getRolesGC } from "../../lib/usersBEClient";

interface GovernData {
   bodyId: string;
   title: string;
}

interface CargoSelectorComponentProps {
   governUsers: any[];
   isLoading: boolean;
   setSelectedRole: Function;
   label: string;
   companyUsers: any[];
   governData: GovernData;
   disabled?: boolean;
   implementationUser?: boolean;
   chargeName?: string;
   disableClearable?: boolean;
}

const useFilterRoles = (governData, governUsers, disabled?, implementationUser?) => {
   const [roles, setRoles] = useState([]);
   const [rolesLoading, setRolesLoading] = useState(false);
   useEffect(() => {
      const getRolesUsers = async () => {
         setRolesLoading(true);
         const gcRoles = await getRolesGC(governData.title);
         const chargesArray = gcRoles.map((c) => c.charges);
         const chargesIds = chargesArray.flat();
         const enableRoles = await filterCharges(governUsers, chargesIds);
         if (implementationUser) {
            const coordRole = enableRoles.filter((r) => r.chargeName === "Coordinador de gobierno corporativo");
            setRoles(coordRole);
         } else {
            setRoles(enableRoles);
         }
         setRolesLoading(false);
      };
      if (!disabled) getRolesUsers();
   }, [governData, governUsers]);

   return { roles, rolesLoading };
};

export const CargoSelectorComponent = (props: CargoSelectorComponentProps) => {
   const { roles, rolesLoading } = useFilterRoles(
      props.governData,
      props.governUsers,
      props.disabled,
      props.implementationUser
   );

   return (
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
         {!props.chargeName ? (
            <Typography variant="body2" fontWeight={600} sx={{ mb: 1 }}>
               {props.label}
            </Typography>
         ) : (
            <Box sx={{ mb: !props.chargeName ? 0 : 1 }} />
         )}
         <Autocomplete
            isOptionEqualToValue={() => true}
            disableClearable={props.disableClearable}
            size="small"
            options={roles || []}
            disabled={props.disabled}
            loading={rolesLoading}
            onChange={(_e, role) => {
               props.setSelectedRole(role);
            }}
            getOptionLabel={(role) => `${role.chargeName}`}
            renderInput={(params) => <TextField {...params} label={props.chargeName} />}
         />
      </Box>
   );
};
