import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/userContext";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { uniq } from "lodash";
import { ClientCreationContext } from "../../../context/officeContexts/clientCreationContext";
import { Companies } from "../../../types/BaseTypes";
import { getUsersByIds } from "../../../lib/usersBEClient";

interface OfficeUserSelectionProps {
   selectedUsers: string[] | string;
   setSelectedUsers: (users: string[] | string) => void;
   multiple?: boolean;
   fullwidth?: boolean;
}

const OfficeUserSelection = ({
   selectedUsers,
   setSelectedUsers,
   multiple = false,
   fullwidth = false,
}: OfficeUserSelectionProps) => {
   const { companies } = useContext(UserContext);
   const { selectedClient } = useContext(ClientCreationContext);
   const [availableUsers, setAvailableUsers] = useState<any[]>([]);

   useEffect(() => {
      const fetchUsers = async () => {
         if (!selectedClient?.data || !companies) return [];

         const currentCompany = selectedClient.data as Companies;
         const ownerCompany = companies.find((c) => c._id === currentCompany.owner);
         const details = ownerCompany?.company_details;

         if (!details) return [];

         const { admin, users } = details;

         const unique = uniq([admin, ...(users || [])].filter(Boolean));
         const data = await getUsersByIds(unique);
         setAvailableUsers(data);
      };

      fetchUsers();
   }, [selectedClient, companies]);

   return (
      <FormControl sx={{ my: 1, minWidth: 120 }} size="small" fullWidth={fullwidth}>
         <InputLabel>Seleccionar usuario</InputLabel>
         <Select
            value={selectedUsers}
            multiple={multiple}
            onChange={(event) => setSelectedUsers(event.target.value)}
            label="Seleccionar usuario"
            sx={{ borderColor: "#E0E0E0" }}
         >
            {availableUsers.map((user) => (
               <MenuItem key={user._id} value={user}>
                  {`${user.firstName} ${user.lastName}`}
               </MenuItem>
            ))}
         </Select>
      </FormControl>
   );
};

export default OfficeUserSelection;
