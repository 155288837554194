import { Box, Grid, MenuItem, Typography } from "@mui/material";
import { InputSelectField } from "../../../../../Inputs/InputSelectField";
import { InputTextField } from "../../../../../Inputs/InputTextField";

interface SocietyKindsComponentProps {
   currentSociety: string;
   currentLegalConcept: string;
   disabled: boolean;
}

const LEGAL_CONCEPTS = {
   civil: ["Sociedad civil", "Asociación civil"],
   mercantile: [
      "Sociedad de responsabilidad limitada",
      "Sociedad anónima",
      "Sociedad anónima promotora de inversión",
      "Sociedad en nombre colectivo",
      "Sociedad en comandita simple",
      "Sociedad en comandita por acciones",
      "Sociedad cooperativa",
      "Sociedad por acciones simplificada",
      "Sociedades de solidaridad social",
      "Otro",
   ],
};

const SOCIETY_KINDS = ["Sociedad mercantil", "Sociedad civil"];

export const SocietyKindsComponent = ({
   currentSociety,
   currentLegalConcept,
   disabled,
}: SocietyKindsComponentProps) => {
   const legalConcepts = currentSociety === "Sociedad mercantil" ? LEGAL_CONCEPTS.mercantile : LEGAL_CONCEPTS.civil;

   return (
      <Grid container gap={1} sx={{ display: "flex" }}>
         <Grid item xs={12}>
            <Typography>Tipo de sociedad</Typography>
            <InputSelectField
               id="societyKind"
               name="societyKind"
               variant="outlined"
               size="small"
               fullWidth
               label=""
               labelId=""
               disabled={disabled}
            >
               {SOCIETY_KINDS.map((type) => (
                  <MenuItem key={type} value={type}>
                     {type}
                  </MenuItem>
               ))}
            </InputSelectField>
         </Grid>

         <Grid item xs={currentSociety === "Sociedad mercantil" && currentLegalConcept === "Otro" ? 3.5 : 12}>
            <Typography>Figura jurídica</Typography>
            <InputSelectField
               id="legalConcept"
               name="legalConcept"
               variant="outlined"
               size="small"
               fullWidth
               disabled={!currentSociety}
               label=""
               labelId=""
            >
               {legalConcepts.map((concept) => (
                  <MenuItem key={concept} value={concept}>
                     {concept}
                  </MenuItem>
               ))}
            </InputSelectField>
         </Grid>
         {currentSociety === "Sociedad mercantil" && currentLegalConcept === "Otro" && (
            <Grid item xs={8} sx={{ mt: 3, ml: 2 }}>
               <InputTextField id="legalConceptOther" name="legalConceptOther" type="string" fullWidth size="small" />
            </Grid>
         )}
      </Grid>
   );
};
