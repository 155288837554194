import { Grid, Stack, Typography } from "@mui/material";
import { useContext } from "react";
import { ExternalGovernanceSessionContext } from "../../../../context/governanceContext/externalSessionContext";
import { getContrastYIQ } from "../../../../const/globalConst";

export const BillCommitteeExternal = () => {
   const { membersWithCharge, colors, externalMemberUsers } = useContext(ExternalGovernanceSessionContext);
   const interUsers = membersWithCharge.filter((member) => member.firstName);

   const chargesOrder = ["presidente", "vicepresidente", "secretario", "vocal", "consultor"];

   const getChargeKey = (chargeNames: string[]) => {
      if (!chargeNames?.length) return;
      const foundChargeName = chargeNames.find((name) => !name.includes("coordinador"));
      if (!foundChargeName) return "consultor";
      const lowerChargeName = foundChargeName.toLowerCase();
      return chargesOrder.find((charge) => lowerChargeName.includes(charge)) || "consultor";
   };

   return (
      <Stack>
         <Grid container rowSpacing={0.4}>
            <Grid container item xs={12} spacing={0.2}>
               <Grid item xs={12} sx={{ bgcolor: colors.primary }}>
                  <Typography
                     sx={{
                        color: getContrastYIQ(colors.primary),
                        textAlign: "center",
                        bgcolor: colors.primary,
                        fontSize: 12,
                        p: 0.2,
                     }}
                  >
                     CONVOCADOS A SESIÓN
                  </Typography>
               </Grid>
            </Grid>
            <Grid container item spacing={0.4}>
               <Grid item xs={6} sx={{ bgcolor: "#EFEFEF", borderLeft: 1, borderTop: 1, borderColor: "white" }}>
                  <Typography
                     sx={{
                        color: "#434343",
                        textAlign: "center",
                        bgcolor: "#EFEFEF",
                        fontSize: 12,
                        fontWeight: 800,
                        p: 0.3,
                     }}
                  >
                     PARTICIPANTES
                  </Typography>
               </Grid>
               <Grid item xs={6} sx={{ bgcolor: "#EFEFEF", borderLeft: 1, borderTop: 1, borderColor: "white" }}>
                  <Typography
                     sx={{
                        color: "#434343",
                        textAlign: "center",
                        bgcolor: "#EFEFEF",
                        fontSize: 12,
                        fontWeight: 800,
                        p: 0.3,
                     }}
                  >
                     CARGO DENTRO DEL COMITÉ
                  </Typography>
               </Grid>
            </Grid>
            {interUsers
               .sort((a, b) => {
                  const aKey = getChargeKey(a.memberCharge);
                  const bKey = getChargeKey(b.memberCharge);
                  return chargesOrder.indexOf(aKey) - chargesOrder.indexOf(bKey);
               })
               .map((member, index) => {
                  if (member.memberCharge.length === 1 && member.memberCharge[0].includes("Coordinador"))
                     return undefined;
                  return (
                     <Grid container item spacing={0.4} sx={{}} key={"Committee" + index}>
                        <Grid
                           item
                           xs={6}
                           sx={{ bgcolor: "#EFEFEF", borderLeft: 1, borderTop: 1, borderColor: "white" }}
                        >
                           <Typography
                              sx={{
                                 color: "#434343",
                                 textAlign: "center",
                                 bgcolor: "#EFEFEF",
                                 fontSize: 12,
                                 p: 0.3,
                              }}
                           >
                              {member.firstName && member.lastName && member.firstName + " " + member.lastName}
                           </Typography>
                        </Grid>
                        <Grid
                           item
                           xs={6}
                           sx={{ bgcolor: "#EFEFEF", borderLeft: 1, borderTop: 1, borderColor: "white" }}
                        >
                           <Typography
                              sx={{
                                 color: "#434343",
                                 textAlign: "center",
                                 bgcolor: "#EFEFEF",
                                 fontSize: 12,
                                 p: 0.3,
                              }}
                           >
                              {member.memberCharge[0]}
                           </Typography>
                        </Grid>
                     </Grid>
                  );
               })}
         </Grid>
         <Grid item container xs={12} spacing={0.2}>
            <Grid item xs={12} sx={{ bgcolor: colors.secondary, pb: 0.5 }}>
               <Typography
                  sx={{
                     color: getContrastYIQ(colors.secondary),
                     textAlign: "center",
                     bgcolor: colors.secondary,
                     fontSize: 12,
                  }}
               >
                  INVITADOS
               </Typography>
            </Grid>
         </Grid>
         <Grid container spacing={0.5}>
            <ExternUsersList usersList={interUsers} intern />
            <ExternUsersList usersList={externalMemberUsers} />
         </Grid>
      </Stack>
   );
};

const ExternUsersList = ({ usersList, intern = false }) =>
   usersList.map(
      (member) =>
         ((intern && member.memberCharge.some((charge) => charge.includes("Coordinador"))) || !intern) && (
            <Grid item container spacing={0.4} key={member?.email}>
               <Grid item xs={6} sx={{ bgcolor: "#EFEFEF", borderLeft: 1, borderColor: "white" }}>
                  <Typography
                     sx={{
                        color: "#434343",
                        textAlign: "center",
                        bgcolor: "#EFEFEF",
                        fontSize: 12,
                        p: 0.3,
                     }}
                  >
                     {intern ? member.firstName + " " + member.lastName : member?.name || member?.email}
                  </Typography>
               </Grid>
               <Grid item xs={6} sx={{ bgcolor: "#EFEFEF", borderLeft: 1, borderColor: "white" }}>
                  <Typography
                     sx={{
                        color: "#434343",
                        textAlign: "center",
                        bgcolor: "#EFEFEF",
                        fontSize: 12,
                        p: 0.3,
                     }}
                  >
                     {intern ? member.memberCharge.slice(-1) : `Usuario Externo - ${member.specialty}`}
                  </Typography>
               </Grid>
            </Grid>
         )
   );
