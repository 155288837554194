import {
   Box,
   Button,
   Card,
   CardActions,
   CardContent,
   CircularProgress,
   IconButton,
   InputBase,
   ListItemIcon,
   ListItemText,
   Menu,
   MenuItem,
   Typography,
} from "@mui/material";
import AttachmentIcon from "@mui/icons-material/Attachment";
import SendIcon from "@mui/icons-material/Send";
import { useContext, useEffect, useRef, useState } from "react";
import { ChatContext } from "../../../../context/governanceContext/ChatContext";
import React from "react";
import UploadIcon from "@mui/icons-material/Upload";
import { ClientTypeModal } from "../../../ClientTypeModal";
import { SelectFile } from "../../Modal/SelectFile";
import DeleteIcon from "@mui/icons-material/Delete";
import { UploadFile } from "../../Modal/UploadFile";
import { VerifyChatFolderByName } from "../../../../lib/usersBEClient";
import { UserContext } from "../../../../context/userContext";
import { AddRounded } from "@mui/icons-material";

export const ChatTextInputComponent = ({ setNewMessage, newMessage, submit }) => {
   const { socket, currentChat, fileUploaded, setFileUploaded, setFileSelected, fileSelected, isUploadingFile } =
      useContext(ChatContext);
   const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
   const [openSelectModal, setOpenSelectModal] = useState(false);
   const [openUploadFile, setOpenUploadFile] = useState(false);
   const inputRef = useRef();
   const open = Boolean(anchorEl);
   const { mutate } = VerifyChatFolderByName();
   const { user } = useContext(UserContext);
   const [chatFolder, setChatFolder] = useState(null);

   const handleKeyDown = (event) => {
      if (event.key === "Enter") {
         event.preventDefault();
         handleSendMessage(event);
      }
   };

   const handleClose = () => {
      setAnchorEl(null);
   };

   const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
   };

   useEffect(() => {
      const fetchChatFolder = async () => {
         mutate(
            {
               name: currentChat.title,
               userId: user.id,
            },
            {
               onError: async (error) => {
                  console.error(error);
               },
               onSuccess: async (data) => {
                  setChatFolder(data);
               },
            }
         );
      };
      if (openUploadFile) fetchChatFolder();
   }, [openUploadFile, mutate, currentChat.title, user.id]);

   const handleSendMessage = (event) => {
      event.preventDefault();
      submit(event);

      if (fileUploaded || fileSelected) {
         socket.emit("new message", {
            file: fileUploaded || fileSelected,
            chatId: currentChat._id,
         });
         setFileUploaded(null);
         setFileSelected(null);
      } else {
         socket.emit("new message", {
            message: newMessage,
            chatId: currentChat._id,
         });
      }

      setNewMessage("");
      socket.emit("stop typing", currentChat);
   };

   return (
      <>
         <Menu
            id="basic-menu"
            elevation={1}
            anchorOrigin={{
               vertical: "top",
               horizontal: "left",
            }}
            transformOrigin={{
               vertical: "bottom",
               horizontal: "left",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
               "aria-labelledby": "basic-button",
            }}
         >
            {/* <MenuItem
               onClick={() => {
                  setOpenSelectModal(true);
                  handleClose();
               }}
            >
               <ListItemIcon>
                  <AttachmentIcon fontSize="small" />
               </ListItemIcon>
               <ListItemText>Adjuntar de mis archivos</ListItemText>
            </MenuItem> */}

            <MenuItem
               onClick={() => {
                  setOpenUploadFile(true);
                  handleClose();
               }}
            >
               <ListItemIcon>
                  <UploadIcon fontSize="small" />
               </ListItemIcon>
               <ListItemText>Subir archivos</ListItemText>
            </MenuItem>
         </Menu>
         {(fileUploaded || fileSelected) && (
            <Card
               sx={{
                  display: "flex",
                  bgcolor: "#F9F9F9",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  my: 0.5,
                  boxShadow: 0,
                  border: 1,
                  borderColor: "#E2E2E2",
                  mx: 1,
                  borderRadius: 2,
               }}
            >
               <CardContent>
                  <Typography sx={{ fontSize: 14, flexWrap: "nowrap" }}>
                     {fileUploaded ? fileUploaded.name : fileSelected && fileSelected.name}
                  </Typography>
               </CardContent>
               <CardActions>
                  <IconButton
                     onClick={() => {
                        setFileUploaded(null);
                        setFileSelected(null);
                     }}
                  >
                     <DeleteIcon sx={{ color: "red" }} />
                  </IconButton>
               </CardActions>
            </Card>
         )}
         <Box sx={{ display: "flex", alignItems: "center", py: 1, px: 2, borderColor: "#DBDBDB" }}>
            <Box
               sx={{
                  display: "flex",
                  alignItems: "center",
                  border: "1px solid #DBDBDB",
                  borderRadius: "16px",
                  padding: "8px",
                  width: "100%",
               }}
            >
               <Box sx={{ borderRight: 1, borderColor: "#DBDBDB", pr: 1, mr: 2 }}>
                  <Button
                     onClick={handleClick}
                     sx={{
                        bgcolor: "#3F5063",
                        "&:hover": { bgcolor: "#152C44" },
                        borderRadius: "50%",
                        minWidth: 0,
                        width: 30,
                        height: 30,
                     }}
                     disabled={currentChat.type === "GROUP"}
                  >
                     <AddRounded sx={{ fontSize: 20, color: "white" }} />
                  </Button>
               </Box>
               <InputBase
                  fullWidth
                  value={newMessage}
                  ref={inputRef}
                  placeholder="Escriba su mensaje aquí..."
                  onKeyDown={handleKeyDown}
                  onChange={(e) => {
                     setNewMessage(e.target.value);
                     if (e.target.value === "") {
                        socket.emit("stop typing", currentChat);
                     } else {
                        socket.emit("typing", currentChat);
                     }
                  }}
               />
               {isUploadingFile ? (
                  <CircularProgress />
               ) : (
                  <IconButton
                     size="small"
                     disabled={isUploadingFile}
                     sx={{
                        bgcolor: "#3F5063",
                        borderRadius: 3,
                        p: 1,
                        px: 1.5,
                        "&:hover": { backgroundColor: "#152C44" },
                        ml: 2,
                     }}
                     onClick={handleSendMessage}
                  >
                     <SendIcon fontSize="small" sx={{ color: "white" }} />
                  </IconButton>
               )}
            </Box>
         </Box>
         <ClientTypeModal
            body={
               <Box>
                  {openSelectModal && (
                     <SelectFile
                        selectedFile={fileSelected}
                        setOpenModal={setOpenSelectModal}
                        reload={"reload"}
                        setReload={"setReload"}
                        handleClose={handleClose}
                        chat
                     />
                  )}
               </Box>
            }
            onConfirm={() => {}}
            open={openSelectModal}
            setOpen={setOpenSelectModal}
            title={
               <Box sx={{ py: 1 }}>
                  <Typography variant="h5" fontWeight={600}>
                     Selecciona un archivo
                  </Typography>
                  <Typography>Selecciona un archivo para enviar</Typography>
               </Box>
            }
            blue
         />
         <ClientTypeModal
            body={
               <UploadFile
                  folder={chatFolder}
                  setReload={undefined}
                  reload={undefined}
                  setSelectedFile={setFileUploaded}
                  setCloseModal={setOpenUploadFile}
                  setOpenModalFile={() => {}}
                  chat={true}
               />
            }
            onConfirm={() => {
               setOpenUploadFile(false);
            }}
            open={openUploadFile}
            setOpen={setOpenUploadFile}
            title={
               <Box sx={{ py: 1 }}>
                  <Typography variant="h5" fontWeight={600}>
                     Selecciona un archivo
                  </Typography>
                  <Typography>Selecciona el archivo que quieres subir</Typography>
               </Box>
            }
            blue
         />
      </>
   );
};
