import { useState, useEffect, useContext } from "react";
import { Box, Stack, CircularProgress, Tooltip } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { getDateFromISODate } from "../../const/globalConst";
import ErrorIcon from "@mui/icons-material/Error";
import { UserContext } from "../../context/userContext";
import { UseLastCompanyInvoice, findStatusService } from "../../lib/usersBEClient";

interface ServiceStatusComponentProps {
   company?: any;
}

export const ServiceStatusComponent = (props: ServiceStatusComponentProps) => {
   const [isActive, setIsActive] = useState(false);
   const [isDueSoon, setIsDueSoon] = useState(false);
   const { user } = useContext(UserContext);
   const [isLoading, setIsLoading] = useState<boolean>(true);
   const [invoiceData, setInvoiceData] = useState(null);

   const fetchData = async () => {
      setIsLoading(true);
      if (user.group) {
         if (user.group.invoiceCompany) {
            const data = await UseLastCompanyInvoice(user.group.invoiceCompany);
            setInvoiceData(data);
            const endPeriodDate = new Date(data.endPeriod);
            const currentDate = new Date();
            const nextDueDate = new Date(data.endPeriod);
            nextDueDate.setDate(nextDueDate.getDate() + 4);

            if (currentDate.getTime() <= endPeriodDate.getTime()) {
               setIsActive(true);
            } else {
               if (currentDate.getTime() < nextDueDate.getTime()) {
                  setIsActive(true);
                  setIsDueSoon(true);
               } else {
                  setIsActive(false);
                  setIsDueSoon(false);
               }
            }
         } else {
            if (props.company?.company_details === null) return;
            const status = await findStatusService(props.company?.company_details._id);
            if (status) setIsActive(true);
         }
      } else {
         if (!props.company) return;
         const data = await UseLastCompanyInvoice(props.company ? props.company._id : null);
         setInvoiceData(data);
         const endPeriodDate = new Date(data.endPeriod);
         const currentDate = new Date();
         const nextDueDate = new Date(data.endPeriod);
         nextDueDate.setDate(nextDueDate.getDate() + 4);

         if (currentDate.getTime() <= endPeriodDate.getTime()) {
            setIsActive(true);
         } else {
            if (currentDate.getTime() < nextDueDate.getTime()) {
               setIsActive(true);
               setIsDueSoon(true);
            } else {
               setIsActive(false);
               setIsDueSoon(false);
            }
         }
      }
   };

   useEffect(() => {
      fetchData();
      setIsLoading(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [user, props.company]);

   return (
      <>
         <Box p={1}>
            {user.group ? (
               !isLoading ? (
                  <Stack display={"flex"} justifyContent={"center"} p={4} alignItems={"center"} textAlign={"center"}>
                     {invoiceData && (
                        <>
                           <Box>Fecha de corte:</Box>
                           <Box>
                              {user &&
                                 user.group.invoiceCompany &&
                                 invoiceData &&
                                 invoiceData.endPeriod &&
                                 getDateFromISODate(invoiceData.endPeriod)}
                           </Box>
                        </>
                     )}
                     <Box>
                        {isActive && isDueSoon ? (
                           <ErrorIcon sx={{ width: "35px", height: "35px", color: "#daf202" }} />
                        ) : isActive && isDueSoon === false ? (
                           <CheckCircleIcon sx={{ width: "35px", height: "35px", color: "#0FA958" }} />
                        ) : (
                           <CancelIcon sx={{ width: "35px", height: "35px", color: "#f70a0a" }} />
                        )}
                     </Box>
                     <Box sx={{ maxWidth: "250px" }}>
                        {isActive && isDueSoon
                           ? "Fecha límite de pago proxima"
                           : isActive && isDueSoon === false
                           ? "El servicio se encuentra actualmente habilitado"
                           : "El servicio se encuentra actualmente inhabilitado"}
                     </Box>
                  </Stack>
               ) : (
                  <CircularProgress />
               )
            ) : !isLoading ? (
               <Stack display={"flex"} justifyContent={"center"} p={4} alignItems={"center"} textAlign={"center"}>
                  {invoiceData && (
                     <>
                        <Box>Fecha de corte:</Box>
                        <Box>{invoiceData && getDateFromISODate(invoiceData.endPeriod)}</Box>
                     </>
                  )}
                  <Box>
                     {isActive && isDueSoon ? (
                        <ErrorIcon sx={{ width: "35px", height: "35px", color: "#daf202" }} />
                     ) : isActive && isDueSoon === false ? (
                        <CheckCircleIcon sx={{ width: "35px", height: "35px", color: "#0FA958" }} />
                     ) : (
                        <CancelIcon sx={{ width: "35px", height: "35px", color: "#f70a0a" }} />
                     )}
                  </Box>
                  <Box sx={{ maxWidth: "250px" }}>
                     {isActive && isDueSoon
                        ? "Fecha límite de pago proxima"
                        : isActive && isDueSoon === false
                        ? "El servicio se encuentra actualmente habilitado"
                        : "El servicio se encuentra actualmente inhabilitado"}
                  </Box>
               </Stack>
            ) : (
               <CircularProgress />
            )}
         </Box>
      </>
   );
};
