import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
   Box,
   Button,
   Grid,
   Typography,
   CircularProgress,
   FormControlLabel,
   Checkbox,
   Divider,
   Container,
   List,
   ListItem,
   ListItemText,
} from "@mui/material";
import { capitalizeFirstLetter, getDateAndTimeFromISODate, toISOString } from "../../../const/globalConst";
import { NotificationDocumentsBar } from "../../../components/notificationsDocumentsBar";
import { recordClousureTemplate } from "../../../lib/documentsTemplates/acknowledgmentTemplates";
import { createDocumentPdf } from "../../../lib/uploadDocuments";
import { Form, Formik } from "formik";
import { InputTextField } from "../../../components/Inputs/InputTextField";
import { recordClousureSchema } from "../../../lib/validations/inputSchemas";
import { ComplaintFileRecord } from "../../../components/ComplaintInfo/ComplaintFileRecord";
import { SnackBarContext } from "../../../context/snackBarContext";
import pdfMake from "../../../const/pdfMakeFonts";
import { ComplaintStepperContext } from "../../../context/complaintStepperContext";
import { Accordion, AccordionDetails, AccordionSummary } from "../../../components/ComplainantAndWitnessDetailsModal";
import EvidenceTable from "../../../components/Evidences/EvidenceTable";
import PersonIcon from "@mui/icons-material/Person";
import { getUrlForDocumentsList } from "../../../lib/usersBEClient";
import UseGetDocumentationList from "../../../hooks/complaints/getDocumentList";

export const RecordClosureScreen = () => {
   const { complaint } = useContext(ComplaintStepperContext);
   const [isLoadingTemplate, setIsLoadingTemplate] = useState<boolean>(false);
   const { showSnackBar } = useContext(SnackBarContext);
   const [expanded, setExpanded] = useState<string | false>("panel0");
   const [complainer, setComplainer] = useState<any>();
   const [witnesses, setWitnesses] = useState<any[]>([]);
   const [offenders, setOffenders] = useState<any[]>([]);
   const navigate = useNavigate();
   const date = new Date();

   useEffect(() => {
      const fetchWitnesses = async () => {
         const witnessesFound: any[] = [];
         for (const witness of complaint.witnesses) {
            const documents = await fetchedEvidences(witness, "Testigo");
            witnessesFound.push({ witnessInfo: witness, documents });
         }
         return setWitnesses(witnessesFound);
      };

      const fetchOffenders = async () => {
         const offendersFound: any[] = [];
         for (const offender of complaint.offenderDetails) {
            const documents = await fetchedEvidences(offender, "Denunciado");
            offendersFound.push({ offenderInfo: offender, documents });
         }
         return setOffenders(offendersFound);
      };

      const fetchComplainerEvidences = async () => {
         const documents = await fetchedEvidences(
            complaint.complaintType === "anonima" ? undefined : complaint.complainerDetails,
            "Denunciante"
         );
         setComplainer({ complainerInfo: complaint.complainerDetails, documents });
      };

      const fetchedEvidences = async (selected, type: string) => {
         const involvedEvidences = await getUrlForDocumentsList("files-lecosy", {
            folder: selected?.name
               ? `evidences/${complaint.reportNumber}/${type}/${selected._id}`
               : `evidences/${complaint.reportNumber}/${type}/anonimo`,
         });
         const files: string[] = [];
         if (involvedEvidences?.Contents) {
            for (const file of involvedEvidences.Contents) {
               files.push(file.Key.split("/").slice(-1));
            }
         }
         return files;
      };
      fetchWitnesses();
      fetchOffenders();
      fetchComplainerEvidences();
   }, [complaint]);

   const [lecosyInputs, setLecosyInputs] = useState({
      interpositionFormat: false,
      offenderNotification: false,
      offenderInterviewNotification: false,
      witnessInterviewNotification: false,
      complainterInterviewNotification: false,
      resolutionCheck: false,
      endingAccuse: false,
      extraDocument: false,
      offenderAnswer: "No",
      digitalEvidence: false,
      pdfEvidence: false,
      pictures: false,
      voiceMails: false,
      sanctionMeasures: false,
      disciplinaryMeasures: false,
      preventionMeasures: false,
      dateTime: date,
   });

   const handleCheckChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
      setLecosyInputs({
         ...lecosyInputs,
         [event.target.name]: event.target.checked,
      });
   };

   const handleSendForm = async (values, actions) => {
      setIsLoadingTemplate(true);
      const measures: string[] = [];
      if (lecosyInputs.sanctionMeasures) {
         measures.push("Medidas sancionadoras");
      }
      if (lecosyInputs.disciplinaryMeasures) {
         measures.push("Medidas disciplinarias");
      }
      if (lecosyInputs.preventionMeasures) {
         measures.push("Medidas de prevención");
      }
      const necesaryDocuments = complaint.documentation.find(
         (doc) => doc.name === `cierre-${complaint.reportNumber}.pdf`
      );
      if (!necesaryDocuments) {
         const dd = recordClousureTemplate(
            complaint.reportNumber,
            complaint.complainerDetails
               ? complaint.complainerDetails.name + " " + complaint.complainerDetails.lastName
               : `${complaint.type || "denuncia" ? "Quejoso" : "Denunciante"} anonimo`,
            complaint.complainerDetails ? complaint.complainerDetails.address + " " : "No aplica",
            complaint.complainerType,
            complaint.directlyAffected,
            complaint.complainerDetails ? complaint.complainerDetails.phoneNumber + " " : "No aplica",
            complaint.complainerDetails ? complaint.complainerDetails.email + " " : "No aplica",
            complaint.complaintType === "anonima",
            complaint.clasification,
            complaint.witnesses,
            complaint.date || complaint.createdAt,
            complaint.specificPlace,
            complaint.body,
            complaint.status,
            complaint.observations,
            complaint.clasification,
            complaint.createdAt ? complaint.createdAt : "",
            values.investigationProcess,
            complaint.documentation,
            complaint.documentation.some(
               (doc) =>
                  doc.name === `replica-${complaint.reportNumber}-1.pdf` ||
                  doc.name === `replica-${complaint.reportNumber}-2.pdf`
            ),
            complainer,
            offenders,
            witnesses,
            values.evidences,
            complaint.newClasification,
            measures,
            values.measures,
            complaint.offenderDetails,
            complaint.type
         );

         const pdfDocGenerator = pdfMake.createPdf(dd);
         pdfDocGenerator.getBlob(async (blob) => {
            await createDocumentPdf(complaint.reportNumber, `cierre-${complaint.reportNumber}`, blob);
            showSnackBar("Documento creado y subido correctamente.", false);
            actions.resetForm();
            setTimeout(() => navigate(-1), 1000);
         });
         setIsLoadingTemplate(false);
      } else {
         showSnackBar("Este documento ya fue enviado.", true);
         setIsLoadingTemplate(false);
         setTimeout(() => navigate(-1), 1000);
      }
   };

   const noDeleteHeaders = [
      { field: "file", headerName: "Evidencia" },
      { field: "icon", headerName: "Ver" },
   ];

   const handleChangeAccordion = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
   };

   const accordionData = (data: any, i: number, type: string) => {
      const documentList = UseGetDocumentationList({
         selected: data,
         subject: type,
         reportNumber: complaint.reportNumber,
      });
      return (
         <Accordion
            key={`${data.name} Involved ${i}`}
            expanded={expanded === `panel${data._id ? data._id : "1"}`}
            onChange={handleChangeAccordion(`panel${data._id ? data._id : "1"}`)}
            sx={{ borderRadius: 0 }}
         >
            <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
               <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ display: "flex", flex: 1 }}>
                     <PersonIcon sx={{ mr: 1 }} />
                     <Typography fontWeight={600} fontSize={17}>
                        {data.name !== ""
                           ? `${data.name}  ${data.lastName}`
                           : complaint.complaintType === "anonima"
                           ? `${complaint.type || "denuncia" ? "Interpositor" : "Denunciante"} anónimo`
                           : ` ${type + (i + 1)}`}
                     </Typography>
                  </Box>
               </Box>
            </AccordionSummary>
            <AccordionDetails>
               <Box
                  sx={{
                     display: "flex",
                     flexDirection: "column",
                  }}
               >
                  <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
                     <Box
                        sx={{
                           flex: 1,
                           justifyContent: "center",
                           display: "flex",
                           alignItems: "center",
                        }}
                     >
                        {documentList.length > 0 ? (
                           <EvidenceTable
                              rows={documentList}
                              headers={noDeleteHeaders}
                              reportNumber={complaint.reportNumber}
                              subject={type}
                              selectedUser={data._id}
                           />
                        ) : (
                           <Typography>Esta persona no tiene evidencias</Typography>
                        )}
                     </Box>
                  </Box>
               </Box>
            </AccordionDetails>
         </Accordion>
      );
   };

   return (
      <Box p={2}>
         <NotificationDocumentsBar head="Cierre de expediente" isScheduleAppointment={false} />
         <Container maxWidth="xl">
            <Formik
               initialValues={{
                  investigationProcess: "",
                  measures: "",
                  evidences: "",
                  dateTime: toISOString(date),
               }}
               validationSchema={recordClousureSchema}
               onSubmit={handleSendForm}
            >
               <Form>
                  <Box
                     display={"flex"}
                     sx={{ px: 3, py: 2, minWidth: 950, flexDirection: "column", alignItems: "center", m: 2 }}
                  >
                     <Box
                        sx={{ width: "100%", bgcolor: "#FFFFFF", borderRadius: 2, border: 1, borderColor: "#8A95A0" }}
                     >
                        <Grid container>
                           <Grid item xs={3}>
                              <Box display={"flex"} sx={dataFieldSX}>
                                 <Typography fontWeight={600} variant="body1">
                                    {`Reporte de ${capitalizeFirstLetter(complaint.type || "denuncia")}`}
                                 </Typography>
                                 <Typography variant="subtitle1">{complaint.reportNumber}</Typography>
                              </Box>
                           </Grid>
                           <Grid item xs={3}>
                              <Box display={"flex"} sx={dataFieldSX}>
                                 <Typography fontWeight={600} variant="body1">
                                    {`${capitalizeFirstLetter(complaint.type || "denuncia")} anónima`}
                                 </Typography>
                                 <Typography variant="subtitle1">
                                    {complaint.complaintType === "anonima" ? "Si" : "No"}
                                 </Typography>
                              </Box>
                           </Grid>
                           <Grid item xs={3}>
                              <Box display={"flex"} sx={dataFieldSX}>
                                 <Typography fontWeight={600} variant="body1">
                                    Clasificación
                                 </Typography>
                                 <Typography textAlign={"center"} variant="subtitle1">
                                    {complaint.clasification || "No aplica"}
                                 </Typography>
                              </Box>
                           </Grid>
                           <Grid item xs={3} sx={{ display: "flex", alignItems: "center", px: 1 }}>
                              <InputTextField
                                 variant="standard"
                                 fullWidth
                                 name="dateTime"
                                 id="dateTime"
                                 label="Fecha y hora"
                                 type="datetime-local"
                                 InputLabelProps={{
                                    shrink: true,
                                 }}
                              />
                           </Grid>
                        </Grid>
                        <Divider sx={{ mx: 2 }} />
                        <Grid container>
                           <Grid item xs={12} sx={{ display: "flex", p: 2 }}>
                              <Typography color={"#2d4357"} fontWeight={700} variant="h6">
                                 {`Datos ${complaint.type || "denuncia" ? "del interpositor" : "del denunciante"}`}
                              </Typography>
                           </Grid>
                           <Grid item xs={4}>
                              <Box display={"flex"} sx={dataFieldSX}>
                                 <Typography fontWeight={600} variant="body1" sx={{ textAlign: "center" }}>
                                    {`Tipo de ${
                                       (complaint.type || "denuncia") !== "denuncia" ? "interpositor" : "denunciante"
                                    }`}
                                 </Typography>
                                 <Typography>{complaint.complainerType}</Typography>
                              </Box>
                           </Grid>
                           <Grid item xs={4}>
                              <Box display={"flex"} sx={dataFieldSX}>
                                 <Typography fontWeight={600} variant="body1" sx={{ textAlign: "center" }}>
                                    Se le afectó directamente
                                 </Typography>
                                 <Typography>{complaint.directlyAffected ? "Sí" : "No"}</Typography>
                              </Box>
                           </Grid>
                           <Grid item xs={4}>
                              <Box display={"flex"} sx={dataFieldSX}>
                                 <Typography fontWeight={600} variant="body1" sx={{ textAlign: "center" }}>
                                    Número telefónico
                                 </Typography>
                                 <Typography variant="subtitle1">
                                    {complaint.complainerDetails
                                       ? complaint.complainerDetails.phoneNumber
                                       : "6325794510"}
                                 </Typography>
                              </Box>
                           </Grid>
                           <Grid item xs={4}>
                              <Box display={"flex"} sx={dataFieldSX}>
                                 <Typography fontWeight={600} variant="body1" sx={{ textAlign: "center" }}>
                                    Nombre
                                 </Typography>
                                 <Typography variant="subtitle1">
                                    {complaint.complainerDetails
                                       ? complaint.complainerDetails.name + " " + complaint.complainerDetails.lastName
                                       : "No aplica"}
                                 </Typography>
                              </Box>
                           </Grid>
                           <Grid item xs={4}>
                              <Box display={"flex"} sx={dataFieldSX}>
                                 <Typography fontWeight={600} variant="body1" sx={{ textAlign: "center" }}>
                                    Correo electrónico
                                 </Typography>
                                 <Typography variant="subtitle1">
                                    {complaint.complainerDetails ? complaint.complainerDetails.email : "No aplica"}
                                 </Typography>
                              </Box>
                           </Grid>
                           <Grid item xs={4}>
                              <Box display={"flex"} sx={dataFieldSX}>
                                 <Typography fontWeight={600} variant="body1" sx={{ textAlign: "center" }}>
                                    Domicilio
                                 </Typography>
                                 <Typography variant="subtitle1">
                                    {complaint.complainerDetails ? complaint.complainerDetails.address : "No aplica"}
                                 </Typography>
                              </Box>
                           </Grid>
                        </Grid>
                        <Divider sx={{ mx: 2 }} />
                        <Grid container>
                           {(complaint.type || "denuncia") === "denuncia" ? (
                              <Grid item xs={12} sx={{ display: "flex", p: 2 }}>
                                 <Typography color={"#2d4357"} fontWeight={700} variant="h6">
                                    {complaint.offenderDetails.length > 1
                                       ? "Datos de los denunciados"
                                       : "Datos del denunciado"}
                                 </Typography>
                              </Grid>
                           ) : (
                              <></>
                           )}
                           {complaint.offenderDetails.map((e) => {
                              return (
                                 <>
                                    <Grid item xs={4}>
                                       <Box display={"flex"} sx={dataFieldSX}>
                                          <Typography fontWeight={600} variant="body1" sx={{ textAlign: "center" }}>
                                             Nombre
                                          </Typography>
                                          <Typography variant="subtitle1">{e.name + " " + e.lastName}</Typography>
                                       </Box>
                                    </Grid>
                                    <Grid item xs={4}>
                                       <Box display={"flex"} sx={dataFieldSX}>
                                          <Typography fontWeight={600} variant="body1" sx={{ textAlign: "center" }}>
                                             Puesto
                                          </Typography>
                                          <Typography variant="subtitle1">{e.position}</Typography>
                                       </Box>
                                    </Grid>
                                    <Grid item xs={4}>
                                       <Box display={"flex"} sx={dataFieldSX}>
                                          <Typography fontWeight={600} variant="body1">
                                             Género
                                          </Typography>
                                          <Typography>{e.gender}</Typography>
                                       </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                       <Box
                                          display={"flex"}
                                          sx={{
                                             alignItems: "center",
                                             flexDirection: "column",
                                             py: 2,
                                             px: 2,
                                          }}
                                       >
                                          <Typography fontWeight={600} variant="body1" sx={{ textAlign: "right" }}>
                                             Observaciones
                                          </Typography>
                                          <Typography variant="subtitle1">{e.observations}</Typography>
                                       </Box>
                                    </Grid>
                                 </>
                              );
                           })}
                        </Grid>
                        <Divider sx={{ mx: 2 }} />
                        {(complaint.type || "denuncia") === "denuncia" ? (
                           <Grid container>
                              <Grid item xs={12} sx={{ display: "flex", p: 2 }}>
                                 <Typography color={"#2d4357"} fontWeight={700} variant="h6">
                                    {complaint.witnesses.length > 1 ? "Datos de los testigos" : "Datos del testigo"}
                                 </Typography>
                              </Grid>
                              {complaint.witnesses.map((e) => {
                                 return (
                                    <>
                                       <Grid item xs={4}>
                                          <Box display={"flex"} sx={dataFieldSX}>
                                             <Typography fontWeight={600} variant="body1" sx={{ textAlign: "center" }}>
                                                Nombre
                                             </Typography>
                                             <Typography variant="subtitle1">{e.name + " " + e.lastName}</Typography>
                                          </Box>
                                       </Grid>
                                       <Grid item xs={4}>
                                          <Box display={"flex"} sx={dataFieldSX}>
                                             <Typography fontWeight={600} variant="body1" sx={{ textAlign: "center" }}>
                                                Puesto
                                             </Typography>
                                             <Typography variant="subtitle1">{e.position}</Typography>
                                          </Box>
                                       </Grid>
                                       <Grid item xs={4}>
                                          <Box display={"flex"} sx={dataFieldSX}>
                                             <Typography fontWeight={600} variant="body1">
                                                Género
                                             </Typography>
                                             <Typography>{e.gender}</Typography>
                                          </Box>
                                       </Grid>
                                       <Grid item xs={12}>
                                          <Box
                                             display={"flex"}
                                             sx={{
                                                alignItems: "center",
                                                flexDirection: "column",
                                                py: 2,
                                                px: 2,
                                             }}
                                          >
                                             <Typography fontWeight={600} variant="body1" sx={{ textAlign: "right" }}>
                                                Observaciones
                                             </Typography>
                                             <Typography variant="subtitle1">{e.observations}</Typography>
                                          </Box>
                                       </Grid>
                                    </>
                                 );
                              })}
                           </Grid>
                        ) : (
                           <></>
                        )}
                        <Divider sx={{ mx: 2 }} />
                        <Grid container sx={{ display: "flex", justifyContent: "center" }}>
                           <Grid item xs={12} sx={{ display: "flex", p: 2 }}>
                              <Typography color={"#2d4357"} fontWeight={700} variant="h6">
                                 {`Datos de la ${complaint.type || "denuncia"}`}
                              </Typography>
                           </Grid>
                           <Grid item xs={6}>
                              <Box display={"flex"} sx={dataFieldSX}>
                                 <Typography fontWeight={600} variant="body1" sx={{ textAlign: "center" }}>
                                    Fecha del acto
                                 </Typography>
                                 <Typography variant="subtitle1">
                                    {getDateAndTimeFromISODate(complaint.createdAt)}
                                 </Typography>
                              </Box>
                           </Grid>
                           <Grid item xs={6}>
                              <Box display={"flex"} sx={dataFieldSX}>
                                 <Typography fontWeight={600} variant="body1" sx={{ textAlign: "center" }}>
                                    Donde sucedieron los hechos
                                 </Typography>
                                 <Typography variant="subtitle1">{complaint.specificPlace || "No aplica"}</Typography>
                              </Box>
                           </Grid>
                           <Grid item xs={12}>
                              <Box
                                 display={"flex"}
                                 sx={{ display: "flex", flexDirection: "column", px: 12, alignItems: "center" }}
                              >
                                 <Typography fontWeight={600} variant="body1" sx={{ pb: 1, alignText: "center" }}>
                                    {`Descripción de la ${complaint.type || "denuncia"}`}
                                 </Typography>
                                 <Typography variant="subtitle1" textAlign={"justify"}>
                                    {complaint.body}
                                 </Typography>
                              </Box>
                           </Grid>
                        </Grid>
                        <Divider sx={{ mx: 2 }} />
                        <Grid container>
                           <Grid item xs={12} sx={{ display: "flex", p: 2 }}>
                              <Typography color={"#2d4357"} fontWeight={700} variant="h6">
                                 Reporte Lecosy
                              </Typography>
                           </Grid>
                           <Grid item xs={4}>
                              <Box display={"flex"} sx={dataFieldSX}>
                                 <Typography fontWeight={600} variant="body1" sx={{ textAlign: "center" }}>
                                    {`Estatus de la ${complaint.type || "denuncia"}`}
                                 </Typography>
                                 <Typography variant="subtitle1">{complaint.status}</Typography>
                              </Box>
                           </Grid>
                           <Grid item xs={4}>
                              <Box display={"flex"} sx={dataFieldSX}>
                                 <Typography fontWeight={600} variant="body1" sx={{ textAlign: "center" }}>
                                    {`Fecha de interposición de la ${complaint.type || "denuncia"}`}
                                 </Typography>
                                 <Typography variant="subtitle1">
                                    {getDateAndTimeFromISODate(complaint.createdAt)}
                                 </Typography>
                              </Box>
                           </Grid>
                           <Grid item xs={4}>
                              <Box display={"flex"} sx={dataFieldSX}>
                                 <Typography fontWeight={600} variant="body1" sx={{ textAlign: "center" }}>
                                    Clasificación del hecho
                                 </Typography>
                                 <Typography textAlign={"center"} variant="subtitle1">
                                    {complaint.clasification || "No aplica"}
                                 </Typography>
                              </Box>
                           </Grid>
                           <Grid item xs={12}>
                              <Box
                                 display={"flex"}
                                 sx={{ display: "flex", flexDirection: "column", px: 12, pb: 1, alignItems: "center" }}
                              >
                                 <Typography fontWeight={600} variant="body1" sx={{ pb: 1 }}>
                                    Observaciones
                                 </Typography>
                                 <Typography variant="subtitle1">
                                    {complaint.observations ? `${complaint.observations}` : "No aplica"}
                                 </Typography>
                              </Box>
                           </Grid>
                        </Grid>
                        <Divider sx={{ mx: 2 }} />
                        <Grid container sx={{ display: "flex", justifyContent: "center", py: 2 }}>
                           <Grid item xs={12} sx={{ display: "flex", p: 2 }}>
                              <Typography color={"#2d4357"} fontWeight={700} variant="h6">
                                 {`Proceso de investigación de la ${complaint.type || "denuncia"}`}
                              </Typography>
                           </Grid>
                           <Grid item xs={10}>
                              <Box display={"flex"} sx={dataFieldSX}>
                                 <InputTextField
                                    id="investigationProcess"
                                    name="investigationProcess"
                                    type="text"
                                    variant="standard"
                                    label="Investigación"
                                    fullWidth={true}
                                    multiline={true}
                                    sx={{ mt: 1 }}
                                 />
                              </Box>
                           </Grid>
                        </Grid>
                        <Divider sx={{ mx: 2 }} />
                        <Grid container sx={{ display: "flex", justifyContent: "center", py: 2 }}>
                           <Grid item xs={12} sx={{ display: "flex", p: 2 }}>
                              <Typography color={"#2d4357"} fontWeight={700} variant="h6">
                                 Documentación
                              </Typography>
                           </Grid>
                           <Grid item xs={12}>
                              <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column", p: 4 }}>
                                 <ComplaintFileRecord includeStepper={false} clousure={true} />
                              </Box>
                           </Grid>
                           <Grid
                              item
                              xs={12}
                              sx={{
                                 display: "flex",
                                 justifyContent: "center",
                                 alignItems: "center",
                                 flexDirection: "column",
                                 py: 4,
                              }}
                           >
                              <Typography sx={{ px: 2 }}>
                                 {`Existió respuesta/uso de réplica por el ${
                                    (complaint.type || "denuncia") !== "denuncia" ? "quejoso" : "denunciado"
                                 }/s:`}
                              </Typography>
                              <List dense={true}>
                                 {complaint.offenderDetails.map((ev, i) => {
                                    return (
                                       <ListItem
                                          key={`${ev._id}-${ev.name}`}
                                          secondaryAction={
                                             <Checkbox
                                                disabled
                                                checked={complaint.documentation.some(
                                                   (doc) =>
                                                      doc.name === `replica-${complaint.reportNumber}-${i + 1}.pdf`
                                                )}
                                             />
                                          }
                                       >
                                          <ListItemText primary={ev.name + " " + ev.lastName} sx={{ p: 2 }} />
                                       </ListItem>
                                    );
                                 })}
                              </List>
                           </Grid>
                           <Grid container>
                              <Grid item xs={12} sx={{ display: "flex", p: 2 }}>
                                 <Typography color={"#2d4357"} fontWeight={700} variant="h6">
                                    Evidencias
                                 </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                 <Box sx={{ display: "flex", justifyContent: "center", px: 6 }}>
                                    <Box sx={{ display: "flex", flexDirection: "column", flex: 1, gap: 2 }}>
                                       <>
                                          <Typography variant="h6" align="center">
                                             {`${
                                                (complaint.type || "denuncia") !== "denuncia"
                                                   ? "Interpositor"
                                                   : "Denunciante"
                                             }`}
                                          </Typography>
                                          {complaint.complaintType !== "no anonima"
                                             ? accordionData(
                                                  { name: "", evidence: complaint.anonimousEvidence },
                                                  0,
                                                  "Denunciante"
                                               )
                                             : accordionData(complaint.complainerDetails, 0, "Denunciante")}
                                       </>
                                       {complaint.type === "denuncia" && (
                                          <>
                                             <Typography variant="h6" align="center">
                                                Denunciados
                                             </Typography>
                                             {complaint.offenderDetails.length > 0 ? (
                                                complaint.offenderDetails.map((offender, i) =>
                                                   accordionData(offender, i, "Denunciado")
                                                )
                                             ) : (
                                                <Typography
                                                   variant="body1"
                                                   sx={{ p: 1, border: 1, borderColor: "#DCDCDC" }}
                                                >
                                                   No existen denunciados
                                                </Typography>
                                             )}
                                          </>
                                       )}
                                       {complaint.type === "denuncia" && (
                                          <>
                                             <Typography variant="h6" align="center">
                                                Testigos
                                             </Typography>
                                             {complaint.witnesses.length > 0 ? (
                                                complaint.witnesses.map((witness, i) =>
                                                   accordionData(witness, i, "Testigo")
                                                )
                                             ) : (
                                                <Typography
                                                   variant="body1"
                                                   sx={{ p: 1, border: 1, borderColor: "#DCDCDC" }}
                                                >
                                                   No existen testigos
                                                </Typography>
                                             )}
                                          </>
                                       )}
                                    </Box>
                                    {/* )} */}
                                 </Box>
                              </Grid>
                              <Grid container sx={{ display: "flex", justifyContent: "center", py: 1 }}>
                                 <Grid item xs={10}>
                                    <Box display={"flex"} sx={dataFieldSX}>
                                       <InputTextField
                                          id="evidences"
                                          name="evidences"
                                          type="text"
                                          variant="standard"
                                          label="Observaciones"
                                          fullWidth={true}
                                          multiline={true}
                                          sx={{ mt: 1 }}
                                       />
                                    </Box>
                                 </Grid>
                              </Grid>
                              {complaint.newClasification && (
                                 <>
                                    <Grid item xs={12} sx={{ display: "flex", p: 2 }}>
                                       <Typography color={"#2d4357"} fontWeight={700} variant="h6">
                                          Resolución
                                       </Typography>
                                    </Grid>
                                    <Grid container sx={{ display: "flex", justifyContent: "center", py: 1 }}>
                                       <Grid item xs={10}>
                                          <Box display={"flex"} sx={dataFieldSX}>
                                             <Typography>{complaint.newClasification}</Typography>
                                          </Box>
                                       </Grid>
                                    </Grid>
                                 </>
                              )}
                              <Grid container sx={{ display: "flex", justifyContent: "center" }}>
                                 <Grid item xs={12} sx={{ display: "flex", p: 2 }}>
                                    <Typography color={"#2d4357"} fontWeight={700} variant="h6">
                                       Medidas impuestas
                                    </Typography>
                                 </Grid>
                                 <Grid item xs={3}>
                                    <Box sx={{ display: "flex", marginLeft: 5 }}>
                                       <FormControlLabel
                                          control={
                                             <Checkbox
                                                name="sanctionMeasures"
                                                checked={lecosyInputs.sanctionMeasures}
                                                onChange={handleCheckChanged}
                                                inputProps={{ "aria-label": "controlled" }}
                                             />
                                          }
                                          label="Medidas sancionadoras"
                                       />
                                    </Box>
                                 </Grid>
                                 <Grid item xs={3}>
                                    <FormControlLabel
                                       control={
                                          <Checkbox
                                             name="disciplinaryMeasures"
                                             checked={lecosyInputs.disciplinaryMeasures}
                                             onChange={handleCheckChanged}
                                             inputProps={{ "aria-label": "controlled" }}
                                          />
                                       }
                                       label="Medidas disciplinarias"
                                    />
                                 </Grid>
                                 <Grid item xs={3}>
                                    <FormControlLabel
                                       control={
                                          <Checkbox
                                             name="preventionMeasures"
                                             checked={lecosyInputs.preventionMeasures}
                                             onChange={handleCheckChanged}
                                             inputProps={{ "aria-label": "controlled" }}
                                          />
                                       }
                                       label="Medidas de prevención"
                                    />
                                 </Grid>
                              </Grid>
                              <Grid container sx={{ display: "flex", justifyContent: "center", py: 1 }}>
                                 <Grid item xs={10}>
                                    <Box display={"flex"} sx={dataFieldSX}>
                                       <InputTextField
                                          id="measures"
                                          name="measures"
                                          type="text"
                                          variant="standard"
                                          label="Observaciones"
                                          fullWidth={true}
                                          multiline={true}
                                          sx={{ mt: 1 }}
                                       />
                                    </Box>
                                 </Grid>
                              </Grid>
                           </Grid>
                        </Grid>
                     </Box>
                     <Box display={"flex"} sx={{ justifyContent: "flex-end", width: "100%" }}>
                        <Button variant="contained" sx={{ mt: 2, width: "50px" }} type="submit">
                           {isLoadingTemplate ? <CircularProgress size={25} sx={{ color: "white" }} /> : "Enviar"}
                        </Button>
                     </Box>
                  </Box>
               </Form>
            </Formik>
         </Container>
      </Box>
   );
};

const dataFieldSX = {
   alignItems: "center",
   justifyContent: "center",
   flexDirection: "column",
   py: 2,
   px: 2,
};
