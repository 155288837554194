import {
   eightTeen,
   imageEigth,
   imageEleven,
   imageFive,
   imageFiveTeen,
   imageFour,
   imageFourTeen,
   imageNine,
   imageOne,
   imageSeven,
   imageSevenTeen,
   imageSix,
   imageSixTeen,
   imageTen,
   imageThirteen,
   imageTree,
   imageTwelve,
   imageTwo,
   interviewCircle,
   nineTeen,
} from "../../const/globalConst";
import { footer, header, textDefinition } from "../../const/templateConst";

export const witnessInterview = (
   empty: boolean,
   answer1: string,
   answer2: string,
   answer3: string,
   answer4: string,
   body: string,
   answer5: string,
   answer6: string,
   answer7: string,
   answer8: string,
   answer9: string,
   answer10: string,
   answer11: string,
   answer12: string,
   answer13: string,
   answer14: string,
   answer15: string,
   answer16: string,
   answer17: string,
   answer18: string,
   answer19: string,
   evidence: string | ArrayBuffer
) => {
   const witnessQuestions = [
      {
         image: imageOne,
         question: "¿Cuál es su nombre completo?",
         answer: textDefinition(answer1, [25, 10, 25, 10], false),
      },
      {
         image: imageTwo,
         question: "¿Usted labora en la organización?",
         answer: textDefinition(answer2, [25, 10, 25, 10], false, "#00000", 11),
         multiple: true,
      },
      {
         image: imageTree,
         question: "En caso de que la respuesta anterior sea si ¿Cuál es la actividad que realiza en la organización?",
         answer: textDefinition(answer3, [25, 10, 25, 10], false, "#00000", 11),
      },
      {
         question: "",
         answer: textDefinition(
            "En caso de que la respuesta número 2. Sea No se omiten las preguntas 3 y 4",
            [25, 10, 25, 10],
            false,
            "#646D80",
            10
         ),
         onlyText: true,
      },
      {
         image: imageFour,
         question: "¿Cúanto tiempo tiene trabajando en esta organización?",
         answer: textDefinition(answer4, [25, 10, 25, 10], false, "#00000", 11),
      },
      {
         question: "",
         answer: {
            text: `Se le da lectura de los hechos denunciados y se cuestiona lo siguiente:`,
            fontSize: 10,
            color: "#646D80",
            alignment: "center",
            margin: [25, 10, 25, 10],
            lineHeight: 1.1,
         },
         onlyText: true,
      },
      {
         question: "",
         answer: textDefinition(body, [25, 10, 25, 10], false, "#46566a", 10),
         onlyText: true,
      },
      {
         image: imageFive,
         question: "¿Usted tiene conocimiento de los hechos anteriormente leídos?",
         answer: textDefinition(answer5, [25, 10, 25, 10], false, "#00000", 11),
      },
      {
         image: imageSix,
         question: "¿Usted presenció la denuncia que se menciona?",
         answer: textDefinition(answer6, [25, 10, 25, 10], false, "#00000", 11),
         multiple: true,
      },
      {
         image: imageSeven,
         question: "¿Reconoce la denuncia narrada como cierta?",
         answer: textDefinition(answer7, [25, 10, 25, 10], false, "#00000", 11),
         multiple: true,
      },
      {
         image: imageEigth,
         question:
            "En caso de que la respuesta anterior sea afirmativa, ¿Tiene conocimiento del nombre de las personas implicadas en los hechos?",
         answer: textDefinition(answer8 !== "" ? `${answer8}` : "No aplica", [25, 10, 25, 10], false, "#00000", 11),
         multiple: true,
      },
      {
         image: imageNine,
         question: "En caso de que la respuesta anterior sea si. ¿Podría mencionarlos?",
         answer: textDefinition(answer9, [25, 10, 25, 10], false, "#00000", 11),
      },
      {
         image: imageTen,
         question: "¿Los hechos narrados en la denuncia tienen alguna variación conforme a su percepción?",
         answer: textDefinition(answer10, [25, 10, 25, 10], false, "#00000", 11),
         multiple: true,
      },
      {
         image: imageEleven,
         question: "En caso de que la respuesta anterior sea afirmativa, ¿puede narrar los hechos?",
         answer: textDefinition(answer11 !== "" ? `${answer11}` : "No aplica", [25, 10, 25, 10], false, "#00000", 11),
      },
      {
         image: imageTwelve,
         question: "¿Qué actividades realiza usted en la fecha que se estima esta denuncia?",
         answer: textDefinition(answer12, [25, 10, 25, 10], false, "#00000", 11),
      },
      {
         image: imageThirteen,
         question: "¿Tiene testigos de las actividades que realizaba ese día?",
         answer: textDefinition(answer13, [25, 10, 25, 10], false, "#00000", 11),
         multiple: true,
      },
      {
         image: imageFourTeen,
         question: "En caso de que la respuesta anterior sea si. ¿Podría mencionarlos?",
         answer: textDefinition(answer14 !== "" ? `${answer14}` : "No aplica", [25, 10, 25, 10], false, "#00000", 11),
      },
      {
         image: imageFiveTeen,
         question: "¿Cuenta con alguna evidencia que pruebe lo que menciona en esta entrevista?",
         answer: textDefinition(answer15, [25, 10, 25, 10], false, "#00000", 11),
         multiple: true,
      },
      {
         image: imageSixTeen,
         question: "En caso de ser afirmativa, su respuesta ¿puede proporcionar tales evidencias?",
         answer: textDefinition(answer16 !== "" ? `${answer16}` : "No aplica", [25, 10, 25, 10], false, "#00000", 11),
      },
      {
         image: imageSevenTeen,
         question:
            "¿Tiene alguna información que crea pertinente adicionar a esta entrevista para la correcta investigación de los hechos?",
         answer: textDefinition(answer17, [25, 10, 25, 10], false, "#00000", 11),
      },
      {
         image: eightTeen,
         question: "¿Qué acciones cree que pudieran modificarse para evitar la existencia de este tipo de denuncias?",
         answer: textDefinition(answer18, [25, 10, 25, 10], false, "#00000", 11),
      },
      {
         image: nineTeen,
         question: "¿Ha respondido a todas las preguntas con veracidad?",
         answer: textDefinition(answer19, [25, 10, 25, 10], false, "#00000", 11, evidence !== "" ? true : false),
         multiple: true,
         final: evidence !== "" ? true : false,
      },
   ];

   const dd = {
      pageSize: "A4",
      pageMargins: [70, 90, 70, 180],
      header: header("FORMATO DE ENTREVISTA AL TESTIGO"),
      footer: footer(evidence !== "" ? true : false),
      content: [
         textDefinition(
            "La razón de esta entrevista es colaborar con la investigación de los hechos, conforme un acopio de información de cualquier denuncia existente en el canal de denuncias.",
            [25, 10, 25, 10],
            false
         ),
         textDefinition(
            "Consiste en una serie de preguntas efectuadas a las distintas personas que tienen conocimiento o pueden brindar antecedentes acerca de un hecho que se investiga, fundamentalmente con el objeto de obtener información que coadyuve en el esclarecimiento de los hechos.",
            [25, 10, 25, 10],
            false
         ),
         witnessQuestions.map((e) => {
            return [
               e.image
                  ? {
                       columns: [
                          {
                             image: e.image,
                             width: 20,
                          },
                          textDefinition(e.question, [4, 2, 0, 2], true, "#152c44", 12),
                       ],
                    }
                  : null,
               e.multiple
                  ? empty
                     ? {
                          columns: [
                             {
                                image: interviewCircle,
                                width: 15,
                             },
                             textDefinition("Si", [0, 0, 0, 0], true, "#152c44", 12, false, 20),
                             {
                                image: interviewCircle,
                                width: 15,
                             },
                             textDefinition("No", [0, 0, 0, 0], true, "#152c44", 12, false, 20),
                          ],
                          columnGap: 5,
                          margin: [25, 10, 25, 10],
                          pageBreak: e.final ? "after" : "",
                       }
                     : e.answer
                  : empty
                  ? textDefinition("", [25, 10, 25, 10], false)
                  : e.answer,
            ];
         }),
         evidence !== ""
            ? [
                 {
                    text: "Evidencia de identidad",
                    fontSize: 12,
                    bold: true,
                    color: "#152c44",
                    width: 20,
                    margin: [25, 10, 25, 10],
                 },
                 {
                    image: evidence,
                    width: 400,
                    margin: [25, 10, 25, 10],
                 },
              ]
            : {},
      ],
   };
   return dd;
};

export const denouncedInterview = (
   empty: boolean,
   answer1: string,
   answer2: string,
   answer3: string,
   answer4: string,
   answer5: string,
   answer6: string,
   answer7: string,
   answer8: string,
   answer9: string,
   answer10: string,
   answer11: string,
   answer12: string,
   answer13: string,
   answer14: string,
   answer15: string,
   answer16: string,
   answer17: string,
   clasification: string,
   evidence: string | ArrayBuffer
) => {
   const denouncedQuestions = [
      {
         image: imageOne,
         question: "¿Cuál es su nombre completo?",
         answer: textDefinition(answer1, [25, 10, 25, 10], false),
      },
      {
         image: imageTwo,
         question: "¿Cuál es la actividad que realiza en la organización?",
         answer: textDefinition(answer2, [25, 10, 25, 10], false, "#00000", 11),
      },
      {
         image: imageTree,
         question: "¿Cuánto tiempo tiene trabajando en esta organización?",
         answer: textDefinition(answer3, [25, 10, 25, 10], false, "#00000", 11),
      },
      {
         image: imageFour,
         question: `¿Personalmente reconoces alguna falta catalogada como ${clasification} que te indique como responsable de la misma?`,
         answer: textDefinition(answer4, [25, 10, 25, 10], false, "#00000", 11),
         multiple: true,
      },
      {
         image: imageFive,
         question: "¿Tiene algún hecho que manifestar referente a la denuncia que fue instaurada en su contra?",
         answer: textDefinition(answer5, [25, 10, 25, 10], false, "#00000", 11),
         multiple: true,
      },
      {
         image: imageSix,
         question: "En caso de que la respuesta anterior sea si ¿Puede mencionarlos?",
         answer: textDefinition(answer6 === "" ? "No aplica" : `${answer6}`, [25, 10, 25, 10], false, "#00000", 11),
         multiple: true,
      },
      {
         image: imageSeven,
         question: "¿Niega que exista una denuncia de este tipo, que se le indique como responsable de alguna falta?",
         answer: textDefinition(answer7, [25, 10, 25, 10], false, "#00000", 11),
         multiple: true,
      },
      {
         image: imageEigth,
         question:
            "Si la respuesta anterior fue si, ¿Tiene conocimiento del nombre de la persona que pudiera ser la que cometió dicha denuncia?",
         answer: textDefinition(answer8, [25, 10, 25, 10], false, "#00000", 11),
         multiple: true,
      },
      {
         image: imageNine,
         question: "Si la respuesta anterior fue si, ¿Puede mencionarlo?",
         answer: textDefinition(answer9 === "" ? "No aplica" : `${answer9}`, [25, 10, 25, 10], false, "#00000", 11),
      },
      {
         image: imageTen,
         question: "¿Qué actividades realizaba usted en la fecha que se estima la denuncia?",
         answer: textDefinition(answer10, [25, 10, 25, 10], false, "#00000", 11),
      },
      {
         image: imageEleven,
         question: "¿Tiene testigos de las actividades que realizaba ese día?",
         answer: textDefinition(answer11 !== "" ? `${answer11}` : "No aplica", [25, 10, 25, 10], false, "#00000", 11),
         multiple: true,
      },
      {
         image: imageTwelve,
         question: "Si la respuesta anterior fue si, ¿Puede mencionarlos?",
         answer: textDefinition(answer12 === "" ? "No aplica" : `${answer12}`, [25, 10, 25, 10], false, "#00000", 11),
      },
      {
         image: imageThirteen,
         question: "¿Tiene alguna prueba que fundamente lo que menciona en esta entrevista?",
         answer: textDefinition(answer13, [25, 10, 25, 10], false, "#00000", 11),
         multiple: true,
      },
      {
         image: imageFourTeen,
         question: "Si la respuesta anterior fue si, ¿Puede mencionarlos?",
         answer: textDefinition(answer14 !== "" ? `${answer14}` : "No aplica", [25, 10, 25, 10], false, "#00000", 11),
         multiple: true,
      },
      {
         image: imageFiveTeen,
         question: "¿Conoce o fue protagonista de algún suceso de esta categoría recientemente?",
         answer: textDefinition(answer15, [25, 10, 25, 10], false, "#00000", 11),
         multiple: true,
      },
      {
         image: imageSixTeen,
         question: "Si la respuesta anterior es sí, ¿podría narrar los hechos?",
         answer: textDefinition(answer16 === "" ? "No aplica" : `${answer16}`, [25, 10, 25, 10], false, "#00000", 11),
      },
      {
         image: imageSevenTeen,
         question: "¿Ha respondido a todas las preguntas con la verdad?",
         answer: textDefinition(answer17, [25, 10, 25, 10], false, "#00000", 11, evidence !== "" ? true : false),
         multiple: true,
         final: false,
      },
   ];

   const dd = {
      pageSize: "A4",
      pageMargins: [70, 90, 70, 180],
      header: header("FORMATO DE ENTREVISTA AL DENUNCIADO"),
      footer: footer(evidence !== "" ? true : false),
      content: [
         textDefinition(
            "La razón de esta entrevista es colaborar con la investigación de los hechos, conforme un acopio de información de cualquier denuncia existente en el canal de denuncias.",
            [25, 10, 25, 10],
            false
         ),
         denouncedQuestions.map((e) => {
            return [
               e.image
                  ? {
                       columns: [
                          {
                             image: e.image,
                             width: 20,
                          },
                          textDefinition(e.question, [4, 2, 0, 2], true, "#152c44", 12),
                       ],
                    }
                  : null,
               e.multiple
                  ? empty
                     ? {
                          columns: [
                             {
                                image: interviewCircle,
                                width: 15,
                             },
                             textDefinition("Si", [0, 0, 0, 0], true, "#152c44", 12, false, 20),
                             {
                                image: interviewCircle,
                                width: 15,
                             },
                             textDefinition("No", [0, 0, 0, 0], true, "#152c44", 12, false, 20),
                          ],
                          columnGap: 5,
                          margin: [25, 10, 25, 10],
                          pageBreak: e.final ? "after" : "",
                       }
                     : e.answer
                  : empty
                  ? textDefinition("", [25, 10, 25, 10], false)
                  : e.answer,
            ];
         }),
         evidence !== ""
            ? [
                 {
                    text: "Evidencia de identidad",
                    fontSize: 12,
                    bold: true,
                    color: "#152c44",
                    width: 20,
                    margin: [25, 10, 25, 10],
                 },
                 {
                    image: evidence,
                    width: 400,
                    margin: [25, 10, 25, 10],
                 },
              ]
            : {},
      ],
   };
   return dd;
};

export const complainantInterview = (
   empty: boolean,
   answer1: string,
   answer2: string,
   answer3: string,
   answer4: string,
   answer5: string,
   answer6: string,
   answer7: string,
   answer8: string,
   answer9: string,
   answer10: string,
   answer11: string,
   answer12: string,
   answer13: string,
   answer14: string,
   answer15: string,
   answer16: string,
   answer17: string,
   evidence: string | ArrayBuffer
) => {
   const complainantQuestions = [
      {
         image: imageOne,
         question: "¿Cuál es su nombre completo?",
         answer: textDefinition(answer1, [25, 10, 25, 10], false),
      },
      {
         image: imageTwo,
         question: "¿Usted labora en la organización?",
         answer: textDefinition(answer2, [25, 10, 25, 10], false, "#00000", 11),
         multiple: true,
      },
      {
         image: imageTree,
         question: "En caso de que la respuesta anterior sea si ¿Cuál es la actividad que realiza en la organización?",
         answer: textDefinition(answer3 === "" ? "No aplica" : `${answer3}`, [25, 10, 25, 10], false, "#00000", 11),
      },
      {
         image: imageFour,
         question: `¿Cuánto tiempo tiene trabajando en esta organización?`,
         answer: textDefinition(answer4 === "" ? "No aplica" : `${answer4}`, [25, 10, 25, 10], false, "#00000", 11),
      },
      {
         image: imageFive,
         question:
            "En caso de que la respuesta número 2. Sea No ¿Por qué razón se encontraba en el lugar de los hechos, motivo de su denuncia?",
         answer: textDefinition(answer5, [25, 10, 25, 10], false, "#00000", 11),
      },
      {
         image: imageSix,
         question: "¿Usted se declara como la persona denunciante en el canal de denuncias?",
         answer: textDefinition(answer6 === "" ? "No aplica" : `${answer6}`, [25, 10, 25, 10], false, "#00000", 11),
         multiple: true,
      },
      {
         image: imageSeven,
         question: "¿Alguien más está involucrado o es consciente de la irregularidad denunciada?",
         answer: textDefinition(answer7, [25, 10, 25, 10], false, "#00000", 11),
         multiple: true,
      },
      {
         image: imageEigth,
         question: "Si la respuesta anterior es sí, ¿Podría proceder a narrar tal afirmación?",
         answer: textDefinition(answer8 === "" ? "No aplica" : answer8, [25, 10, 25, 10], false, "#00000", 11),
      },
      {
         image: imageNine,
         question: "¿Usted asegura que lo ocurrido sucedió de esa manera?",
         answer: textDefinition(answer9 === "" ? "No aplica" : `${answer9}`, [25, 10, 25, 10], false, "#00000", 11),
      },
      {
         image: imageTen,
         question: "¿Cuenta con algún testigo que respalde su narración?",
         answer: textDefinition(answer10, [25, 10, 25, 10], false, "#00000", 11),
         multiple: true,
      },
      {
         image: imageEleven,
         question: "En caso de que la respuesta anterior sea si. ¿Podría mencionarlos?",
         answer: textDefinition(answer11 !== "" ? `${answer11}` : "No aplica", [25, 10, 25, 10], false, "#00000", 11),
      },
      {
         image: imageTwelve,
         question: "¿Se declara como víctima principal de lo ocurrido?",
         answer: textDefinition(answer12 === "" ? "No aplica" : `${answer12}`, [25, 10, 25, 10], false, "#00000", 11),
         multiple: true,
      },
      {
         image: imageThirteen,
         question:
            "¿Cuenta con alguna evidencia para probar su dicho, que pueda aportar en el presente proceso de investigación?",
         answer: textDefinition(answer13, [25, 10, 25, 10], false, "#00000", 11),
         multiple: true,
      },
      {
         image: imageFourTeen,
         question: "En caso de ser afirmativa, su respuesta ¿puede proporcionar tales evidencias?",
         answer: textDefinition(answer14 !== "" ? `${answer14}` : "No aplica", [25, 10, 25, 10], false, "#00000", 11),
         multiple: true,
      },
      {
         image: imageFiveTeen,
         question:
            "¿Qué medidas serían las idóneas para usted para la erradicación de estos problemas dentro de la organización?",
         answer: textDefinition(answer15, [25, 10, 25, 10], false, "#00000", 11),
      },
      {
         image: imageSixTeen,
         question: "¿Cómo podríamos ayudar a resarcir el daño ocurrido?",
         answer: textDefinition(answer16 === "" ? "No aplica" : `${answer16}`, [25, 10, 25, 10], false, "#00000", 11),
      },
      {
         image: imageSevenTeen,
         question: "¿Ha respondido a todas las preguntas con la verdad?",
         answer: textDefinition(answer17, [25, 10, 25, 10], false, "#00000", 11, evidence !== "" ? true : false),
         multiple: true,
         final: true,
      },
   ];

   const dd = {
      pageSize: "A4",
      pageMargins: [70, 90, 70, 180],
      header: header("FORMATO DE ENTREVISTA AL DENUNCIANTE"),
      footer: footer(evidence !== "" ? true : false),
      content: [
         textDefinition(
            "La razón de esta entrevista es colaborar con la investigación de los hechos, conforme un acopio de información de cualquier denuncia existente en el canal de denuncias.",
            [25, 10, 25, 10],
            false
         ),
         complainantQuestions.map((e) => {
            return [
               e.image
                  ? {
                       columns: [
                          {
                             image: e.image,
                             width: 20,
                          },
                          textDefinition(e.question, [4, 2, 0, 2], true, "#152c44", 12),
                       ],
                    }
                  : null,
               e.multiple
                  ? empty
                     ? {
                          columns: [
                             {
                                image: interviewCircle,
                                width: 15,
                             },
                             textDefinition("Si", [0, 0, 0, 0], true, "#152c44", 12, false, 20),
                             {
                                image: interviewCircle,
                                width: 15,
                             },
                             textDefinition("No", [0, 0, 0, 0], true, "#152c44", 12, false, 20),
                          ],
                          columnGap: 5,
                          margin: [25, 10, 25, 10],
                          pageBreak: e.final ? "after" : "",
                       }
                     : e.answer
                  : empty
                  ? textDefinition("", [25, 10, 25, 10], false)
                  : e.answer,
            ];
         }),
         evidence !== ""
            ? [
                 {
                    text: "Evidencia de identidad",
                    fontSize: 12,
                    bold: true,
                    color: "#152c44",
                    width: 20,
                    margin: [25, 10, 25, 10],
                 },
                 {
                    image: evidence,
                    width: 400,
                    margin: [25, 10, 25, 10],
                 },
              ]
            : {},
      ],
   };
   return dd;
};
