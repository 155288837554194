import { useContext, useEffect, useRef, useState } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { HeadCell, TableComponent } from "../components/TableComponent";
import { UserContext } from "../context/userContext";
import { getManyCompaniesById, getManyCorporateData, getUrlS3 } from "../lib/usersBEClient";
import { useNavigate } from "react-router-dom";
import { FilterComponent } from "../components/FilterComponent";
import { AddCircle } from "@mui/icons-material";
import { CustomModalComponent } from "../components/CustomModalComponent";
import { CompaniesSelectorComponet } from "../components/MiLecosy/Office/Group/CompaniesSelectorComponent";
import { ClientCreationContext } from "../context/officeContexts/clientCreationContext";

let columns: HeadCell[] = [
   { field: "logo", headerName: "Logo", type: "string" },
   { field: "name", headerName: "Empresa", type: "string" },
   { field: "councilType", headerName: "Tipo de consejo", type: "string" },
   { field: "status", headerName: "Estatus", type: "boolean" },
];

export const CompaniesTable = () => {
   const { isOfficeAdmin, adminCompanies, isloadingCompanies, isImplementationUser, companies, groupSelected } =
      useContext(UserContext);
   const { selectedClient, setSelectedClient } = useContext(ClientCreationContext);

   const [companiesRow, setCompaniesRow] = useState([]);
   const [filteredCompanies, setFilteredCompanies] = useState([]);
   const [isLoading, setIsLoading] = useState(true);
   const navigate = useNavigate();

   const anchorRef = useRef<HTMLButtonElement>(null);
   const [openModal, setOpenModal] = useState<boolean>(false);

   const fetchCompaniesInfo = async () => {
      const companyData: any[] = [];
      setIsLoading(true);
      try {
         if (!groupSelected && companies.length > 0) {
            const companyCorporateDatas = await getManyCorporateData(companies.map((company) => company._id));
            for (const company of companies) {
               const logo = await getDataS3(company._id);
               companyData.push({
                  _id: company._id,
                  logo: logo,
                  name: company.person_details.comercialName,
                  status: company.disabled,
                  councilType: companyCorporateDatas?.find((c) => c.companyId === company._id)?.councilType ?? "---",
               });
            }
         } else if (groupSelected) {
            const companyCorporateDatas = await getManyCorporateData(
               groupSelected.companies.map((company) => company._id)
            );
            const groupCompanies = await getManyCompaniesById(groupSelected.companies.map((company) => company._id));
            for (const company of groupCompanies) {
               companyData.push({
                  _id: company._id,
                  logo: (
                     <img
                        defaultValue={company.logo}
                        src={company.logo}
                        alt="logo"
                        style={{ maxWidth: "140px", maxHeight: "50px" }}
                     />
                  ),
                  name: company.person_details.comercialName,
                  status: company.disabled,
                  councilType: companyCorporateDatas?.find((c) => c.companyId === company._id)?.councilType ?? "---",
               });
            }
         }
         setCompaniesRow(companyData);
         setFilteredCompanies(companyData);
      } catch (error) {}
      setIsLoading(false);
   };

   useEffect(() => {
      fetchCompaniesInfo();
      // eslint-disable-next-line
   }, [isloadingCompanies, adminCompanies]);

   const getDataS3 = async (companyId: string) => {
      const logoUrl: string = await getUrlS3("images-lecosy", { folder: companyId }, "logo.png");
      return <img defaultValue={logoUrl} src={logoUrl} alt="logo" style={{ maxWidth: "140px", maxHeight: "50px" }} />;
   };

   return (
      <Box sx={{ width: "100%" }}>
         {!isLoading && !isloadingCompanies ? (
            <>
               <FilterComponent
                  originaltableOneRows={companiesRow}
                  tableOneFilteredRows={filteredCompanies}
                  setTableOneFilteredRows={setFilteredCompanies}
                  filters={[]}
                  fullWidth
                  searchBar={
                     <>
                        {isOfficeAdmin && (
                           <Button
                              variant="contained"
                              sx={{ bgcolor: "#162C44", width: "200px" }}
                              ref={anchorRef}
                              onClick={() => {
                                 setSelectedClient({ type: "group", data: groupSelected });
                                 setOpenModal(!openModal);
                              }}
                           >
                              <AddCircle sx={{ marginRight: 1 }} />
                              <Typography variant="button" sx={{ mr: 1 }}>
                                 Agregar empresa
                              </Typography>
                           </Button>
                        )}
                     </>
                  }
                  disableRefresh
               />
               <TableComponent
                  headerColor
                  defaultColumnToOrder="empresa"
                  defaultOrder="asc"
                  defaultRowsPerPage={5}
                  emptyDataText={
                     companiesRow.length > 0
                        ? "No se encontró una empresa con ese nombre"
                        : "No existen empresas registradas"
                  }
                  headers={columns}
                  loader={isLoading}
                  rows={filteredCompanies}
                  rowsPerPageOptions={[5, 10, 20]}
                  onClick={(_e, row) => navigate(`/mi-lecosy/${row._id}/inicio`)}
               />
            </>
         ) : (
            <>
               <Box width={"100%"} display="flex" justifyContent={"center"} p={1}>
                  <CircularProgress sx={{ alignSelf: "center" }} />
               </Box>
            </>
         )}
         {selectedClient && (
            <CustomModalComponent
               open={openModal}
               setOpen={setOpenModal}
               onClose={() => setSelectedClient(null)}
               title="Agregar nueva empresa"
               timeStamp
               maxWidth="1000px"
            >
               <CompaniesSelectorComponet onComplete={() => {}} />
            </CustomModalComponent>
         )}
      </Box>
   );
};
